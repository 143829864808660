import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { MyEvent } from '../utils/types';
import { AxiosError, AxiosResponse } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { axiosInstance } from '../utils/axiosInstance';
import { IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import ConfirmDialog from './ConfirmDialog';
import Edit from '@mui/icons-material/Edit';
import { toast, toastHandlePromise } from '../utils/toast';
import axiosErrorToToastString from '../utils/axiosErrorToToastString';
import ShareIcon from '@mui/icons-material/Share';
import { useIsMobile } from '../features/responsiveService/responsiveSlice';
import Page from './Page';

interface MyEventsResponse extends AxiosResponse {
  data: MyEvent[]
}

const MyEvents = () => {
  const isMobile = useIsMobile()
  const [myEvents, setMyEvents] = useState<MyEvent[]>([])

  useEffect(() => {
    const fetchMyEvents = async () => {
      return axiosInstance.get('/api/event/my-events')
        .then((res: MyEventsResponse) => res.data)
    }

    fetchMyEvents()
      .then((myEvents) => setMyEvents(myEvents))
      .catch((e) => {
        // TODO: handle error
        console.log(e)
      })
  }, [])

  const deleteEvent = async (eventId: number) => {
    const deleteEventPromise = axiosInstance.delete(`/api/event/delete/${eventId}`)

    await toastHandlePromise(deleteEventPromise, {
      pending: 'Deleting... Please wait.',
      success: {
        render: () => {
          setMyEvents([...myEvents].filter((event) => event.id !== eventId))
          return 'Event deleted!'
        }
      },
      error: {
        render: ({ data }) => {
          if (data instanceof AxiosError) {
            const axiosErrorMsg = axiosErrorToToastString(data)
            return `Failed to delete event. ${axiosErrorMsg}`
          } else {
            return 'Event deletion failed. Please contact Otails.'
          }
        }
      }
    })
  }

  const copyJoinEventLinkToClipboard = (eventUuid: string) => {
    const linkToJoinEvent = `${window.location.origin}/join-event/${eventUuid}`
    navigator.clipboard.writeText(linkToJoinEvent)
    toast.info('Link copied to clipboard')
  }

  return (
    <Page title="My events">
      {isMobile ? <MobileView
        myEvents={myEvents}
        deleteEvent={deleteEvent}
        copyJoinEventLinkToClipboard={copyJoinEventLinkToClipboard}
      /> : <DesktopView
        myEvents={myEvents}
        deleteEvent={deleteEvent}
        copyJoinEventLinkToClipboard={copyJoinEventLinkToClipboard}
      />}
    </Page>
  )
}

interface ViewProps {
  myEvents: MyEvent[]
  deleteEvent: (eventId: number) => Promise<void>,
  copyJoinEventLinkToClipboard: (eventUuid: string) => void
}

const MobileView = ({ myEvents, deleteEvent, copyJoinEventLinkToClipboard }: ViewProps) => {
  const navigate = useNavigate()

  const renderEventNameLink = (event: MyEvent) => {
    return (
      <Link to={`/event/${event.uuid}`}>{event.name}</Link>
    )
  }

  const renderEventDate = (event: MyEvent) => {
    // This deafault now to YYYY-MM-DD format. Refactor when localization is added
    const date = event.date.slice(0, 10)
    return (
      <Typography>
        {date}
      </Typography>
    )
  }

  const getEventActions = (event: MyEvent) => {
    return (
      <Stack direction="row">
        <IconButton onClick={() => navigate(`/edit-event/${event.id}`)}><Edit /></IconButton>
        <IconButton onClick={() => copyJoinEventLinkToClipboard(event.uuid)}><ShareIcon /></IconButton>
        <ConfirmDialog
          title='Do you really want to delete this event?'
          text='Event deletion is permanent. All data including maps, courses, runners and gps data are deleted. This action cannot be restored.'
          buttonText='Delete'
          icon='delete'
          handleOk={() => deleteEvent(event.id)}
        />
      </Stack>
    )
  }

  const navigateToEvent = (clickEvent: React.MouseEvent<HTMLElement>, eventUuid: string) => {
    clickEvent.stopPropagation()
    navigate(`/event/${eventUuid}`)
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {myEvents.map((event) => {
        return (
          <ListItem key={event.uuid} sx={{ p: 0 }}>
            <ListItemText onClick={(e) => navigateToEvent(e, event.uuid)} primary={renderEventNameLink(event)} secondary={renderEventDate(event)} />
            {getEventActions(event)}
          </ListItem>
        )
      })}
    </List>
  )
}


const DesktopView = ({ myEvents, deleteEvent, copyJoinEventLinkToClipboard }: ViewProps) => {
  const navigate = useNavigate()

  const renderActions = (params: GridRenderCellParams) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          variant='contained'
          size='small'
          onClick={() => navigate(`/edit-event/${params.row.id}`)}
          sx={{ mr: 1 }}
          startIcon={<Edit />}
        >
          Edit
        </Button>
        <Button
          sx={{
            mr: 1
          }}
          variant='contained'
          size='small'
          onClick={() => copyJoinEventLinkToClipboard(params.row.uuid)}
          startIcon={<ShareIcon />}
        >
          Share
        </Button>
        <ConfirmDialog
          title='Do you really want to delete this event?'
          text='Event deletion is permanent. All data including maps, courses, runners and gps data are deleted. This action cannot be restored.'
          buttonText='Delete'
          icon='delete'
          handleOk={() => deleteEvent(params.row.id)}
        />
      </Box>

    )
  }

  const renderEventNameLink = (params: GridRenderCellParams) => {
    return (
      <Link to={`/event/${params.row.uuid}`}>{params.value}</Link>
    )
  }

  const renderEventDate = (params: GridRenderCellParams) => {
    // This deafault now to YYYY-MM-DD format. Refactor when localization is added
    const date = params.row.eventDate.slice(0, 10)
    return (
      <Typography>
        {date}
      </Typography>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'eventName',
      headerName: 'Event name',
      flex: 0.9,
      editable: false,
      renderCell: renderEventNameLink
    },
    {
      field: 'eventDate',
      headerName: 'Event date',
      flex: 0.7,
      editable: false,
      renderCell: renderEventDate
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.7,
      sortable: false,
      renderCell: renderActions
    }
  ];

  const getRows = () => {
    return myEvents.map((event) => {
      return {
        id: event.id,
        uuid: event.uuid,
        eventName: event.name,
        eventDate: event.date
      }
    })
  }

  return (
    <DataGrid
      rows={getRows()}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      columnVisibilityModel={{
        id: false
      }}
    />
  )
}

export default MyEvents