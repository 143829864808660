import React, { useEffect } from 'react';
import { socket } from '../socket';
import { useDispatch, useSelector } from 'react-redux';
import { setLiveIsConnected, updateRunners } from '../features/eventService/eventServiceSlice';
import { LiveData } from '../utils/types';
import { RootState } from '../app/store';

const LiveUpdates = () => {
  const eventId = useSelector((state: RootState) => state.eventService.eventDetails?.id)
  const dispatch = useDispatch()

  useEffect(() => {
    console.log('live updates run')
    if (eventId) {
      console.log('Setting up live updates')
      const onConnect = () => {
        dispatch(setLiveIsConnected(true))
        // TODO: client should report here what it knows about runners so that server could fill the "gap"
        socket.emit('joinLive', eventId, (eventIdRes) => {
          console.log('Client listening live updates for eventId: ', eventIdRes)
        })
      }
  
      const onDisconnect = () => {
        dispatch(setLiveIsConnected(false))
        console.log('Client not listening live updates for eventId', eventId)
      }
  
      const onLiveData = (liveData: LiveData) => {
        console.log('Updating live data')
        dispatch(updateRunners(liveData))
      }
  
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
  
      socket.on('liveData', onLiveData);
  
      socket.connect()
  
      return () => {
        console.log('Live data unmounted')
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('liveData', onLiveData);
        socket.disconnect();
      }
    }
  }, [])

  return null
};

export default LiveUpdates;