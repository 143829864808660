import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GeoreferenceMap from './GeoreferenceMap';
import { ErrorBoundary } from 'react-error-boundary';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fi';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AxiosError, AxiosResponse } from 'axios';
import { Course, CreateOrEditEventRunner, DeviceGroup, EditEvent, EventAction, EventType, EventVisibility } from '../../utils/types';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from 'react-router-dom';
import { Bounds } from '../../utils/types';
import EventVisibilitySelection from './EventVisibilitySelection';
import { toast, toastHandlePromise } from '../../utils/toast';
import axiosErrorToToastString from '../../utils/axiosErrorToToastString';
import { axiosInstance } from '../../utils/axiosInstance';
import { UserRole } from '../../services/authService';
import Courses from './Courses';
import DeviceGroups from './DeviceGroups';
import { gpsFeaturesEnabled } from '../../utils/userLimits';
import MapInput from './MapInput';
import { useAuth } from '../Auth/AuthProvider';
import SelectMapFromOmapstore from './SelectMapFromOmapstore';
import EventLiveSelection from './EventLiveSelection';
import Page from '../Page';

enum CreateEventMode {
  EventDataForm = 'eventDataForm',
  GeoreferenceMap = 'geoReferenceMap',
}

const CreateEvent = () => {
  const navigate = useNavigate()
  const { userRole } = useAuth()
  const { eventId } = useParams()
  const [currentMode, setCurrentMode] = useState<CreateEventMode>(CreateEventMode.EventDataForm) // remember to change this to Start 
  const [mapFile, setMapFile] = useState<File | null>(null);
  const [mapBounds, setMapBounds] = useState<Bounds | null>(null)
  const [omapstoreMapUuid, setOmapstoreMapUuid] = useState<string | null>(null)
  const [georeferencingDoneManually, setGeoreferencingDoneManually] = useState(false)

  const [eventName, setEventName] = useState<string>('')
  const [eventDate, setEventDate] = useState<Dayjs | null>(dayjs())

  const [eventVisibility, setEventVisibility] = useState<EventVisibility>(EventVisibility.PRIVATE)
  const [eventPassword, setEventPassword] = useState<string>('')
  const [eventIsVisibleForGroupIds, setEventIsVisibleForGroupIds] = useState<number[]>([])

  const [eventType, setEventType] = useState<EventType>(EventType.GPX)
  const [eventActions, setEventActions] = useState<EventAction[]>([])

  const isGpsFeaturesEnabled = gpsFeaturesEnabled(userRole)

  const [courses, setCourses] = useState<Course[]>([])
  const [runners, setRunners] = useState<CreateOrEditEventRunner[]>([])
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroup[]>([])

  const addRunner = (runner: CreateOrEditEventRunner) => {
    setRunners(runners.concat([runner]))
  }

  const removeRunner = (deviceId: number) => {
    setRunners([...runners].filter((runner) => runner.deviceId !== deviceId))
  }

  const updateRunner = (updatedRunner: CreateOrEditEventRunner) => {
    const updatedRunners = runners.map((oldRunner) => {
      if (oldRunner.deviceId === updatedRunner.deviceId) {
        return {
          ...oldRunner,
          ...updatedRunner
        }
      } else {
        return oldRunner
      }
    })
    setRunners(updatedRunners)
  }

  useEffect(() => {
    const fetchDeviceGroups = async () => {
      const response = await axiosInstance.get<DeviceGroup[]>('/api/device-group/available-devices')
      const deviceGroups = response.data

      setDeviceGroups(deviceGroups)
    }

    fetchDeviceGroups()
  }, [])

  useEffect(() => {
    if (userRole !== UserRole.PREMIUM) {
      setEventVisibility(EventVisibility.PUBLIC_HIDDEN)
    }
  }, [userRole])

  useEffect(() => {
    const handleSetEditEventData = (editEvent: EditEvent) => {
      setEventName(editEvent.name)
      setEventDate(dayjs(editEvent.date))

      setEventPassword(editEvent.password ?? '')
      setEventIsVisibleForGroupIds(editEvent.eventVisibleForGroups.map((elem) => elem.deviceGroupId))
      setEventVisibility(editEvent.eventVisibility)
      setEventType(editEvent.eventType)
      setCourses(editEvent.courses)
      setRunners(editEvent.runners)
      setEventActions(editEvent.eventActions)
    }

    const fetchEditEvent = async (eventId: number) => {
      const { data: eventToEdit } = await axiosInstance.get<EditEvent>(`/api/event/edit/${eventId}`)
      handleSetEditEventData(eventToEdit)
      return eventToEdit
    }

    const fetchData = async () => {
      const shouldFetchEventToEdit = !isNaN(Number(eventId))
      const eventToEdit = shouldFetchEventToEdit
        ? await fetchEditEvent(Number(eventId))
        : null

      if (!eventToEdit) {
        setEventName('')
        setEventDate(dayjs())
        setEventType(EventType.GPX)
        setEventVisibility(userRole === UserRole.FREE ? EventVisibility.PUBLIC_HIDDEN : EventVisibility.PRIVATE)
        setRunners([])
        setCourses([])
        setEventActions([])
      }
    }

    fetchData()
      .catch((e) => {
        console.log(e)
        // TODO: handle error
      })
  }, [eventId, userRole])

  const handleGeoreferencingDone = (corners: Bounds) => {
    setMapBounds(corners)
    setGeoreferencingDoneManually(true)
    setCurrentMode(CreateEventMode.EventDataForm)
  }

  const handleGeoreferencingCancel = () => {
    setCurrentMode(CreateEventMode.EventDataForm)
  }

  const getEventForm = () => {
    const handleSubmitEvent = async () => {
      if (!eventDate || !eventName || !eventVisibility) {
        toast.warn('Please fill up all the required fields')
        return
      }

      try {
        const createEventPromise = new Promise<void>((resolve, reject) => {
          (async () => {
            try {
              let mapFilePathInServer: string | null = null
              if (mapFile) {
                const { data: { mapFilePath } } = await axiosInstance.postForm<{ mapFilePath: string }>('/api/event/upload-map-file', {
                  mapFile
                })
                mapFilePathInServer = mapFilePath
              }

              await axiosInstance.post('/api/event/create', {
                omapstoreMapUuid,
                eventName,
                eventDate: eventDate.format('YYYY-MM-DD'),
                bounds: mapBounds,
                runners: runners,
                eventVisibility,
                eventPassword,
                eventIsVisibleForGroupIds,
                eventType,
                courses,
                mapFilePath: mapFilePathInServer,
                eventActions: eventActions
              })

              resolve()
            } catch (e) {
              reject(e)
            }
          })()
        })

        await toastHandlePromise<AxiosResponse<any, any>>(createEventPromise, {
          pending: 'Saving... Please wait.',
          success: {
            render: () => {
              navigate('/my-events')
              return 'Event created!'
            }
          },
          error: {
            render: ({ data }) => {
              if (data instanceof AxiosError) {
                const axiosErrorString = axiosErrorToToastString(data)
                return `Event not saved. ${axiosErrorString}`
              }
              console.log({ data })
              return 'Unknown error. Modifications not saved!'
            }
          }
        })

      } catch (e) {
        console.log(e)
        // Do nothing
      }

    }

    const handleSubmitEditEvent = async () => {
      if (!eventDate || !eventName || !eventId || !eventVisibility) {
        toast.warn('Please fill up all the required fields')
        return
      }

      try {
        const editEventPromise = new Promise<void>((resolve, reject) => {
          (async () => {
            try {
              let mapFilePathInServer: string | null = null
              if (mapFile) {
                const { data: { mapFilePath } } = await axiosInstance.postForm<{ mapFilePath: string }>('/api/event/upload-map-file', {
                  mapFile
                })
                mapFilePathInServer = mapFilePath
              }

              await axiosInstance.put('/api/event/edit', {
                eventId: Number(eventId),
                omapstoreMapUuid,
                eventName,
                eventDate: eventDate.format('YYYY-MM-DD'),
                bounds: mapBounds,
                runners: runners,
                eventVisibility,
                eventPassword,
                eventIsVisibleForGroupIds,
                eventType,
                courses,
                mapFilePath: mapFilePathInServer,
                eventActions: eventActions
              })

              resolve()
            } catch (e) {
              reject(e)
            }
          })()
        })

        await toastHandlePromise(editEventPromise, {
          pending: 'Saving... Please wait.',
          success: {
            render: () => {
              navigate('/my-events')
              return 'Event updateted!'
            }
          },
          error: {
            render: ({ data }) => {
              if (data instanceof AxiosError) {
                const axiosErrorString = axiosErrorToToastString(data)
                return `Event not saved. ${axiosErrorString}`
              }
              console.log({ data })
              return 'Unknown error. Modifications not saved!'
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 3
        }}
        component="form"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="eventName"
              required
              fullWidth
              id="eventName"
              label="Event name"
              autoFocus={eventName.trim() === ''}
              value={eventName}
              onChange={({ target }) => setEventName(target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
            >
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fi'}>
                {/*  TODO: there is also mobile datepicker available */}
                <DatePicker
                  label="Event date"
                  value={eventDate}
                  onChange={(newDate) => setEventDate(newDate)}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              disabled
              value={mapFile?.name || 'no map selected'}
              label="Map file"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MapInput setMapFile={setMapFile} setMapBounds={setMapBounds} setGeoreferencingDoneManually={setGeoreferencingDoneManually} />
          </Grid>
          {((mapFile && !mapBounds) || georeferencingDoneManually) &&
            <Grid item xs={12} marginTop='auto'>
              <Typography marginBottom={3}>
                {mapBounds === null
                  ? 'Georeferencing required'
                  : 'Georeferencing done'
                }
              </Typography>
              <FormControl
                fullWidth
              >
                <Button
                  variant='contained'
                  onClick={() => setCurrentMode(CreateEventMode.GeoreferenceMap)}
                  disabled={mapFile === null}
                >
                  {mapBounds && 'Re-'}Georeference map
                </Button>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} sm={6}>
            <SelectMapFromOmapstore selectedOmapstoreMapUuid={omapstoreMapUuid} setMapBounds={setMapBounds} setOmapstoreMapUuid={setOmapstoreMapUuid} />
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography>
                  Courses
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Courses courses={courses} setCourses={setCourses} />
              </AccordionDetails>
            </Accordion>
          </Grid>
          {
            isGpsFeaturesEnabled &&
            <Grid item xs={12}>
              <DeviceGroups
                deviceGroups={deviceGroups}
                runners={runners}
                addRunner={addRunner}
                removeRunner={removeRunner}
                updateRunner={updateRunner}
                courses={courses}
              />
            </Grid>
          }

          <Grid item xs={12}>
            <EventVisibilitySelection
              eventVisibility={eventVisibility}
              setEventVisibility={setEventVisibility}
              eventPassword={eventPassword}
              setEventPassword={setEventPassword}
              eventIsVisibleForGroupIds={eventIsVisibleForGroupIds}
              setEventIsVisibleForGroupIds={setEventIsVisibleForGroupIds}
              deviceGroups={deviceGroups}
              userRole={userRole}
            />
          </Grid>
          <Grid item xs={12}>
            <EventLiveSelection
              eventDate={eventDate}
              eventType={eventType}
              setEventType={setEventType}
              eventActions={eventActions}
              setEventActions={setEventActions}
            />
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={eventId ? handleSubmitEditEvent : handleSubmitEvent}
        >
          {eventId
            ? 'Save changes'
            : 'Create new event'
          }
        </Button>
      </Box>
    )
  }

  const title = eventId ? 'Edit an event' : 'Create a new event'

  const getCurrentModeComponent = () => {
    if (currentMode === CreateEventMode.EventDataForm) {
      return (
        <Page title={title}>
          {getEventForm()}
        </Page>
      )

    } else if (currentMode === CreateEventMode.GeoreferenceMap) {
      return <GeoreferenceMap mapFile={mapFile} georeferencingDone={handleGeoreferencingDone} cancel={handleGeoreferencingCancel} />
    }
  }

  return (
    <ErrorBoundary fallback={<>Unexpected error</>}>
      {getCurrentModeComponent()}
    </ErrorBoundary>
  )

}

export default CreateEvent;
