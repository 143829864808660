// adapted from free MUI templates https://github.com/mui/material-ui/blob/v5.12.1/docs/data/material/getting-started/templates/sign-up/SignUp.tsx

import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Copyright from './Copyright'
import { register, RegisterData } from '../services/authService'
import { useAuth } from './Auth/AuthProvider'
import { isString } from '../utils/typeGuards'
import { toast } from '../utils/toast'
import { AxiosError } from 'axios'
import axiosErrorToToastString from '../utils/axiosErrorToToastString'
import Page from './Page'
import { useNavigate } from 'react-router-dom'

const theme = createTheme();

export default function SignUp() {
  const navigate = useNavigate()
  const { handleLogin } = useAuth()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const registerData = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
    }

    if (!isString(registerData.firstName) || !isString(registerData.lastName) || !isString(registerData.email) || !isString(registerData.password)) {
      toast.error('Please provide good data for registration!')
      return
    }

    try {
      const registerError = await register(registerData as RegisterData)
      if (registerError.length > 0) {
        toast.error(registerError)
        return
      }

      const loginError = await handleLogin(registerData.email, registerData.password)
      if (loginError) {
        toast.error(loginError)
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(axiosErrorToToastString(e))
      }
    }

  };

  return (
    <ThemeProvider theme={theme}>
      <Page sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link onClick={() => navigate('/signin')} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
        </Page>
      <Copyright sx={{ mt: 5 }} />
    </ThemeProvider>
  );
}