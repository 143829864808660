import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Delete from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useIsMobile } from '../features/responsiveService/responsiveSlice'
import { IconButton } from '@mui/material'

type SupportedIcons = 'delete'

const getIcon = (icon: SupportedIcons) => {
  switch (icon) {
    case 'delete': {
      return <Delete color='warning' />
    }
    default: {
      throw new Error(`Unsupported icon ${icon}`)
    }
  }
}

interface ConfirmDialogProps {
  buttonText: string,
  icon: SupportedIcons
  text: string,
  title: string,
  handleCancel?: () => void
  handleOk: () => Promise<void> | void
}

const ConfirmDialog = ({ icon, buttonText, text, title, handleCancel, handleOk }: ConfirmDialogProps) => {
  const isMobile = useIsMobile()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectCancel = () => {
    handleClose()
    if (handleCancel) {
      handleCancel()
    }
  }

  const handleSelectOk = () => {
    handleClose()
    handleOk()
  }

  return (
    <div>
      {isMobile ? <IconButton onClick={handleClickOpen}>{getIcon(icon)}</IconButton>:
        <Button variant="outlined" onClick={handleClickOpen} startIcon={getIcon(icon)}>
          {buttonText}
        </Button>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectCancel}>Cancel</Button>
          <Button onClick={handleSelectOk} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog

