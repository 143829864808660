import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Course, EventDetails, EventMode, GpsRecord, GpxRunner, LiveData, Map, OtailsEvent, RunnerType, Runner } from '../../utils/types'
import { getRunnerColor } from '../../utils/getRunnerColor'

export interface EventServiceState {
  runners: Runner[],
  map?: Map,
  eventDetails?: EventDetails,
  liveIsConnected: boolean,
  visibleRunnersIds: number[],
  courseRunnerDrawerOpen: boolean,
  eventMode: EventMode,
  osmOn: boolean
  panRunnerId: number | null,
  showRoute: boolean,
  courses: Course[],
  visibleCourseIds: number[]
}

const initialState: EventServiceState = {
  runners: [],
  liveIsConnected: false,
  visibleRunnersIds: [],
  courseRunnerDrawerOpen: false,
  eventMode: EventMode.REPLAY,
  osmOn: false,
  panRunnerId: null,
  showRoute: false,
  courses: [],
  visibleCourseIds: []
}

const gpsRecordsToDecimalFormat = (gpsRecords: GpsRecord[]): GpsRecord[] => {
  return gpsRecords.map((gpsRecord) => {
    return {
      deviceId: gpsRecord.deviceId,
      latitude: gpsRecord.latitude / 10000000.0,
      longitude: gpsRecord.longitude / 10000000.0,
      timestamp: (new Date(gpsRecord.timestamp)).getTime() 
    }
  })
}

export const eventServiceSlice = createSlice({
  name: 'eventService',
  initialState,
  reducers: {
    initEvent: (state, { payload }: PayloadAction<OtailsEvent>) => {
      state.eventDetails = {
        id: payload.id,
        name: payload.name,
        date: payload.date,
        eventType: payload.eventType
      }

      state.map = payload.map
      
      state.runners = payload.runners.reduce<Runner[]>(((runners, runner) => {
        // assume that runner have id here when it comes from db
        if (runner.id) {
          if (runner.type === RunnerType.GPS) {
            const gpsRecordsInDecimalFormat = gpsRecordsToDecimalFormat(runner.device.gpsRecords)
            // Drop first 5 records to skip records form previous event
            // That could be avoided if start time of runner would be given
            const firstFiveSecodsDropped = gpsRecordsInDecimalFormat.slice(5)
            runner.device.gpsRecords = firstFiveSecodsDropped
          }
          runner.color = getRunnerColor(runner)
          runners.push(runner)
          state.visibleRunnersIds.push(runner.id)
        }
        return runners
      }), [])

      if (payload.courses) {
        state.courses = payload.courses
      }
    },
    updateRunners: (state, { payload }: PayloadAction<LiveData>) => {
      const eventId = payload.id
      if (state.eventDetails?.id && state.eventDetails?.id !== eventId) {
        // data is not for that event
        return
      }
      const runners = payload.runners

      const stateRunners = state.runners

      runners.forEach((runner) => {
        // we assume that for every runner there are already details in list loaded when event is loaded
        // and dont accepts "wild" runners here as this would require us to send also all runner details in every live update
        const runnerInState = stateRunners.find(({id}) => id === runner.id)
        if (runnerInState && runnerInState.type === RunnerType.GPS) {
          // assume that gpsrecords are in order based on timestamp
          const lastKnownTimestamp = runnerInState.device.gpsRecords[runnerInState.device.gpsRecords.length - 1]?.timestamp ?? 0 // It can be that there is no gps records at all
          const newGpsRecords: GpsRecord[] = runner.device.gpsRecords.filter(({ timestamp }) => (new Date(timestamp)).getTime() > lastKnownTimestamp)
            .map((gpsRecord) => {
              return {
                deviceId: runner.deviceId,
                latitude: gpsRecord.latitude / 10000000.0,
                longitude: gpsRecord.longitude / 10000000.0,
                timestamp: (new Date(gpsRecord.timestamp)).getTime()
              }
            })
          runnerInState.device.gpsRecords = runnerInState.device.gpsRecords.concat(newGpsRecords)
        }
      })

      state.runners = stateRunners
    },
    setLiveIsConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.liveIsConnected = payload
    },
    addGpxRunner: (state, { payload }: PayloadAction<GpxRunner>) => {
      if (payload.id) {
        state.runners[payload.id] = payload
        state.visibleRunnersIds.push(payload.id)
      } else {
        // TODO:
      }
    },
    toggleCourseRunnerDrawerOpenOpen: (state) => {
      state.courseRunnerDrawerOpen = !state.courseRunnerDrawerOpen
    },
    toggleRunnerIsVisible: (state, { payload }: PayloadAction<number>) => {
      if (state.visibleRunnersIds.includes(payload)) {
        state.visibleRunnersIds = state.visibleRunnersIds.filter((runnerId) => runnerId !== payload)
      } else {
        state.visibleRunnersIds = state.visibleRunnersIds.concat(payload)
      }
    },
    toggleCourseIsVisible: (state, { payload }: PayloadAction<number> ) => {
      if (state.visibleCourseIds.includes(payload)) {
        state.visibleCourseIds = state.visibleCourseIds.filter((runnerId) => runnerId !== payload)
      } else {
        state.visibleCourseIds = state.visibleCourseIds.concat(payload)
      }
    },
    setEventMode: (state, { payload }: PayloadAction<EventMode>) => {
      state.eventMode = payload
    },
    setOsmOn: (state, { payload }: PayloadAction<boolean>) => {
      state.osmOn = payload
    },
    setPanRunnerId: (state, { payload }: PayloadAction<number | null>) => {
      state.panRunnerId = payload
    },
    setShowRoute: (state, { payload }: PayloadAction<boolean>) => {
      state.showRoute = payload
    },
    setMassStart: (state, { payload }: PayloadAction<Runner[]>) => {
      state.runners = payload
    },
    resetMassStart: (state) => {
      state.runners = state.runners.map((runner) => ({ ...runner, timeOffset: undefined }))
    }
  }
})

export const { 
  initEvent, 
  updateRunners, 
  setLiveIsConnected, 
  addGpxRunner, 
  toggleCourseRunnerDrawerOpenOpen, 
  toggleRunnerIsVisible, 
  toggleCourseIsVisible,
  setEventMode,
  setOsmOn,
  setPanRunnerId,
  setShowRoute,
  setMassStart,
  resetMassStart
} = eventServiceSlice.actions

export default eventServiceSlice.reducer