import React, { useEffect } from 'react'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import { RootState } from '../app/store'
import { setIsBetaAlertVisible } from '../features/responsiveService/responsiveSlice'
import { useDispatch, useSelector } from 'react-redux'

const setBetaAlertDismissedToLocalStorage = () => {
  window.localStorage.setItem('betaAlert', Date.now().toString())
}

const shouldShowBetaBannerAgain = () => {
  const timestamp = window.localStorage.getItem('betaAlert')
  if (timestamp) {
    const shouldShowBetaBannerAgain = Date.now() - Number(timestamp) > 60 * 60 * 24 * 7 * 1000
    return shouldShowBetaBannerAgain
  }
  return true
}

const BetaAlert = () => {
  const dispatch = useDispatch()
  const isBetaAlertIsVisible = useSelector((state: RootState) => state.responsiveService.isBetaAlertVisible)

  useEffect(() => {
    dispatch(setIsBetaAlertVisible(shouldShowBetaBannerAgain()))
  }, [])


  const handleCloseBetaAlert = () => {
    setBetaAlertDismissedToLocalStorage()
    dispatch(setIsBetaAlertVisible(false))
  }

  return (
    <Collapse in={isBetaAlertIsVisible}>
      <Alert onClose={handleCloseBetaAlert} severity="warning">This service is still in beta phase! If you have any questions please contact shop(at)omapstore.com</Alert>
    </Collapse>
  )
}

export default BetaAlert