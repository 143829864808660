import React from 'react';
import { DeviceGroup, EventVisibility } from '../../utils/types';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { UserRole } from '../../services/authService';

interface EventVisibilitySelectionProps {
  eventVisibility: EventVisibility,
  setEventVisibility: (eventVisibility: EventVisibility) => void,
  eventPassword: string,
  setEventPassword: (newEventPassword: string) => void,
  eventIsVisibleForGroupIds: number[],
  setEventIsVisibleForGroupIds: (groupIds: number[]) => void,
  deviceGroups: DeviceGroup[],
  userRole: UserRole | null
}

const getAvailableEventVisibilitiesBasedOnUserRole = (userRole: UserRole | null) => {
  if (userRole === UserRole.PREMIUM) {
    return Object.entries(EventVisibility)
  }
  return [['PUBLIC_HIDDEN', EventVisibility.PUBLIC_HIDDEN]] as [string, EventVisibility][]
}

const EventVisibilitySelection = ({ eventVisibility, setEventVisibility, eventPassword, setEventPassword, eventIsVisibleForGroupIds, setEventIsVisibleForGroupIds, deviceGroups, userRole }: EventVisibilitySelectionProps) => {
  const handleGroupSelectionChange = (groupId: number) => {
    if (eventIsVisibleForGroupIds.includes(groupId)) {
      setEventIsVisibleForGroupIds(eventIsVisibleForGroupIds.filter((id) => id !== groupId))
    } else {
      setEventIsVisibleForGroupIds(eventIsVisibleForGroupIds.concat([groupId]))
    }
  }
 
  return (
    <Box>
      <FormControl>
        <FormLabel id="event-visibility-radio-buttons-group-label">Event visibility</FormLabel>
        <RadioGroup
          aria-labelledby="event-visibility-radio-buttons-group-label"
          name="event-visibility-radio-buttons-group"
        >
          { getAvailableEventVisibilitiesBasedOnUserRole(userRole).map(([value, eventVisibilityEnum]) => {
            return (
             <Grid container key={`event-visibility-selection-${value}`}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel value={eventVisibilityEnum} control={<Radio />} label={value} onChange={() => setEventVisibility(eventVisibilityEnum)} checked={eventVisibility === eventVisibilityEnum}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {
                    eventVisibilityEnum === EventVisibility.LINK_AND_PASSWORD && eventVisibility === EventVisibility.LINK_AND_PASSWORD &&
                    <Box>
                      <TextField
                        name="eventPassword"
                        required
                        id="eventPassword"
                        label="Event password"
                        value={eventPassword}
                        onChange={({ target }) => setEventPassword(target.value)}
                      />
                    </Box>
                  }
                  {
                    eventVisibilityEnum === EventVisibility.GROUPS && eventVisibility === EventVisibility.GROUPS &&
                    <Box sx={{ display: 'flex' }}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Event is visible for groups</FormLabel>
                        <FormGroup>
                          { deviceGroups.map((group) =>
                              <FormControlLabel
                                key={`visibility-group-checkbox-${group.name}`}
                                control={
                                  <Checkbox checked={eventIsVisibleForGroupIds.includes(group.id)} onChange={() => handleGroupSelectionChange(group.id)} name={`visibility-group-checkbox-${group.name}`} />
                                }
                                label={group.name}
                              />
                            )
                          }
                        </FormGroup>
                      </FormControl>
                    </Box>
                  }
                </Grid>
              </Grid>
            )
          })
          }
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default EventVisibilitySelection
