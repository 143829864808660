import { Runner, RunnerType } from './types'

const getMinAndMaxTimesFromRunners = (runners: Runner[]) => {
  // assume that records are in order
  const earliestTimestamp = runners.reduce((minAndMaxTime, runner) => {
    const gpsOrGpxRecords = runner.type === RunnerType.GPS ? runner.device.gpsRecords : runner.gpxRecords
    const firstFiveRecordsSkipped = gpsOrGpxRecords.slice(gpsOrGpxRecords.length > 5 ? 5 : 0)
    if (firstFiveRecordsSkipped.length > 0) {
      const firstTimeStampInGpsRecords = firstFiveRecordsSkipped[0].timestamp
      const lastTimeStampInGpsRecords = firstFiveRecordsSkipped[firstFiveRecordsSkipped.length - 1].timestamp
      if (minAndMaxTime.minTime > firstTimeStampInGpsRecords) {
        minAndMaxTime.minTime = firstTimeStampInGpsRecords
      }
      if (minAndMaxTime.maxTime < lastTimeStampInGpsRecords) {
        minAndMaxTime.maxTime = lastTimeStampInGpsRecords
      }
    }
    return minAndMaxTime
  }, {
    minTime: Date.now(),
    maxTime: 0
  })

  return earliestTimestamp
}

export default getMinAndMaxTimesFromRunners;
