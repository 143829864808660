import React, { useEffect } from 'react'
import { socket } from './socket'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store';
import { BoxType, GameEvent, GameEventType, GameMode } from '../../types';
import { appendGameEvents, setBoxType } from '../../gameService/gameServiceSlice';
import { toast } from '../../../utils/toast';

interface PlayerNameMap {
  [playerId: number]: string | undefined
}

const LiveGameEvents = () => {
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const gameId = useSelector((state: RootState) => state.gameService.gameId)
  const gameMode = useSelector((state: RootState) => state.gameService.gameMode)
  const players = useSelector((state: RootState) => state.gameService.players)
  const dispatch = useDispatch()

  useEffect(() => {
    if (gameMode !== GameMode.LIVE || !gameId) {
      return
    }

    const playerIdsToRunnerNames = () => {
      return players.reduce((map, player) => {
        const playerName = runners.find((runner) => runner.id === player.runnerId)?.displayName
        map[player.id] = playerName
        return map
      }, {} as PlayerNameMap)
    }

    const gameEventToToastString = (gameEvent: GameEvent, playerNameMap: PlayerNameMap) => {
      const playerName = gameEvent.playerId ? playerNameMap[gameEvent.playerId] : ''
      const targetPlayerName = gameEvent.targetPlayerId ? playerNameMap[gameEvent.targetPlayerId] : ''
    
      if (playerName && targetPlayerName) {
        switch (gameEvent.type) {
          case GameEventType.MUST_DRINK:
            return `${playerName} gave refreshment ticket to ${targetPlayerName}`
        }
      } else if (playerName) {
        switch (gameEvent.type) {
          case GameEventType.DOUBLE_RESOURCES:
            return `${playerName} doubled their resources`
          case GameEventType.DRUNK:
            return `${playerName} took refreshments`
          case GameEventType.SEND_MUST_DRINK_RECEIVED:
            return `${playerName} found refreshment ticket`
          case GameEventType.SHOT: {
            const whoseSalpalinjasWasShot = gameEvent.playerIdsWhoseSalpalinjaWasShot
            const playerNames = whoseSalpalinjasWasShot?.map((playerId) => playerNameMap[playerId])
            return `${playerName} destroyed salpalinjas of ${playerNames?.join(', ')}`
          }
        }
      }
      return null
    }

    const onConnect = () => {
      socket.emit('joinGameLive', gameId, (gameIdRes) => {
        console.log('Client listening game events from gameId: ', gameIdRes)
      })
    }

    const onDisconnect = () => {
      //dispatch(setLiveIsConnected(false))
      console.log('Client not listening game events for gameId', gameId)
    }

    const onLiveGameEvents = (liveGameEvents: GameEvent[]) => {
      dispatch(appendGameEvents({ gameEvents: liveGameEvents, runners }))
      liveGameEvents.forEach((gameEvent) => {
        const toastText = gameEventToToastString(gameEvent, playerIdsToRunnerNames())
        if (toastText) {
          toast.info(toastText)
        }
      })
    }

    const onLiveSetBoxType = (boxId: number, newBoxType: BoxType) => {
      dispatch(setBoxType({ boxId, newBoxType }))
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    socket.on('liveGameEvents', onLiveGameEvents);
    socket.on('setBoxType', onLiveSetBoxType)

    socket.connect()

    return () => {
      console.log('Live game events unmounted')
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('liveGameEvents', onLiveGameEvents)
      socket.off('setBoxType', onLiveSetBoxType)
      socket.disconnect();
    }
  }, [gameMode, gameId, dispatch])

  return null
};

export default LiveGameEvents
