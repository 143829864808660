import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { toast, toastHandlePromise } from '../../utils/toast'
import { axiosInstance } from '../../utils/axiosInstance'
import { AxiosError } from 'axios'
import axiosErrorToToastString from '../../utils/axiosErrorToToastString'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'


const SaveSalpalinjasDialog = () => {
  const [open, setOpen] = useState(false)

  const runners = useSelector((state: RootState) => state.eventService.runners)
  const gridPolygons = useSelector((state: RootState) => state.gameService.gridPolygons)
  const gameId = useSelector((state: RootState) => state.gameService.gameId)

  const [selectedRunnerId, setSelectedRunnerId] = useState<number | null>(null)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectCancel = () => {
    handleClose()
  }

  const handleSelectOk = async () => {
    if (!selectedRunnerId) {
      toast.error('Please select your runner from list')
      return
    }

    handleClose()
    await handleSaveSalpalinja()
  }

  const handleSaveSalpalinja = async () => {
    const salpalinjaSpBoxIds = gridPolygons.filter((feature) => {
      return feature.properties?.hasSalpalinja && feature.id && !isNaN(Number(feature.id))
    }).map((salpalinja) => Number(salpalinja.id))

    // TODO: parametrize the number of salpalinajas to game table
    const MUST_HAVE_NUMBER_OF_SALPALINJAS = 20
    if (salpalinjaSpBoxIds.length < MUST_HAVE_NUMBER_OF_SALPALINJAS) {
      toast.error(`You have not used all of your salpalinjas. You must build ${MUST_HAVE_NUMBER_OF_SALPALINJAS} salpalinjas.`)
      return
    }
    if (salpalinjaSpBoxIds.length > MUST_HAVE_NUMBER_OF_SALPALINJAS) {
      toast.error(`You have too many salpalinjas. You must build only ${MUST_HAVE_NUMBER_OF_SALPALINJAS} salpalinjas.`)
      return
    }

    // TODO: ask runner id here

    const createSalpalinjasPromise = axiosInstance.post('/api/game/create-salpalinjas', {
      runnerId: selectedRunnerId,
      gameId: gameId,
      spBoxIds: salpalinjaSpBoxIds
    })

    await toastHandlePromise(createSalpalinjasPromise, {
      pending: 'Building salpalinjas...',
      success: {
        render: () => {
          return 'Salpalinjas built'
        }
      },
      error: {
        render: ({ data }) => {
          if (data instanceof AxiosError) {
            const axiosErrorMsg = axiosErrorToToastString(data)
            return `Building salpalinjas failed. ${axiosErrorMsg}`
          }
        }
      }
    })
  }

  return (
    <div>
      <Button sx={{ width: 300, mt: 1 }} variant="contained" onClick={handleClickOpen}>
        Save salpalinjas
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="save-salpalinja-dialog-title"
        aria-describedby="save-salpalinja-dialog-description"
      >
        <DialogTitle id="save-salpalinja-dialog-title">
          Save salpalinjas
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="save-salpalinja-dialog-description">
            Please select your runner from the list. Select runner that corresponds to the gps device you are going to run.
          </DialogContentText>
          <FormControl>
            <FormLabel id="select-runner-for-game-radio-buttons-group-label">Runner</FormLabel>
            <RadioGroup
              aria-labelledby="select-runner-for-game-radio-buttons-group-label"
              defaultValue="female"
              name="select-runner-for-game-radio-buttons-group"
              onChange={({ target }) => setSelectedRunnerId(Number(target.value))}
            >
              { runners.map((runner) => (
                <FormControlLabel key={`runner-radio-button-${runner.id}`} checked={runner.id === selectedRunnerId} value={runner.id} control={<Radio />} label={`${runner.name} - ${runner.displayName}`} />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectCancel}>Cancel</Button>
          <Button onClick={handleSelectOk} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SaveSalpalinjasDialog
