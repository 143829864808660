import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'leaflet/dist/leaflet.css'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './components/Auth/AuthProvider'
import { SideNavigationProvider } from './components/SideNavigation/SideNavigationProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Provider store={store}>
    <Router>
      <AuthProvider>
        <SideNavigationProvider>
          <App />
        </SideNavigationProvider>
      </AuthProvider>
    </Router>
  </Provider>
)
