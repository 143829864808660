import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../utils/axiosInstance'
import { toast } from '../utils/toast'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { EventType } from '../utils/types'
import Chip from '@mui/material/Chip'
import { LiveLoader } from './Loaders'
import Stack from '@mui/material/Stack'
import Page from './Page'

interface PublicEvent {
  name: string,
  uuid: string,
  date: Date,
  eventType: EventType
}

interface EventListProps {
  events: PublicEvent[]
}

const getEventTypeChip = (eventType: EventType) => {
  switch (eventType) {
    case EventType.GPX:
      return <Stack direction="row" spacing={1}>
        <Chip label='GPS' />
        <Chip label='GPX' />
      </Stack>
    case EventType.LIVE:
      return <Chip
        sx={{ p: 1 }}
        label='Live'
        color='success'
        variant='outlined'
        icon={<LiveLoader visible size={30} color='red' />}
      />
    default:
      return null
  }
}

const EventList = ({ events }: EventListProps) => {
  return (
    <List>
      {events.map((event, i) => {
        return (
          <ListItem
            key={`public-event-list-item-${event.name}-${i}`}
            sx={{ p: 0 }}
          >
            <ListItemText
              primary={<Link to={`/event/${event.uuid}`}>{event.name}</Link>}
              secondary={dayjs(event.date).format('YYYY-MM-DD')}
            />
            {getEventTypeChip(event.eventType)}
          </ListItem>
        )
      })
      }
    </List>
  )
}

const Home = () => {
  const [publicEvents, setPublicEvents] = useState<PublicEvent[]>([])

  useEffect(() => {
    const fetchPublicEvents = async () => {
      const { data: publicEvents } = await axiosInstance.get<PublicEvent[]>('/api/event/public-events')
      setPublicEvents(publicEvents)
    }

    fetchPublicEvents()
      .catch((_e) => {
        toast.error('Failed to load events')
      })
  }, [])

  return (
    <Page title='Events'>
      <EventList events={publicEvents} />
    </Page>
  )
}

export default Home