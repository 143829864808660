import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setPanRunnerId, toggleCourseIsVisible, toggleRunnerIsVisible } from '../features/eventService/eventServiceSlice';

const CourseList = () => {
  const dispatch = useDispatch()
  const courses = useSelector((state: RootState) => state.eventService.courses)
  const visibleCourseIds = useSelector((state: RootState) => state.eventService.visibleCourseIds)
  const visibleRunnerIds = useSelector((state: RootState) => state.eventService.visibleRunnersIds)
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const panRunnerId = useSelector((state: RootState) => state.eventService.panRunnerId)

  const courseIsVisible = (courseId: number | undefined) => visibleCourseIds.includes(courseId ?? -1)

  const toggleCourseVisible = (courseId: number | undefined) => {
    if (courseId) {
      dispatch(toggleCourseIsVisible(courseId))
    }
  }

  const toggleRunnerVisibility = (runnerId: number | undefined) => {
    if (runnerId) {
      dispatch(toggleRunnerIsVisible(runnerId))
    }
  }

  const setRunnerToPan = (checked: boolean, runnerId?: number) => {
    if (checked && runnerId) {
      dispatch(setPanRunnerId(runnerId))
    } else {
      dispatch(setPanRunnerId(null))
    }
  }

  return (
    <Box>
      {
        courses.map((course) => {
          const courseRunners = runners.filter((runner) => runner.courseId === course.id)

          return (
            <Accordion key={`course-accordion-${course.id}`} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <FormControlLabel control={<Checkbox />} checked={courseIsVisible(course.id)} onChange={() => toggleCourseVisible(course.id)} onClick={(e) => e.stopPropagation()} label={course.name} />
              </AccordionSummary>
              <AccordionDetails>
                {
                  courseRunners.map((runner) => {
                    return (
                      <Box key={`course-runner-selection-${runner.id}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel control={<Checkbox />} checked={visibleRunnerIds.includes(runner.id ?? -1)} onChange={() => toggleRunnerVisibility(runner.id)} label={runner.name}/>
                        <FormControlLabel control={<Checkbox />} checked={panRunnerId === runner.id} onChange={(_event, checked) => setRunnerToPan(checked, runner.id)} label="Follow" />
                      </Box>
                    )
                  })
                }
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </Box>
  )
}

export default CourseList
