import { Socket, io } from 'socket.io-client'
import { BoxType, GameEvent } from '../../types';

interface ServerToClientEvents {
  liveGameEvents: (gameEvents: GameEvent[]) => void
  setBoxType: (spBoxId: number, newBoxType: BoxType) => void
}

interface ClientToServerEvents {
  joinGameLive: (gameId: number, callback: (gameId: number | null) => void) => void;
}

// empty string means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? '' : 'ws://localhost:4000';

// use 'salpalinjapitaa' namespace so we can use same ws connection also for "liveUpdates"
export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(`${URL}/salpalinjapitaa`, {
  autoConnect: false, // TODO: maybe this could be true
  path: '/ws/salpalinjapitaalive'
})