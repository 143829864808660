export interface GameEvent {
  id: number,
  playerId: number,
  spBoxId: number,
  type: GameEventType,
  timestamp: number, // milliseconds
  targetPlayerId: number
  playerIdsWhoseSalpalinjaWasShot?: number[] // this exists only for shot events
}

export enum GameEventType {
  SET_TARGET = 'SET_TARGET',
  SHOT = 'SHOT',
  MISS = 'MISS',
  SEND_MUST_DRINK_RECEIVED = 'SEND_MUST_DRINK_RECEIVED',
  MUST_DRINK = 'MUST_DRINK',
  DRUNK = 'DRUNK',
  DOUBLE_RESOURCES = 'DOUBLE_RESOURCES',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE'
}

export enum GameMode {
  BUILD = 'BUILD',
  LIVE = 'LIVE',
  REPLAY = 'REPLAY'
}

export interface Player {
  id: number,
  runnerId: number,
  userId: number,
  salpalinjas: {
      id: number,
      spBoxId: number,
  }[]
}

export interface GetGameData {
  gameId: number,
  gameMode: GameMode,
  boxes: {
    id: number,
    bounds: {
      type: 'Polygon',
      coordinates: number[][][]
    },
    spBoxType: BoxType
  }[]
  players: Player[]
  gameEvents: GameEvent[]
}

export enum BoxType {
  NORMAL = 'NORMAL',
  SEND_MUST_DRINK = 'SEND_MUST_DRINK',
  MUST_DRINK = 'MUST_DRINK',
  DOUBLE_RESOURCES = 'DOUBLE_RESOURCES',
  DISABLED = 'DISABLED'
}