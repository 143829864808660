import React, { Fragment, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Drawer from '@mui/material/Drawer';
import { FormControl, FormControlLabel, InputLabel, List, ListItem, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { setIsLive, setTailLength, setTickSpeed } from '../features/timeSlider/timeSliderSlice';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fi';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { EventMode, EventType } from '../utils/types';
import { setEventMode, setOsmOn, setShowRoute } from '../features/eventService/eventServiceSlice';
import { UserRole } from '../services/authService';
import { useAuth } from './Auth/AuthProvider';
 
const EventSettings = () => {
  const { userRole } = useAuth()
  const [open, setOpen] = useState(false);
  const [tailLengthTimePicker, setTailLengthTimePicker] = useState<Dayjs | null>(dayjs('2020-05-27T00:01:00'))
  const dispatch = useDispatch();

  const currentSpeed = useSelector((state: RootState) => state.timeSlider.tickSpeed)
  const tailLength = useSelector((state: RootState) => state.timeSlider.tailLength)
  const eventType = useSelector((state: RootState) => state.eventService.eventDetails?.eventType)
  const eventMode = useSelector((state: RootState) => state.eventService.eventMode)
  const osmOn = useSelector((state: RootState) => state.eventService.osmOn)
  const showRoute = useSelector((state: RootState) => state.eventService.showRoute)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSpeedChange = (event: SelectChangeEvent) => {
    const newTickSpeed = Number(event.target.value)
    dispatch(setTickSpeed(newTickSpeed))
  }

  const handleTailChange = (input: SelectChangeEvent | number) => {
    if (typeof input === 'number') {
      const seconds = input
      var h = Math.floor((seconds % (3600 * 24)) / 3600)
      var m = Math.floor((seconds % 3600) / 60)
      var s = Math.floor(seconds % 60)
      setTailLengthTimePicker(dayjs(`2023-05-27T${h}:${m}:${s}`))

      dispatch(setTailLength(input))
    } else {
      const newTailLength = Number(input.target.value)
      dispatch(setTailLength(newTailLength))
    }
  }

  const handleTailTimePickerChange = (input: Dayjs | null) => {
    if (input !== null) {
      const h = input.hour() * 3600
      const m = input.minute() * 60
      const s = input.second()

      setTailLengthTimePicker(input)
      dispatch(setTailLength(h + m + s))
    }
  }

  

  const speedCoefficients = [1, 2, 5, 10, 20, 50, 100]

  const handleModeChange = (newMode: EventMode) => {
    dispatch(setIsLive(newMode === EventMode.LIVE))
    dispatch(setEventMode(newMode))
  }

  return (
    <Fragment>
      <IconButton color='primary' onClick={handleOpen}>
        <SettingsIcon fontSize='large' />
      </IconButton>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={handleClose}
      >
        <List>
          <ListItem key="event-settings-speed">
            <FormControl>
              <InputLabel id="event-settings-speed-select-label">Speed</InputLabel>
              <Select
                labelId='event-settings-speed-select-label'
                id="event-settings-speed-select"
                value={currentSpeed.toString()}
                label="Speed"
                onChange={handleSpeedChange}
              >
                { speedCoefficients.map((speedCoefficient) => 
                  <MenuItem key={`speed-menuitem-${speedCoefficient}`} value={speedCoefficient}>{speedCoefficient}x</MenuItem>
                )}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem key="event-setttings-tail-length">
               {/*<InputLabel id="event-settings-tail-length-select-label">Tail length</InputLabel>
              <Select
                labelId="event-settings-tail-length-select-label"
                id="event-settings-tail-length-select"
                value={tailLength.toString()}
                label="Tail"
                onChange={handleTailChange}
              >
                <MenuItem value={30}>30s</MenuItem>
                <MenuItem value={60}>1 min</MenuItem>
                <MenuItem value={180}>3 min</MenuItem>
                <MenuItem value={300}>5 min</MenuItem>
                <MenuItem value={600}>10 min</MenuItem>
              </Select> */}
              {
                !showRoute &&
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                      <TimeField
                        label="Tail length"
                        value={tailLengthTimePicker}
                        onChange={handleTailTimePickerChange}
                        format="HH:mm:ss"
                      />
                    </LocalizationProvider>
                    <IconButton onClick={() => handleTailChange(tailLength > 60 ? tailLength - 60 : 0)}>
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={() => handleTailChange(tailLength + 60) }>
                      <AddIcon />
                    </IconButton>
                  </>
              }
              <FormControlLabel onChange={() => dispatch(setShowRoute(!showRoute))} control={<Switch checked={showRoute}/>} label="Route" />
          </ListItem>
          { eventType === EventType.LIVE
            && <FormControl sx={{ ml: 2 }}>
            <RadioGroup
              name='event-show-mode-selection-radio-button-group'
              value={eventMode}
              onChange={({ target }) => handleModeChange(target.value as EventMode)}
            >
              { Object.values(EventMode).map((eventMode) => {
                return <FormControlLabel key={`${eventMode}-live-button-group`} value={eventMode} control={<Radio />} label={eventMode} /* onSelect={() => handleModeChange(eventMode)}  *//>
              })}
            </RadioGroup>
          </FormControl>
          }
          {
            userRole === UserRole.ADMIN &&
            <ListItem>
              <FormControlLabel onChange={() => dispatch(setOsmOn(!osmOn))} control={<Switch checked={osmOn}/>} label="Osm off/on" />
            </ListItem>
          }
        </List>
      </Drawer>
    </Fragment>
  )
}

export default EventSettings;
