import React, { useEffect, useRef, useState } from 'react'

import Map, { MapRef, NavigationControl } from 'react-map-gl'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';

import { getStyle } from './style'
import { Feature, Polygon } from 'geojson'
import calcRunnerFeatures from './calcRunnerFeatures'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import getMapAngle from '../../../utils/getMapAngle'
import getDeclination from '../../../utils/getDeclination'
import { RunnerType } from '../../../utils/types'
import { lineString } from '@turf/helpers'
import bbox from '@turf/bbox'
import MapGrid from './MapGrid'
import BoxActions from './BoxActions'
import { BoxStatus } from './enums'
import { useAuth } from '../../../components/Auth/AuthProvider'
import { UserRole } from '../../../services/authService'
import { setGridPolygons } from '../../gameService/gameServiceSlice'
import { GameMode } from '../../types'
import SaveSalpalinjasDialog from '../SaveSalpalinjasDialog'
import LiveUpdates from '../../../components/LiveUpdates';
import { useSideNavigation } from '../../../components/SideNavigation/SideNavigationProvider';


const GameField = ({ children }: React.PropsWithChildren) => {
  const dispatch = useDispatch()
  const { userRole } = useAuth()
  const { toggled, collapsed } = useSideNavigation()
  const isPortrait = useSelector((state: RootState) => state.responsiveService.isPortrait)
  
  const map = useSelector((state: RootState) => state.eventService.map)
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const currentTimestamp = useSelector((state: RootState) => state.timeSlider.currentTime)
  const visibleRunnerIds = useSelector((state: RootState) => state.eventService.visibleRunnersIds)
  const tailInSeconds = useSelector((state: RootState) => state.timeSlider.tailLength)
  const showRoute = useSelector((state: RootState) => state.eventService.showRoute)
  const panToRunnerId = useSelector((state: RootState) => state.eventService.panRunnerId)
  const isLive = useSelector((state: RootState) => state.timeSlider.isLive)
  
  const gameMode = useSelector((state: RootState) => state.gameService.gameMode)
  const gridPolygons = useSelector((state: RootState) => state.gameService.gridPolygons)

  const [longTouchPressTimeout, setLongTouchPressTimeout] = useState<NodeJS.Timeout | null>(null)

  const mapRef = useRef<MapRef | null>(null)
  const [runnerGeojson, setRunnerGeojson] = useState<Feature[]>([])
  //const [gridPolygons, setGridPolygons] = useState<Feature[]>([])
  const [selectedBox, setSelectedBox] = useState<Feature<Polygon> | null>(null)

  const [loading, _setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (newLoading: boolean) => {
    loadingRef.current = newLoading
    _setLoading(newLoading)
  }

/*   useEffect(() => {
    const interval = setInterval(() => {
      const getRandomInt = (max: number) => {
        return Math.floor(Math.random() * max)
      }
      const getRandomGameEventType = () => {
        const r = getRandomInt(3)
        if (r === 0) return GameEventType.SET_TARGET
        if (r === 1) return GameEventType.MISS
        else return GameEventType.SHOT
      }
      const testGameEvent: GameEvent = {
        id: getRandomInt(1000),
        playerId: 1,
        spBoxId: Number(`${getRandomInt(30)}${getRandomInt(30)}`),
        type: getRandomGameEventType(),
        timestamp: Date.now(), // milliseconds
      }
      dispatch(appendGameEvents(testGameEvent))
    }, 1000)

    return () => clearInterval(interval)
  }, []) */

  useEffect(() => {
    if (mapRef) {
      if (isPortrait) {
        mapRef.current?.resize()
      } else {
        // ensures that map canvas is updated when the sidebar menu is full disappeared
        const timeout = setTimeout(() => {
          mapRef.current?.resize()
        }, 300)

        return () => {
          clearTimeout(timeout)
        }
      }  
    }
  }, [toggled, collapsed, isPortrait])

  // this updates runners' positions and tails
  useEffect(() => {
    const source = mapRef.current?.getSource('trace')

    if (source && source.type === 'geojson') {
      const newRunnerGeojson = calcRunnerFeatures(runners, currentTimestamp, visibleRunnerIds, tailInSeconds, showRoute, panToRunnerId, mapRef)
      setRunnerGeojson(newRunnerGeojson)
    }
  }, [runners, currentTimestamp, visibleRunnerIds, tailInSeconds, showRoute, panToRunnerId])

  const onMapLoad = () => {
    if (!mapRef.current) {
      return
    }

    mapRef.current.loadImage(
      '/helmet.png',
      (error, image) => {
        if (error) {
          console.log('failed to load image')
        }
        image && mapRef.current?.addImage('helmet', image)
      }
    )

    if (map) {
      const { bounds } = map
      mapRef.current.once('moveend', () => {
        setLoadingRef(false)
      })

      const mapBearing = map?.mapFilePath ? getMapAngle(map.bounds)
        : map?.omapstoreMapUuid ? getDeclination(map.bounds)
        : 0

      mapRef.current.fitBounds([bounds[0], bounds[2]], { bearing: mapBearing, animate: false })

      return
    }

    // set view to location where some runner is based on gps data if map is not provided
    const runnerWithGpsOrGpxData = runners.find((runner) => {
      if (runner.type === RunnerType.GPS) {
        return runner.device.gpsRecords.length > 0
      } else {
        return runner.gpxRecords.length > 0
      }
    })
    if (runnerWithGpsOrGpxData) {
      mapRef.current.once('moveend', () => {
        setLoadingRef(false)
      })
      const gpxRecords = runnerWithGpsOrGpxData.type === RunnerType.GPS ? runnerWithGpsOrGpxData.device.gpsRecords : runnerWithGpsOrGpxData.gpxRecords
      
      const turfLineString = lineString(gpxRecords.map((gpxRecord) => [gpxRecord.longitude, gpxRecord.latitude]))
      const boundsForGpxRecords = bbox(turfLineString)
      mapRef.current.fitBounds([[boundsForGpxRecords[0], boundsForGpxRecords[1]], [boundsForGpxRecords[2], boundsForGpxRecords[3]]], { animate: false })
    }
  }

  const handleSetSelectedbox = (features: mapboxgl.MapboxGeoJSONFeature[] | undefined) => {
    if (userRole === null || gameMode === GameMode.REPLAY) {
      return null
    }

    if (features && features.length > 0) {
      const firstFeature = features[0]
      if (firstFeature && firstFeature.geometry.type === 'Polygon') {
        if (userRole === UserRole.ADMIN || (firstFeature.properties && 'status' in firstFeature.properties && firstFeature.properties.status !== BoxStatus.DISABLED)) {
          setSelectedBox({
            id: firstFeature.id,
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: firstFeature.geometry.coordinates
            },
            properties: firstFeature.properties
          })
        }
      }
    } else {
      setSelectedBox(null)
    }
  }

  const handleClick = (e: MapLayerMouseEvent) => {
    handleSetSelectedbox(e.features)
  }

  const updateBox = (updatedBox: Feature<Polygon>) => {
    const newPolygons = gridPolygons.map((box) => {
      if (box.id === updatedBox.id) {
        return updatedBox
      } else {
        return { ...box }
      }
    })
    dispatch(setGridPolygons(newPolygons))
    setSelectedBox(null)
  }

  const showTreasures = userRole === UserRole.ADMIN


  const clearLongTouchPressTimeout = () => {
    if (longTouchPressTimeout) {
      clearTimeout(longTouchPressTimeout)
    }
    setLongTouchPressTimeout(null)
  }

  const handleOnTouchStart = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
    const timeout = setTimeout(() => {
      handleSetSelectedbox(e.features)
    }, 500)

    setLongTouchPressTimeout(timeout)
  }

  const handleOnTouchEnd = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
  }

  const handleOnTouchMove = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
  }

  const handleOnTouchCancel = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
  }

  return (
    <Map
      ref={mapRef}
      mapLib={maplibregl}
      mapStyle={getStyle(runnerGeojson, gridPolygons, selectedBox, showTreasures, map)}
      style={{ maxWidth: '100%' }}
      onLoad={onMapLoad}
      fadeDuration={0}
      id="game-map"
      interactiveLayerIds={['grid-polygon']}
      onClick={handleClick}
      onTouchStart={handleOnTouchStart}
      onTouchMove={handleOnTouchMove}
      onTouchEnd={handleOnTouchEnd}
      onTouchCancel={handleOnTouchCancel}
    >
      <NavigationControl />
      { children }
      { map && gridPolygons.length <= 0 && userRole === UserRole.ADMIN && <MapGrid mapBounds={map.bounds} /> }
      {/* { gridPolygons.length > 0 &&
        <Source
          id='map-grid'
          type='geojson'
          data={{
            type: 'FeatureCollection',
            features: gridPolygons
          }}
        >
          <Layer
            id='grid-line'
            type='line'
            paint={{
              'line-color': 'purple',
              'line-width': 2
            }}
          />
          <Layer
            id='grid-polygon'
            type='fill'
            paint={{
              'fill-color': ['get', 'color'],
              'fill-opacity': 0.5
            }}
          />
        </Source>
        
      } */}
      {/* { selectedBox &&
        <Marker anchor='top-left' latitude={selectedBox.geometry.coordinates[0][0][1]} longitude={selectedBox.geometry.coordinates[0][0][0]}>
          <img style={{ width: 20, height: 20 }} alt='helmet icon' src='/helmet.png' />
        </Marker>
      } */}

      { selectedBox && <BoxActions box={selectedBox} updateBox={updateBox} clearSelectedBox={() => setSelectedBox(null)} />}
      { gameMode === GameMode.BUILD && userRole === UserRole.PREMIUM && <SaveSalpalinjasDialog />}
      { isLive && <LiveUpdates /> }
    </Map>
  )
}

export default GameField
