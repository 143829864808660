import { createSlice } from '@reduxjs/toolkit'
import { GpsPoint } from './gpxService'

export interface GpxServiceState {
  gpsTrack: GpsPoint[] | null
}

const initialState: GpxServiceState = {
  gpsTrack: null
}

export const gpxServiceSlice = createSlice({
  name: 'gpxService',
  initialState,
  reducers: {
    setGpsTrack: (state, action) => {
      state.gpsTrack = action.payload
    }
  }
})

export const { setGpsTrack } = gpxServiceSlice.actions

export default gpxServiceSlice.reducer