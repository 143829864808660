import { Socket, io } from 'socket.io-client';
import { LiveData } from './utils/types';

interface ServerToClientEvents {
  liveData: (liveData: LiveData) => void
}

interface ClientToServerEvents {
  joinLive: (eventId: number, callback: (eventId: number | null) => void) => void;
}

// empty string means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? '' : 'ws://localhost:4000';

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(URL, {
  autoConnect: false,
  path: '/ws/'
})
