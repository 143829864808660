import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../utils/axiosInstance'
import { UserRole, isTokenExpired } from '../../services/authService'
import { removeAuthorizationToken, setAuthorizationToken } from '../../utils/axiosInstance'
import { clearAuthLocalStorage, getAuthLocalStorage, getFromLocalStorage, setAuthLocalStorage } from '../../localStorage'
import { AxiosError } from 'axios'
import axiosErrorToToastString from '../../utils/axiosErrorToToastString'

interface AuthContextData {
  token: string | null,
  email: string | null,
  userId: number | null,
  userRole: UserRole | null,
  authReady: boolean,
  handleLogin: (email: string, password: string) => Promise<void | string>,
  handleLogOut: (forwardPath?: string, fromPath?: string) => void
}

type AuthContextType = AuthContextData | null

const AuthContext = createContext<AuthContextType>(null)

export const useAuth = () => {
  const currentAuthContext = useContext(AuthContext)

  if (!currentAuthContext) {
    throw new Error("useAuth must be used within <AuthContext.Provider>")
  }

  return currentAuthContext
}

interface AuthProviderProps {
  children: React.ReactNode
}

interface LoginResponse {
  token: string,
  email: string,
  role: UserRole,
  userId: number
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const initialUserRole = getFromLocalStorage('userRole')

  const [token, setToken] = useState<string | null>(getFromLocalStorage('token'))
  const [email, setEmail] = useState<string | null>(getFromLocalStorage('email'))
  const [userId, setUserId] = useState<number>(Number(getFromLocalStorage('userId')))
  const [userRole, setUserRole] = useState<UserRole | null>(initialUserRole ? initialUserRole as UserRole : null)
  const [authReady, setAuthReady] = useState(false)

  useEffect(() => {
    const authFromLocalStorage = getAuthLocalStorage()
    if (authFromLocalStorage.token && isTokenExpired(authFromLocalStorage.token)) {
      clearAuthLocalStorage()
      setToken(null)
      setEmail(null)
      setUserRole(null)
      setAuthReady(false)
      return
    }

    if (authFromLocalStorage.token) {
      setAuthorizationToken(authFromLocalStorage.token)
    }

    setToken(authFromLocalStorage.token)
    setEmail(authFromLocalStorage.email)
    setUserRole(authFromLocalStorage.userRole as UserRole)
    setAuthReady(true)
  }, [])

  const handleLogin = async (email: string, password: string) => {
    try {
      const { data: loginResponse } = await axiosInstance.post<LoginResponse>('/api/login', { email, password })

      const bearerToken = `Bearer ${loginResponse.token}`
  
      setAuthLocalStorage(bearerToken, loginResponse.email, loginResponse.role, loginResponse.userId)
  
      setAuthorizationToken(bearerToken)
      setToken(loginResponse.token)
      setEmail(loginResponse.email)
      setUserId(loginResponse.userId)
      setUserRole(loginResponse.role)
      setAuthReady(true)

      const origin = location.state?.from?.pathname || '/'
      navigate(origin)
      return
    } catch (e) {
      if (e instanceof AxiosError) {
        return axiosErrorToToastString(e)
      }
    }
  }

  const handleLogOut = (forwardPath?: string, fromPath?: string) => {

    removeAuthorizationToken()
    clearAuthLocalStorage()
    setToken(null)
    setEmail(null)
    setUserRole(null)
    setAuthReady(false)

    navigate(forwardPath ?? '/', { replace: true, state: { from: { pathname: fromPath } }})
  }

  const value = {
    token,
    email,
    userId,
    userRole,
    authReady,
    handleLogin,
    handleLogOut
  }

  return (
    <AuthContext.Provider value={value}>
      { children }
    </AuthContext.Provider>
  )
}