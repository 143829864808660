import React from 'react'
import { Route } from 'react-router-dom'
import UsersList from './users/UsersList'
import EditUser from './users/EditUser'
import AdminRoute from './AdminRoute'

const getAdminRoutes = () => {
  return [
    <Route key="/admin/users-key" path='/admin/users' element={<AdminRoute><UsersList /></AdminRoute>}/>,
    <Route key="/admin/edit-user-key" path='/admin/edit-user/:userId' element={<AdminRoute><EditUser /></AdminRoute>}/>
  ]
}

export default getAdminRoutes

