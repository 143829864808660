import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'

import { axiosInstance } from '../../utils/axiosInstance'
import { toast, toastHandlePromise } from '../../utils/toast'
import { AxiosError } from 'axios'
import axiosErrorToToastString from '../../utils/axiosErrorToToastString'
import Page from '../Page'

export enum IntegrationTokenType {
  OMAPSTORE = 'OMAPSTORE'
}

interface IntegrationToken {
  id: number,
  name: string,
  type: IntegrationTokenType
}

const IntegrationTokens = () => {
  const navigate = useNavigate()
  const [integrationTokens, setIntegrationTokens] = useState<IntegrationToken[]>([])

  useEffect(() => {
    const fetchIntegrationTokens = async () => {
      const { data: tokens } = await axiosInstance.get<IntegrationToken[]>('/api/integration-tokens/my-tokens')
      setIntegrationTokens(tokens)
    }

    fetchIntegrationTokens()
      .catch((e) => {
        toast.error('Failed to load tokens.')
      })
  }, [])

  const handleDelete = async (tokenId: number) => {
    toastHandlePromise(axiosInstance.delete(`/api/integration-tokens/delete-token/${tokenId}`), {
      pending: "Deleting token",
      success: {
        render: () => {
          setIntegrationTokens([...integrationTokens].filter((token) => token.id !== tokenId))
          return "Token deleted"
        }
      },
      error: {
        render: ({ data }) => {
          if (data instanceof AxiosError) {
            return `Failed to delete token. ${axiosErrorToToastString(data)}`
          }
        }
      }
    })
  }

  return (
    <Page title="My integration tokens">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Token name</TableCell>
              <TableCell>type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              integrationTokens.map((token, i) => (
                <TableRow key={`integration-token-row-${token.name}-${i}`}>
                  <TableCell>{token.name}</TableCell>
                  <TableCell>{token.type}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(token.id)}><Delete color="error" /></IconButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Button onClick={() => navigate('/integration-tokens/add-new')}>Add new token</Button>
    </Page>
  )
}

export default IntegrationTokens
