import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from './Auth/AuthProvider';
import { useSideNavigation } from './SideNavigation/SideNavigationProvider';
import BetaAlert from './BetaAlert';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate()
  const { toggleSideNav } = useSideNavigation()
  const { handleLogOut, email } = useAuth()

  return (
    <AppBar position="static">
      <BetaAlert />
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleSideNav}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Otails
        </Typography>
        { email
          ? <>
            <Typography component='div'>
              Logged in as {email}
            </Typography>
            <Button color="inherit" onClick={() => handleLogOut()}>Logout</Button>
          </>
          : <>
            <Button color="inherit" onClick={() => navigate('/signup')}>Register</Button>
            <Button color="inherit" onClick={() => navigate('/signin')}>Login</Button>
          </>
        }
      </Toolbar>
    </AppBar>
  );
}

export default Header