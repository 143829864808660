import React, { useState } from 'react'

import { Feature, Polygon } from 'geojson'

import Box from '@mui/material/Box'
import { Button, Dialog, DialogTitle, FormControlLabel, List, ListItem, ListItemButton, ListItemText, Switch, Typography } from '@mui/material'
import { BoxStatus } from './enums'
import { useAuth } from '../../../components/Auth/AuthProvider'
import { UserRole } from '../../../services/authService'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { BoxType, GameEventType, GameMode, Player } from '../../types'
import { axiosInstance } from '../../../utils/axiosInstance'
import { toast, toastHandlePromise } from '../../../utils/toast'
import { AxiosError } from 'axios'
import axiosErrorToToastString from '../../../utils/axiosErrorToToastString'

interface BoxActionsProps {
  box: Feature<Polygon>
  updateBox: (box: Feature<Polygon>) => void,
  clearSelectedBox: () => void
}

const BoxActions = ({ box, updateBox, clearSelectedBox }: BoxActionsProps) => {
  const { userRole, userId } = useAuth()
  const gameMode = useSelector((state: RootState) => state.gameService.gameMode)
  const gameId = useSelector((state: RootState) => state.gameService.gameId)
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
  const [selectPlayerDialogOpen, setSelectPlayerDialogOpen] = useState(false)
  const gridPolygons = useSelector((state: RootState) => state.gameService.gridPolygons)
  const gameEvents = useSelector((state: RootState) => state.gameService.gameEvents)
  const players = useSelector((state: RootState) => state.gameService.players)
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const [adminBoxEditOn, setAdminBoxEditOn] = useState(false)

  const handleDisableBox = async () => {
    try {
      const disableBoxEventPromise = axiosInstance.post('/api/game/create-game-event', {
        gameId,
        spBoxId: box.id,
        eventType: GameEventType.DISABLE,
        playerId: null
      })
  
      await toastHandlePromise(disableBoxEventPromise, {
        pending: 'Disabling box... Please wait',
        success: 'Box disabled!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to disable box. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      // Do nothing
    } finally {
      clearSelectedBox()
    }
  }

  const handleEnableBox = async () => {
    /* const updatedBox = { ...box }
    updatedBox.properties = { ...updatedBox.properties, status: BoxStatus.FREE }
    updateBox(updatedBox) */
    try {
      const enableBoxEventPromise = axiosInstance.post('/api/game/create-game-event', {
        gameId,
        spBoxId: box.id,
        eventType: GameEventType.ENABLE,
        playerId: null
      })
  
      await toastHandlePromise(enableBoxEventPromise, {
        pending: 'Enabling box... Please wait',
        success: 'Box enabled!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to enable box. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      // Do nothing
    } finally {
      clearSelectedBox()
    }
  }

  const handleSetAsTarget = async () => {
    try {
      const ownSalpalinjaBoxIds = gridPolygons.filter((box) => box.properties?.hasSalpalinja).map((_) => _.id)
      const shotEventsToOwnSalpalinjas = gameEvents.filter((gameEvent) => gameEvent.type === GameEventType.SHOT && ownSalpalinjaBoxIds.includes(gameEvent.spBoxId))
      const allOwnSalpalinjasDestroyed = ownSalpalinjaBoxIds.length - shotEventsToOwnSalpalinjas.length === 0

      if (allOwnSalpalinjasDestroyed) {
        toast.error('Game over! All of your salpalinjas are destroyed.')
        return
      }

      const ownPlayer = players.find((player) => player.userId === userId)
      const mustDrink = gameEvents.filter((gameEvent) => gameEvent.targetPlayerId === ownPlayer?.id && gameEvent.type === GameEventType.MUST_DRINK).length
      const hasDrunk = gameEvents.filter((gameEvent) => gameEvent.playerId === ownPlayer?.id && gameEvent.type === GameEventType.DRUNK).length

      if (mustDrink !== hasDrunk) {
        toast.error('You must take refreshments before you can target more!')
        return
      }

      const setTargetPromise = axiosInstance.post('/api/game/set-target', {
        gameId,
        spBoxId: box.id
      })
  
      await toastHandlePromise(setTargetPromise, {
        pending: 'Targeting... Please wait',
        success: 'Target set. Run!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to set target. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      // Do nothing
    } finally {
      clearSelectedBox()
    }
  }

  const handleBuildSalpalinja = () => {
    const updatedBox = { ...box }
    updatedBox.properties = { ...updatedBox.properties, hasSalpalinja: true }
    updateBox(updatedBox)
  }

  const handleRemoveSalpalinja = () => {
    const updatedBox = { ...box }
    updatedBox.properties = { ...updatedBox.properties, hasSalpalinja: false }
    updateBox(updatedBox)
  }

  const handleShot = async () => {
    if (!selectedPlayer) {
      return
    }

    try {
      const createShotEventPromise = axiosInstance.post('/api/game/create-game-event', {
        gameId,
        spBoxId: box.id,
        eventType: box.properties?.hasSalpalinja ? GameEventType.SHOT : GameEventType.MISS,
        playerId: selectedPlayer.id
      })
  
      await toastHandlePromise(createShotEventPromise, {
        pending: 'Shotting... Please wait',
        success: 'Shot!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to shot. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      // Do nothing
    } finally {
      clearSelectedBox()
      setSelectedPlayer(null)
    }
  }

  const handleAdminSetTarget = async () => {
    if (!selectedPlayer) {
      return
    }

    try {
      const createTargetEventPromise = axiosInstance.post('/api/game/create-game-event', {
        gameId,
        spBoxId: box.id,
        eventType: GameEventType.SET_TARGET,
        playerId: selectedPlayer.id
      })
  
      await toastHandlePromise(createTargetEventPromise, {
        pending: 'Targeting... Please wait',
        success: 'Targeted!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to target. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      // Do nothing
    } finally {
      clearSelectedBox()
      setSelectedPlayer(null)
    }
  }

  const handleAdminSetBoxType = async (newBoxType: BoxType) => {
    try {
      const setBoxTypePromise = axiosInstance.post('/api/game/set-box-type', {
        gameId,
        spBoxId: box.id,
        boxType: newBoxType
      })
  
      await toastHandlePromise(setBoxTypePromise, {
        pending: 'Setting box type... Please wait',
        success: 'Box type set!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to set box type. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      // Do nothing
    } finally {
      const updatedBox = { ...box }
      const boxStatus = newBoxType === BoxType.DISABLED ? BoxStatus.DISABLED : BoxStatus.FREE 
      updatedBox.properties = { ...updatedBox.properties, type: newBoxType, status: boxStatus }
      updateBox(updatedBox)

      clearSelectedBox()
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: 300,  }}>
      { userRole === UserRole.ADMIN ? 
        <>
          <FormControlLabel control={<Switch checked={adminBoxEditOn} onChange={() => setAdminBoxEditOn(!adminBoxEditOn)}/>} label="Box edit off/on" />
          { selectedPlayer && <Box sx={{ position: 'relative' }}><Typography>Selected player: { runners.find((runner) => runner.id === selectedPlayer.runnerId)?.displayName }</Typography></Box>}
          {
            selectPlayerDialogOpen ?
              <Dialog onClose={() => setSelectPlayerDialogOpen(false)} open={selectPlayerDialogOpen}>
                <DialogTitle>Select player for action</DialogTitle>
                <List sx={{ pt: 0 }}>
                  { players.map((player) => (
                    <ListItem disableGutters key={`${player.id}-admin-select-player`}>
                      <ListItemButton onClick={() => { setSelectedPlayer(player); setSelectPlayerDialogOpen(false) }}>
                        <ListItemText primary={runners.find((runner) => runner.id === player.runnerId)?.displayName} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Dialog>
              : <Button onClick={() => setSelectPlayerDialogOpen(true)}>Select player</Button>
          }
          { gameMode === GameMode.LIVE && !box.properties?.hasSalpalinja && box.properties?.status === BoxStatus.FREE && <Button sx={{ my: 1 }} variant='contained' onClick={handleDisableBox}>Disable</Button> }
          { gameMode === GameMode.LIVE && !box.properties?.hasSalpalinja && [BoxStatus.DISABLED].includes(box.properties?.status) && <Button sx={{ my: 1 }} variant='contained' onClick={handleEnableBox}>Enable</Button> }
          { gameMode === GameMode.LIVE && selectedPlayer && BoxStatus.TARGET === box.properties?.status && box.properties?.playerIdWhoMadeTarget === selectedPlayer.id && <Button sx={{ my: 1 }} variant='contained' onClick={handleShot}>Shot</Button> }
          { gameMode === GameMode.LIVE && selectedPlayer && BoxStatus.FREE === box.properties?.status && <Button sx={{ my: 1 }} variant='contained' onClick={handleAdminSetTarget}>Set target</Button> }
          { adminBoxEditOn && (gameMode === GameMode.BUILD || gameMode === GameMode.LIVE) && BoxStatus.SHOT !== box.properties?.status && <Button sx={{ my: 1 }} variant='contained' onClick={() => handleAdminSetBoxType(BoxType.DOUBLE_RESOURCES)}>Set 2x treasure</Button> }
          { adminBoxEditOn && (gameMode === GameMode.BUILD || gameMode === GameMode.LIVE) && BoxStatus.SHOT !== box.properties?.status && <Button sx={{ my: 1 }} variant='contained' onClick={() => handleAdminSetBoxType(BoxType.MUST_DRINK)}>Set must drink "treasure"</Button> }
          { adminBoxEditOn && (gameMode === GameMode.BUILD || gameMode === GameMode.LIVE) && BoxStatus.SHOT !== box.properties?.status && <Button sx={{ my: 1 }} variant='contained' onClick={() => handleAdminSetBoxType(BoxType.SEND_MUST_DRINK)}>Set send drink treasure</Button> }
          { adminBoxEditOn && (gameMode === GameMode.BUILD || gameMode === GameMode.LIVE) && BoxStatus.SHOT !== box.properties?.status && <Button sx={{ my: 1 }} variant='contained' onClick={() => handleAdminSetBoxType(BoxType.DISABLED)}>Set disabled</Button> }
          { adminBoxEditOn && (gameMode === GameMode.BUILD || gameMode === GameMode.LIVE) && BoxStatus.SHOT !== box.properties?.status && <Button sx={{ my: 1 }} variant='contained' onClick={() => handleAdminSetBoxType(BoxType.NORMAL)}>Set normal</Button> }
        </>
        :
        <>
          { gameMode === GameMode.LIVE && [BoxStatus.FREE].includes(box.properties?.status) && <Button sx={{ my: 1 }} variant='contained' onClick={handleSetAsTarget}>Set as target</Button> }

          { gameMode === GameMode.BUILD && [BoxStatus.FREE].includes(box.properties?.status) && !box.properties?.hasSalpalinja && <Button sx={{ my: 1 }} variant='contained' onClick={handleBuildSalpalinja}>Build salpalinja</Button> }
          { gameMode === GameMode.BUILD && [BoxStatus.FREE].includes(box.properties?.status) && box.properties?.hasSalpalinja && <Button sx={{ my: 1 }} variant='contained' onClick={handleRemoveSalpalinja}>Remove salpalinja</Button> }
        </>
      }
    </Box>
  )
}

export default BoxActions
