import React, { Fragment, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import HealthBar from './HealthBar'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { GameEvent, GameEventType, Player } from '../../types'
import { Button, FormControl, FormControlLabel, List, ListItem, ListItemText, Switch, Typography } from '@mui/material'
import { useAuth } from '../../../components/Auth/AuthProvider'
import { axiosInstance } from '../../../utils/axiosInstance'
import { toast, toastHandlePromise } from '../../../utils/toast'
import { AxiosError } from 'axios'
import axiosErrorToToastString from '../../../utils/axiosErrorToToastString'
import { UserRole } from '../../../services/authService'
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import HealthBarPlayer from './HealthBarPlayer'

const ScoreBoard = () => {
  const [playerScoreListVisible, setPlayerScoreListVisible] = useState(true)
  const gameEvents = useSelector((state: RootState) => state.gameService.gameEvents)
  const players = useSelector((state: RootState) => state.gameService.players)
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const { userId, userRole } = useAuth()
  const [unUsedMustDrinkReceivedEvents, setUnUsedMustDrinkReceivedEvents] = useState<GameEvent[]>([])
  const gameId = useSelector((state: RootState) => state.gameService.gameId)

  useEffect(() => {
    const ownPlayer = players.find((player) => player.userId === userId)
    const mustDrinkReceivedEvents = gameEvents.filter((gameEvent) => gameEvent.playerId === ownPlayer?.id && gameEvent.type === GameEventType.SEND_MUST_DRINK_RECEIVED)
    const mustDrinkReceivedEventsUsedBoxIds = gameEvents.filter((gameEvent) => gameEvent.playerId === ownPlayer?.id && gameEvent.type === GameEventType.MUST_DRINK).map((gameEvent) => gameEvent.spBoxId)

    const mustDrinkReceivedEventsUnUsed = mustDrinkReceivedEvents.filter((gameEvent) => !mustDrinkReceivedEventsUsedBoxIds.includes(gameEvent.spBoxId))

    setUnUsedMustDrinkReceivedEvents(mustDrinkReceivedEventsUnUsed)
  }, [gameEvents])

  const getPlayerScore = (player: Player) => {
    const playersGameEvents = gameEvents.filter((gameEvent) => gameEvent.playerId === player.id)
    const shots = playersGameEvents.filter((gameEvent) => gameEvent.type === GameEventType.SHOT).length
    const misses = playersGameEvents.length - shots
    const doubleResourcesGameEventsCount = playersGameEvents.filter((gameEvent) => gameEvent.type === GameEventType.DOUBLE_RESOURCES).length
    const resources = Math.pow(2, doubleResourcesGameEventsCount)

    const mustDrink = gameEvents.filter((gameEvent) => gameEvent.targetPlayerId === player.id && gameEvent.type === GameEventType.MUST_DRINK)
    const hasDrunk = playersGameEvents.filter((gameEvent) => gameEvent.type === GameEventType.DRUNK).length

    const score = Math.floor(shots * 2 + misses / 2)

    return {
      shots,
      misses,
      score,
      player,
      resources,
      mustDrink: mustDrink.length,
      hasDrunk,
      mustDrinkEvents: mustDrink
    }
  }

  const getPlayerScores = () => {
    const allScores = players.map((player) => getPlayerScore(player)).sort((a, b) => {
      const scoreDiff = b.score - a.score
      if (scoreDiff !== 0) return scoreDiff
      else return b.misses - a.misses
    })
    return allScores
  }

  const handleSendDrink = async (targetPlayerId: number) => {
    try {
      if (unUsedMustDrinkReceivedEvents.length < 0) {
        toast.error('You do not have drink tickets left')
        return
      }

      const createMustDrinkEventPromise = axiosInstance.post('/api/game/create-must-drink-event', {
        gameId,
        spBoxId: unUsedMustDrinkReceivedEvents[0].spBoxId,
        targetPlayerId
      })
  
      await toastHandlePromise(createMustDrinkEventPromise, {
        pending: 'Sending drink ticket... Please wait',
        success: 'Drink ticket send!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to send drink ticket. ${axiosErrorMsg}`
            }
          }
        }
      })

      setUnUsedMustDrinkReceivedEvents(unUsedMustDrinkReceivedEvents.slice(1))
    } catch (e) {
      // Do nothing
    }
    return
  }

  const handleAdminMarkDrunk = async (playerId: number, boxId: number) => {
    try {
      const createDrunkEventPromise = axiosInstance.post('/api/game/create-drunk-event', {
        gameId,
        spBoxId: boxId,
        playerId
      })
  
      await toastHandlePromise(createDrunkEventPromise, {
        pending: 'Marking drink as drank... Please wait',
        success: 'Drink is drank!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to drunk. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
    }
    return
  }

  return (
    <Box>
      <HealthBar />

      <FormControl component="fieldset" variant="standard">
        <FormControlLabel
          control={
            <Switch checked={playerScoreListVisible} onChange={() => setPlayerScoreListVisible(!playerScoreListVisible)} />
          }
          label="Show scoreboard"
        />
      </FormControl>

      { playerScoreListVisible &&
        <Box>
          <List sx={{ display: 'inline-block' }}>
            <Typography variant='h5'>Scoreboard</Typography>
            { getPlayerScores().map(({ shots, misses, score, player, resources, hasDrunk, mustDrink, mustDrinkEvents }) => {
              const runnerName = runners.find((runner) => runner.id === player.runnerId)?.displayName
              const drunkTextColor = hasDrunk === mustDrink ? 'green' : 'red'
              return (
                <Box key={`${player.id}-player-score-list-item`} sx={{ mb: 1 }}>
                  <ListItem sx={{ m: 0, p: 0 }} >                    
                    <ListItemText
                      sx={{ backgroundColor: 'white', opacity: '90%' }}
                      primary={`${runnerName}: ${score} points, Resources: ${resources}`}
                      secondary={
                        <Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            { `Salpalinjas destroyed: ${shots}, Misses: ${misses},` }
                            <span style={{ color: drunkTextColor }}>{ ` Refreshments: ${hasDrunk}/${mustDrink}` }</span>
                          </Typography>
                          <br/>
                          {
                            player.userId !== userId && unUsedMustDrinkReceivedEvents.length > 0 &&
                              <Button startIcon={<LocalDrinkIcon />} onClick={() => handleSendDrink(player.id)}>Send drink</Button>
                          }
                          {
                            userRole === UserRole.ADMIN && hasDrunk !== mustDrink && mustDrinkEvents.length > 0 &&
                              <Button startIcon={<LocalDrinkIcon />} onClick={() => handleAdminMarkDrunk(player.id, mustDrinkEvents[0].spBoxId)}>Drunk</Button>
                          }
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <HealthBarPlayer playerId={player.id}/>
                </Box>
              )
            })}
          </List>
        </Box>
      }
    </Box>
  )
}

export default ScoreBoard
