import React, { useEffect, useRef, useState } from 'react';
import Map, { NavigationControl, MapRef, Popup } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { RunnerType } from '../utils/types';
import getMapAngle from '../utils/getMapAngle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { MapLayerMouseEvent, MapLayerTouchEvent, Style, LngLatBoundsLike } from 'mapbox-gl';
import findLastOccurrence from '../utils/findLastOccurrence';
import LiveUpdates from './LiveUpdates';
import { detectSprintCourse, getCourseGeoJson } from '../utils/courseUtils';
import { MapLoader } from './Loaders';
import { Button, Typography } from '@mui/material';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import getDeclination from '../utils/getDeclination';
import { setMassStart } from '../features/eventService/eventServiceSlice';
import { offsetRunnersForMassStart } from '../services/runnerService';
import { setCurrentTime } from '../features/timeSlider/timeSliderSlice';
import { useSideNavigation } from './SideNavigation/SideNavigationProvider';

// avoid loading RTL plugin if not necessary
// TODO: This should be called only once
//maplibregl.setRTLTextPlugin('https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.js', (e) => console.log(e), true)

const EventMap = () => {
  const dispatch = useDispatch()
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const courses = useSelector((state: RootState) => state.eventService.courses)
  const map = useSelector((state: RootState) => state.eventService.map)
  const selectedTime = useSelector((state: RootState) => state.timeSlider.currentTime)
  const tailLegth = useSelector((state: RootState) => state.timeSlider.tailLength)
  const showRoute = useSelector((state: RootState) => state.eventService.showRoute)
  const isLive = useSelector((state: RootState) => state.timeSlider.isLive)
  const visibleRunnerIds = useSelector((state: RootState) => state.eventService.visibleRunnersIds)
  const osmOn = useSelector((state: RootState) => state.eventService.osmOn)
  const panToRunnerId = useSelector((state: RootState) => state.eventService.panRunnerId)
  const visibleCourseIds = useSelector((state: RootState) => state.eventService.visibleCourseIds)
  const [isSprint, setIsSprint] = useState(false)
  const [courseGeoJsonData, setCourseGeoJsonData] = useState<Feature[]>([])
  const [runnersGeoJsonData, setRunnersGeoJsonData] = useState<Feature[]>([])
  const [popUpLngLat, setPopupLngLat] = useState<{ lng: number, lat: number } | null>(null)
  const [longTouchPressTimeout, setLongTouchPressTimeout] = useState<NodeJS.Timeout | null>(null)
  const isBetaAlertVisible = useSelector((state: RootState) => state.responsiveService.isBetaAlertVisible)

  const [loading, _setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (newLoading: boolean) => {
    loadingRef.current = newLoading
    _setLoading(newLoading)
  }

  // set view to location where some runner is based on gps data if map is not provided
  const getInitialBoundsBasedOnRunners = (): LngLatBoundsLike | undefined => {
    const runnerWithGpsOrGpxData = runners.find((runner) => {
      if (runner.type === RunnerType.GPS) {
        return runner.device.gpsRecords.length > 0
      } else {
        return runner.gpxRecords.length > 0
      }
    })
    if (runnerWithGpsOrGpxData) {
      const gpxRecords = runnerWithGpsOrGpxData.type === RunnerType.GPS ? runnerWithGpsOrGpxData.device.gpsRecords : runnerWithGpsOrGpxData.gpxRecords

      const turfLineString = lineString(gpxRecords.map((gpxRecord) => [gpxRecord.longitude, gpxRecord.latitude]))
      const boundsForGpxRecords = bbox(turfLineString)
      return [[boundsForGpxRecords[0], boundsForGpxRecords[1]], [boundsForGpxRecords[2], boundsForGpxRecords[3]]]
    }
    return undefined
  }

  const initialMapBearing = map?.mapFilePath ? getMapAngle(map.bounds)
    : map?.omapstoreMapUuid ? getDeclination(map.bounds)
      : 0

  const initialBounds: LngLatBoundsLike | undefined = map?.bounds ? [map.bounds[0], map.bounds[2]] : getInitialBoundsBasedOnRunners()


  const mapRef = useRef<MapRef | null>(null)
  //const [runnerCircleRadius, setRunnerCircleRadius] = useState(10)
  const isPortrait = useSelector((state: RootState) => state.responsiveService.isPortrait)

  const { toggled, collapsed } = useSideNavigation()

  const onMapLoad = () => {
    if (!mapRef.current) {
      return
    }
    if (map) {
      setLoadingRef(false)
      return
    }
  }

  useEffect(() => {
    if (mapRef) {
      if (isPortrait) {
        // ensures that map canvas is updated when the sidebar menu is full disappeared
        const timeout = setTimeout(() => {
          mapRef.current?.resize()
        }, 200)
        return () => {
          clearTimeout(timeout)
        }
      } else {
        // ensures that map canvas is updated when the sidebar menu is full disappeared
        const timeout = setTimeout(() => {
          mapRef.current?.resize()
        }, 300)

        return () => {
          clearTimeout(timeout)
        }
      }
    }
  }, [toggled, collapsed, isPortrait, isBetaAlertVisible])

  useEffect(() => {
    const courseSource = mapRef.current?.getSource('course')

    if (courseSource && courseSource.type === 'geojson') {
      const visibleCourses = courses.filter(({ id }) => visibleCourseIds.includes(id ?? -1))
      const someIsSprint = visibleCourses.some((visibleCourse) => detectSprintCourse(visibleCourse))

      setIsSprint(someIsSprint)

      const features = visibleCourses.map((course) => getCourseGeoJson(course, isSprint)).reduce<Feature<Geometry, GeoJsonProperties>[]>((allFeatures, { features }) => {
        return allFeatures.concat(features)
      }, [])

      /* courseSource.setData({
        type: 'FeatureCollection',
        features
      }) */
      setCourseGeoJsonData(features)
    }
  }, [visibleCourseIds, courses, isSprint])

  useEffect(() => {
    const trace = mapRef.current?.getSource('trace')

    if (trace) {
      const visibleData = runners.reduce((geoJsonData, runner) => {
        if (!runner.id || !visibleRunnerIds.includes(runner.id)) {
          return geoJsonData
        }
        const selectedTimeWithOffset = selectedTime - (runner.timeOffset ?? 0)
        const gpxRecords = runner.type === RunnerType.GPS ? runner.device.gpsRecords : runner.gpxRecords
        const gpsRecordIsPartOfTail = (timestamp: number) => {
          return showRoute || timestamp > selectedTimeWithOffset - tailLegth * 1000
        }
        const visibleRecords = gpxRecords.filter((gpxRecord) => gpxRecord.timestamp <= selectedTimeWithOffset && gpsRecordIsPartOfTail(gpxRecord.timestamp)).map((gpxRecord) => [gpxRecord.longitude, gpxRecord.latitude])
        if (visibleRecords.length > 0) {
          if (panToRunnerId === runner.id) {
            const lastKnownPosition = findLastOccurrence(gpxRecords, (gpxRecord) => gpxRecord.timestamp <= selectedTimeWithOffset)
            if (lastKnownPosition) {
              mapRef.current?.panTo({ lng: lastKnownPosition.longitude, lat: lastKnownPosition.latitude })
            }
          }

          const currentLonLat = visibleRecords[visibleRecords.length - 1]
          const opacity = 0.9

          const runnerTail: Feature = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: visibleRecords
            },
            properties: {
              runnerColor: runner.color,
              tailOpacity: opacity
            }
          }

          const runnerPositionCircle: Feature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: currentLonLat
            },
            properties: {
              displayname: runner.displayName,
              circleOpacity: opacity,
              runnerColor: runner.color
            }
          }

          geoJsonData.push(runnerTail)
          geoJsonData.push(runnerPositionCircle)
          return geoJsonData
        }

        const lastKnownPosition = findLastOccurrence(gpxRecords, (gpxRecord) => gpxRecord.timestamp <= selectedTime)

        if (!lastKnownPosition) {
          return geoJsonData
        }

        const opaqueRunnerPosition: Feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lastKnownPosition.longitude, lastKnownPosition.latitude]
          },
          properties: {
            displayname: runner.displayName,
            circleOpacity: 0.3,
          }
        }

        geoJsonData.push(opaqueRunnerPosition)
        return geoJsonData
      }, [] as Feature[])


      /* const visibleData = Object.values(runners).map((runner) => {
        const gpxRecords = runner.type === RunnerType.GPS ? runner.device.gpsRecords : runner.gpxRecords
        const visibleRecords = gpxRecords.filter((gpxRecord) => gpxRecord.timestamp > selectedTime - tailLegth * 1000 * 60 && gpxRecord.timestamp <= selectedTime).map((gpxRecord) => [gpxRecord.longitude, gpxRecord.latitude])
        if (visibleRecords.length > 0) {
          const currentLonLat = visibleRecords[visibleRecords.length - 1]

          return [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: visibleRecords
              },
              properties: {}
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: currentLonLat
              },
              properties: {
                displayname: runner.displayName,
                circleOpacity: 0.75,
              }
            }
          ] as Feature[]
        }
        
        const lastKnownPosition = findLastOccurrence(gpxRecords, (gpxRecord) => gpxRecord.timestamp <= selectedTime)

        if (!lastKnownPosition) {
          return []
        }

        return [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lastKnownPosition.longitude, lastKnownPosition.latitude]
            },
            properties: {
              displayname: runner.displayName,
              circleOpacity: 0.3,
            }
          }
        ] as Feature[]
      }).flat() */

      if (trace.type === 'geojson') {
        setRunnersGeoJsonData(visibleData)
        /* trace.setData({
          type: 'FeatureCollection',
          features: visibleData
        }) */
        // TODO: pan for selected user
        //mapRef.current?.panTo({ lng: lonLatOfCurrentLocation[0], lat: lonLatOfCurrentLocation[1]})
      }
    }
  }, [selectedTime, runners, tailLegth, visibleRunnerIds, panToRunnerId, showRoute])

  /* const handleModeChange = (newMode: EventMode) => {
    dispatch(setIsLive(newMode === EventMode.LIVE))
    setMode(newMode)
  } */

  const initialRouteData: FeatureCollection = {
    type: 'FeatureCollection',
    features: runnersGeoJsonData
  }

  const initialCourseData: FeatureCollection = {
    type: 'FeatureCollection',
    features: courseGeoJsonData
  }

  /* const updateRunnerCircleRadius = (zoom: number) => {
    console.log({ zoom })
    let newRadius = 10
    if (zoom > 14) {
      return 6
    }
    if (zoom > 12) {
      newRadius = 15
    }
    setRunnerCircleRadius(newRadius)
  } */

  const handleZoomChange = (newZoom: number) => {
    //updateRunnerCircleRadius(newZoom)
  }

  const getStyle = () => {
    const style: Style = {
      version: 8,
      glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
      sources: {
        trace: {
          type: 'geojson',
          data: initialRouteData
        },
        course: {
          type: 'geojson',
          data: initialCourseData
        }
      },
      layers: [
        {
          source: 'course',
          id: 'course-markings',
          type: 'line',
          paint: {
            'line-color': 'purple',
            'line-width': {
              "type": "exponential",
              "base": 2,
              "stops": [
                [0, (isSprint ? 1 : 3) * Math.pow(2, (0 - 14))], // scale based on zoom level
                [19, (isSprint ? 1 : 3) * Math.pow(2, (19 - 14))]
              ]
            }
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          }
        },
        {
          source: 'course',
          id: 'course-control-numbers',
          type: 'symbol',
          layout: {
            'text-field': ['get', 'controlNumber'],
            'text-anchor': ['get', 'textVariableAnchor'],
            'text-justify': 'auto',
            'text-radial-offset': 1,
            'text-size': {
              'type': 'exponential',
              'base': 2,
              'stops': [
                [0, (isSprint ? 12 : 30) * Math.pow(2, (0 - 14))], // scale based on zoom level
                [19, (isSprint ? 12 : 30) * Math.pow(2, (19 - 14))]
              ]
            },
            'text-allow-overlap': true
          },
          paint: {
            'text-color': 'purple'
          },
          filter: [
            'has', 'controlNumber'
          ]
        },
        {
          source: 'trace',
          id: 'trace-line',
          type: 'line',
          paint: {
            "line-color": ['get', 'runnerColor'],
            "line-opacity": ['get', 'tailOpacity'],
            'line-width': 5
          },
          filter: ['==', '$type', 'LineString']
        },
        {
          source: 'trace',
          id: 'trace-point',
          type: 'circle',
          paint: {
            "circle-color": ['get', 'runnerColor'],
            "circle-radius": 8,
            "circle-opacity": ['get', 'circleOpacity'],
            "circle-pitch-scale": 'viewport',
            "circle-stroke-color": 'black',
            "circle-stroke-opacity": ['get', 'circleOpacity'],
            "circle-stroke-width": 2
          },
          filter: ['==', '$type', 'Point']
        },
        {
          source: 'trace',
          id: 'trace-display-name',
          type: 'symbol',
          filter: ['==', '$type', 'Point'],
          layout: {
            'text-field': ['get', 'displayname'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-justify': 'auto',
            'text-radial-offset': 0.75,
            'text-size': 15,
            'icon-allow-overlap': true
          },
          paint: {
            'text-opacity': ['get', 'circleOpacity'],
            'text-halo-color': ['get', 'runnerColor'],
            'text-halo-width': 1
          }
        }
      ]
    }

    // add map layer only if map and mapFilePath exists
    if (map?.mapFilePath) {
      style.sources['mapImage'] = {
        type: 'image',
        url: `/api/event/map/${map.mapFilePath}`,
        coordinates: map.bounds
      }

      style.layers = [
        {
          id: 'map-image-layer',
          type: 'raster',
          paint: {
            "raster-fade-duration": 0
          },
          source: 'mapImage'
        },
        ...style.layers
      ]
    }

    const omapstoreHost = process.env.NODE_ENV === 'production' ? 'https://shop.omapstore.com' : 'http://127.0.0.1:8000'
    if (map?.omapstoreMapUuid) {
      style.sources['omapstore-map'] = {
        type: "raster",
        tiles: [`${omapstoreHost}/maptiles/${map.omapstoreMapUuid}/{z}/{x}/{y}.png`],
        tileSize: 512,
        attribution: "&copy; Omapstore",
        minzoom: 10,
        maxzoom: 16
      }

      style.layers = [
        {
          id: 'omapstore-layer',
          type: 'raster',
          source: 'omapstore-map'
        },
        ...style.layers
      ]
    }

    if (osmOn) {
      style.sources['osm'] = {
        type: "raster",
        tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution: "&copy; OpenStreetMap Contributors",
        maxzoom: 19
      }

      style.layers = [
        {
          id: 'osm-layer',
          type: 'raster',
          source: 'osm'
        },
        ...style.layers
      ]
    }

    return style
  }

  const PopupActions = () => {
    const handleMassStart = () => {
      if (!popUpLngLat) {
        return
      }
      setPopupLngLat(null)
      const { lng, lat } = popUpLngLat
      const { offsetRunners, time } = offsetRunnersForMassStart(runners, lng, lat)
      dispatch(setMassStart(offsetRunners))
      dispatch(setCurrentTime(time))
    }

    return (
      <Button size='small' onClick={handleMassStart}>Mass start here</Button>
    )
  }

  const handleMouseDown = (e: MapLayerMouseEvent) => {
    // right-click
    if (e.originalEvent.button === 2) {
      const { lng, lat } = e.lngLat
      setPopupLngLat({ lng, lat })
    }
  }

  const clearLongTouchPressTimeout = () => {
    if (longTouchPressTimeout) {
      clearTimeout(longTouchPressTimeout)
    }
    setLongTouchPressTimeout(null)
  }

  const handleOnTouchStart = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
    const timeout = setTimeout(() => {

      const { lng, lat } = e.lngLat
      setPopupLngLat({ lng, lat })
    }, 500)

    setLongTouchPressTimeout(timeout)
  }

  const handleOnTouchEnd = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
  }

  const handleOnTouchMove = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
  }

  const handleOnTouchCancel = (e: MapLayerTouchEvent) => {
    clearLongTouchPressTimeout()
  }

  return (
    <Map
      fadeDuration={0}
      onZoom={(viewStateChangeEvent) => handleZoomChange(viewStateChangeEvent.viewState.zoom)}
      ref={mapRef}
      id="event-map"
      //dragRotate={false}
      //pitchWithRotate={false}
      mapLib={maplibregl}
      mapStyle={getStyle()}
      style={{ maxWidth: '100%' }}
      bearing={initialMapBearing}
      bearingSnap={0}
      onLoad={onMapLoad}
      //touchPitch={false}
      onMouseDown={handleMouseDown}
      onTouchStart={handleOnTouchStart}
      onTouchMove={handleOnTouchMove}
      onTouchEnd={handleOnTouchEnd}
      onTouchCancel={handleOnTouchCancel}
      initialViewState={{ bounds: initialBounds }}
    //onContextMenu={handleMouseDown}
    >
      {!map && <Typography>Event has no map</Typography>} { /* TODO: this can be improved */}
      {map && <MapLoader visible={loadingRef.current} />}
      {isLive && <LiveUpdates />}
      <NavigationControl />
      {popUpLngLat &&
        <Popup longitude={popUpLngLat.lng} latitude={popUpLngLat.lat} onClose={() => setPopupLngLat(null)}>
          <PopupActions />
        </Popup>
      }

      {/* { osmOn && 
        <Fragment>
          <Source
            id='osm'
            type="raster"
            tiles={["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"]}
            tileSize={256}
            attribution="&copy; OpenStreetMap Contributors"
            maxzoom={19}
          >
            <Layer id="osm-layer" type='raster' source='osm'/>
          </Source>
        </Fragment>
      } */}
      {/* <Source
        id="map-image"
        type='image'
        url={`/api/event/map/${mapFileName}`}
        coordinates={bounds}
      >
        <Layer id='map-image-layer' type='raster' source='map-image' paint={{ "raster-fade-duration": 0 }}/>
      </Source>

      <Source
        id={'trace'}
        type='geojson'
        data={routeData}
      >
        <Layer id='trace-line' type='line' source='trace' paint={{ "line-color": 'red', "line-opacity": 0.75, 'line-width': 5 }} filter={['==', '$type', 'LineString']}/>
        <Layer id='trace-point' type='circle' source='trace' paint={{ "circle-color": 'red', "circle-radius": 8, "circle-opacity": 0.75, "circle-pitch-scale": 'viewport', "circle-stroke-color": 'black', "circle-stroke-opacity": 0.75, "circle-stroke-width": 2 }} filter={['==', '$type', 'Point']}/>
        <Layer
          id='trace-display-name'
          type='symbol'
          source='trace'
          filter={['==', '$type', 'Point']}
          layout={{ 
            'text-field': ['get', 'displayname'],
            'text-anchor': 'top',
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-justify': 'auto',
            'text-radial-offset': 0.5,
            'text-size': 20,
            //'text-font': ['Open Sans Bold']
          }}
        />
      </Source> */}
    </Map>
  )
}

export default EventMap
