import React, { useRef } from 'react'
import JSZip from 'jszip'

import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'

import { Bounds } from '../../utils/types'
import { toast } from '../../utils/toast'
import { polygon } from '@turf/helpers'
import transformRotate from '@turf/transform-rotate'

enum FileExtension {
  KMZ = 'kmz',
  JPG = 'jpg',
  JPEG = 'jpeg',
  GIF = 'gif',
  PNG = 'png'
}

const isFileExtension = (possibleFileExtension: string | undefined): possibleFileExtension is FileExtension => {
  return possibleFileExtension !== undefined && Object.values(FileExtension).includes(possibleFileExtension as FileExtension)
}

const ACCEPTED_FILE_EXTENSIONS = Object.values(FileExtension)

const ALLOWED_MAP_FILE_SIZE_IN_BYTES = 2000000

interface MapInputProps {
  setMapFile: (mapFile: File | null) => void,
  setMapBounds: (bounds: Bounds | null) => void,
  setGeoreferencingDoneManually: (flag: boolean) => void
}

const MapInput = ({ setMapFile, setMapBounds, setGeoreferencingDoneManually }: MapInputProps) => {
  const uploadMapInputFieldRef = useRef<HTMLInputElement>(null)

  const parseKmlFile = async (kmlFile: File): Promise<Bounds> => {
    const kmlXml = await kmlFile.text()
    const domParser = new window.DOMParser()
    const kmlDom = domParser.parseFromString(kmlXml, 'text/xml')

    const north = parseFloat(kmlDom.getElementsByTagName('north').item(0)?.textContent ?? '')
    const south = parseFloat(kmlDom.getElementsByTagName('south').item(0)?.textContent ?? '')
    const east = parseFloat(kmlDom.getElementsByTagName('east').item(0)?.textContent ?? '')
    const west = parseFloat(kmlDom.getElementsByTagName('west').item(0)?.textContent ?? '')
    
    const rotation = parseFloat(kmlDom.getElementsByTagName('rotation').item(0)?.textContent ?? '')

    const bounds: Bounds = [
      [west, north],
      [east, north],
      [east, south],
      [west, south]
    ]

    const boundsPolygon = polygon([
      bounds.concat([bounds[0]])
    ])

    const rotatedbounsPolygon = transformRotate(boundsPolygon, -rotation)
    const rotatedBounds = rotatedbounsPolygon.geometry.coordinates[0].slice(0, 4) as unknown as Bounds
  
    return rotatedBounds
  }

  const handleMapFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    const firstFile = files ? files[0] : null

    if (!firstFile) {
      toast.error('Failed to load map. Please try again.')
      return
    }

    const fileExtension = firstFile.name.split('.').reverse().shift()

    if (!isFileExtension(fileExtension)) {
      toast.error(`Unsupported map file. Map files with extensions are supported: ${ACCEPTED_FILE_EXTENSIONS.join()}`)
      return
    }

    if (firstFile.size > ALLOWED_MAP_FILE_SIZE_IN_BYTES) {
      toast.error(`Map file too large. Map file should be smaller than 2MB`)
      return
    }

    switch (fileExtension) {
      case FileExtension.GIF:
      case FileExtension.JPEG:
      case FileExtension.JPG:
      case FileExtension.PNG:
        setMapFile(firstFile)
        setMapBounds(null) // This resets bounds if map file is changed
        break
      case FileExtension.KMZ:
        try {
          const zip = await JSZip.loadAsync(firstFile)

          const files = zip.files

          const numberOfFilesInZip = Object.keys(files).length 

          if (numberOfFilesInZip !== 2) {
            toast.error('Unsupported kmz file. File should include only one image and kml file.')
            return
          }

          const mapAndKmlFiles = await Promise.all(Object.values(files).map(async (zipEntry) => {
            const nameOfKmzFile = firstFile.name.slice(0, -1 - fileExtension.length)
            const fileBlob = await zipEntry.async('blob')
  
            if (zipEntry.name.endsWith('.kml')) {
              const kmlFileName = `${nameOfKmzFile}.kml`
              return new File([fileBlob], kmlFileName)
            } else if (ACCEPTED_FILE_EXTENSIONS.some((extension) => zipEntry.name.endsWith(extension))) {
              const mapFileExtension = zipEntry.name.split('.').reverse().shift()
              const mapImageFileName = `${nameOfKmzFile}.${mapFileExtension}`
              return new File([fileBlob], mapImageFileName)
            }
            return null
          }))

          const mapAndKmlFilesNoNull = mapAndKmlFiles.filter((file): file is File => file !== null)

          if (mapAndKmlFilesNoNull.length !== 2) {
            toast.error('Kmz file does not contain right files. Map not loaded')
            return
          }

          const kmlFile = mapAndKmlFilesNoNull.find((file) => file.name.endsWith('kml'))
          const mapImageFile = mapAndKmlFilesNoNull.find((file) => !file.name.endsWith('kml'))

          if (!kmlFile || !mapImageFile) {
            toast.error('Failure on reading kmz file. Please try again.')
            return
          }

          const bounds = await parseKmlFile(kmlFile)

          setMapBounds(bounds)
          setMapFile(mapImageFile)
          setGeoreferencingDoneManually(false)
        } catch (e) {
          // TODO: handle error
          console.log(e)
          return
        }      
        break
    }
  }

  return (
    <FormControl
      fullWidth
      margin='normal'
    >
      <input
        ref={uploadMapInputFieldRef}
        type='file'
        accept={ACCEPTED_FILE_EXTENSIONS.map((extension) => `.${extension}`).join()}
        hidden
        onChange={handleMapFileUpload}
      />
      <Button
        onClick={() => uploadMapInputFieldRef.current && uploadMapInputFieldRef.current.click()}
        variant="contained"
      >
        Select map ({ ACCEPTED_FILE_EXTENSIONS.join()})
      </Button>
    </FormControl>
  )
}

export default MapInput
