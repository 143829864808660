import React from 'react'
import { MenuItem, SubMenu } from 'react-pro-sidebar'
import { Link, useLocation } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/People'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { blue } from '@mui/material/colors'

const AdminSubMenu = () => {
  const location = useLocation()

  const locationPath = location.pathname

  return (
    <SubMenu label="Admin" icon={ <AdminPanelSettingsIcon /> }>
      <MenuItem active={ locationPath === '/admin/users' } style={{ backgroundColor: blue[700]  }} component={<Link to={'/admin/users'} />} icon={ <PeopleIcon />}>Users</MenuItem>
    </SubMenu>
  )
}

export default AdminSubMenu
