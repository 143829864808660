import { Runner, RunnerType } from '../../../utils/types'
import { Feature } from 'geojson'
import findLastOccurrence from '../../../utils/findLastOccurrence'
import { MapRef } from 'react-map-gl'

const calcRunnerFeatures = (runners: Runner[], currentTimestamp: number, visibleRunnerIds: number[], tailInSeconds: number, showRoute: boolean, panToRunnerId: number | null, mapRef: React.MutableRefObject<MapRef | null>) => {

  const runnerFeatures = runners.reduce<Feature[]>((geoJsonData, runner) => {
    if (!runner.id || !visibleRunnerIds.includes(runner.id)) {
      return geoJsonData
    }

    const currentTimestampWithOffset = currentTimestamp - (runner.timeOffset ?? 0)
        const gpxRecords = runner.type === RunnerType.GPS ? runner.device.gpsRecords : runner.gpxRecords
        const gpsRecordIsPartOfTail = (timestamp: number) => {
          return showRoute || timestamp > currentTimestampWithOffset - tailInSeconds * 1000
        }
        const visibleRecords = gpxRecords.filter((gpxRecord) => gpxRecord.timestamp <= currentTimestampWithOffset && gpsRecordIsPartOfTail(gpxRecord.timestamp)).map((gpxRecord) => [gpxRecord.longitude, gpxRecord.latitude])
        if (visibleRecords.length > 0) {
          if (panToRunnerId === runner.id) {
            const lastKnownPosition = findLastOccurrence(gpxRecords, (gpxRecord) => gpxRecord.timestamp <= currentTimestampWithOffset)
            if (lastKnownPosition) {
              mapRef.current?.panTo({ lng: lastKnownPosition.longitude, lat: lastKnownPosition.latitude})
            }
          }
          
          const currentLonLat = visibleRecords[visibleRecords.length - 1]
          const opacity = 0.9

          const runnerTail: Feature = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: visibleRecords
            },
            properties: {
              runnerColor: runner.color,
              tailOpacity: opacity
            }
          }

          const runnerPositionCircle: Feature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: currentLonLat
            },
            properties: {
              displayname: runner.displayName,
              circleOpacity: opacity,
              runnerColor: runner.color
            }
          }

          geoJsonData.push(runnerTail)
          geoJsonData.push(runnerPositionCircle)
          return geoJsonData
        }
        
        const lastKnownPosition = findLastOccurrence(gpxRecords, (gpxRecord) => gpxRecord.timestamp <= currentTimestamp)

        if (!lastKnownPosition) {
          return geoJsonData
        }

        const opaqueRunnerPosition: Feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lastKnownPosition.longitude, lastKnownPosition.latitude]
          },
          properties: {
            displayname: runner.displayName,
            circleOpacity: 0.3,
          }
        }

        geoJsonData.push(opaqueRunnerPosition)
        return geoJsonData
  }, [])

  return runnerFeatures
}

export default calcRunnerFeatures
