import React from 'react';
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Circle from '@mui/icons-material/Circle'
import CircleOutlined from '@mui/icons-material/CircleOutlined'

import { RootState } from '../app/store'
import { setPanRunnerId, toggleRunnerIsVisible } from '../features/eventService/eventServiceSlice'

const RunnerList = () => {
  const dispatch = useDispatch()
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const visibleRunnersIds = useSelector((state: RootState) => state.eventService.visibleRunnersIds)

  const panRunnerId = useSelector((state: RootState) => state.eventService.panRunnerId)

  const getRunnerSelectList = () => {
    const runnerIsVisible = (runnerId: number | undefined) => runnerId ? visibleRunnersIds.includes(runnerId) : false

    const toggleRunnerVisible = (runnerId: number | undefined) => {
      if (runnerId) {
        dispatch(toggleRunnerIsVisible(runnerId))
      }
    }

    const setRunnerToPan = (checked: boolean, runnerId?: number) => {
      if (checked && runnerId) {
        dispatch(setPanRunnerId(runnerId))
      } else {
        dispatch(setPanRunnerId(null))
      }
    }

    return (
      <FormGroup>
          {
            runners.filter((runner) => !runner.courseId).map((runner) => {
              return (
                <Accordion key={`runner-list-select-${runner.id}`} disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <FormControlLabel control={<Checkbox icon={<CircleOutlined/>} checkedIcon={<Circle style={{ fill: runner.color }} />} />} checked={runnerIsVisible(runner.id)}  onChange={() => toggleRunnerVisible(runner.id)} onClick={(e) => e.stopPropagation()} label={runner.name} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel control={<Checkbox />} checked={panRunnerId === runner.id} onChange={(_event, checked) => setRunnerToPan(checked, runner.id)} label="Follow" />
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
      </FormGroup>
    )
  }

  return (
    <Box>
      { getRunnerSelectList() }
    </Box>
  )
}

export default RunnerList;
