
const findLastOccurrence = <T>(array: Array<T>, predicate: (value: T) => boolean): T | null => {
  let currentIndex = array.length
  while (currentIndex--) {
    const elem = array[currentIndex]
    if (predicate(elem)) {
      return elem
    }
  }
  return null
}

export default findLastOccurrence