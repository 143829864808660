import { configureStore } from '@reduxjs/toolkit';
import gpxServiceReducer from '../features/gpxService/gpxServiceSlice';
import timeSliderReducer from '../features/timeSlider/timeSliderSlice';
import eventServiceReducer from '../features/eventService/eventServiceSlice';
import responsiveReducer from '../features/responsiveService/responsiveSlice';
import gameServiceReducer from '../game/gameService/gameServiceSlice'

export const store = configureStore({
  reducer: {
    gpxService: gpxServiceReducer,
    timeSlider: timeSliderReducer,
    eventService: eventServiceReducer,
    responsiveService: responsiveReducer,
    gameService: gameServiceReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch