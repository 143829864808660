import { Feature } from 'geojson'
import { Style } from 'mapbox-gl'
import { Map } from '../../../utils/types'
import { BoxStatus } from './enums'
import { BoxType } from '../../types'


export const getStyle = (features: Feature[], gridPolygons: Feature[], selectedBox: Feature | null,showTreasures: boolean, map?: Map): Style => {
  const style: Style = {
    version: 8,
    glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
    sources: {
      trace: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features
        }
      },
      'map-grid': {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: gridPolygons
        }
      },
      'selected-box': {
        type: 'geojson',
        data: selectedBox ?? { type: 'FeatureCollection', features: []}
      },
      /* 'salpalinjas': {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: salpalinjas
        }
      } */
    },
    layers: [
      {
        source: 'trace',
        id: 'trace-line',
        type: 'line',
        paint: { 
          "line-color":  ['get', 'runnerColor'],
          "line-opacity": ['get', 'tailOpacity'],
          'line-width': 5
        },
        filter: ['==', '$type', 'LineString']
      },
      {
        source: 'trace',
        id: 'trace-point',
        type: 'circle',
        paint: {
          "circle-color": ['get', 'runnerColor'],
          "circle-radius": 8,
          "circle-opacity": ['get', 'circleOpacity'],
          "circle-pitch-scale":'viewport',
          "circle-stroke-color": 'black', 
          "circle-stroke-opacity": ['get', 'circleOpacity'],
          "circle-stroke-width": 2
        },
        filter: ['==', '$type', 'Point']
      },
      {
        source: 'trace',
        id: 'trace-display-name',
        type: 'symbol',
        filter: ['==', '$type', 'Point'],
        layout: { 
          'text-field': ['get', 'displayname'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-justify': 'auto',
          'text-radial-offset': 0.75,
          'text-size': 15,
          'icon-allow-overlap': true
        },
        paint: {
          'text-opacity': ['get', 'circleOpacity'],
          'text-halo-color': ['get', 'runnerColor'],
          'text-halo-width': 1
        }
      },
      {
        id: 'grid-line',
        type: 'line',
        paint: {
          'line-color': 'black',
          'line-width': 1
        },
        source: 'map-grid'
      },
      {
        id: 'grid-polygon',
        type: 'fill',
        paint: {
          'fill-color': [
            'match',
            ['get', 'status'],
            BoxStatus.FREE, 'transparent',
            BoxStatus.SHOT, 'red',
            BoxStatus.DISABLED, 'grey',
            BoxStatus.TARGET, ['case', ['has', 'target-color'], ['get', 'target-color'], 'blue'],
            BoxStatus.MISS, 'black',
            'grey'
          ],
          'fill-opacity': 0.5
        },
        source: 'map-grid',
      },
      {
        id: 'selected-box',
        type: 'fill',
        paint: {
          'fill-color': 'yellow',
          'fill-opacity': 0.8
        },
        source: 'selected-box',
      },
      {
        id: 'salpalinjas',
        type: 'symbol',
        source: 'map-grid',
        filter: ['==', true, ['get', 'hasSalpalinja']],
        layout: {
          'icon-image': 'helmet',
          'icon-size': {
            'type': 'exponential',
            'base': 2,
            'stops': [
              [0, 0.8 * Math.pow(2, (0 - 17))], // scale based on zoom level
              [19,  0.8 * Math.pow(2, (19 - 17))]
            ]
          },
          'icon-allow-overlap': true
        }
      },
    ]
  }

    style.layers = [
      {
        id: 'treasures',
        type: 'symbol',
        filter: [
          'case',
          showTreasures, true,
          ['==', true, [
          'match',
          ['get', 'status'],
          BoxStatus.SHOT, true,
          BoxStatus.MISS, true,
          false]
        ]],
        layout: {
          'text-field': [
            'match',
            ['get', 'type'],
            BoxType.DOUBLE_RESOURCES, '2x',
            BoxType.MUST_DRINK, 'MD',
            BoxType.SEND_MUST_DRINK, 'SMD',
            ''
          ],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-justify': 'auto',
          'text-radial-offset': 0,
          'text-size': {
            'type': 'exponential',
            'base': 2,
            'stops': [
              [0, Math.pow(2, (0 - 10))], // scale based on zoom level
              [19, Math.pow(2, (19 - 10))]
            ]
          },
          'icon-allow-overlap': true
        },
        paint: {
          "text-color": [
            'match',
            ['get', 'type'],
            BoxType.DOUBLE_RESOURCES, 'yellow',
            BoxType.MUST_DRINK, 'pink',
            BoxType.SEND_MUST_DRINK, 'purple',
            'black'
          ],
          'text-halo-color': 'black',
          'text-halo-width': 2
        },
        source: 'map-grid',
      },
      ...style.layers
    ]

  // add map layer only if map and mapFilePath exists
  if (map?.mapFilePath) {
    style.sources['mapImage'] = {
      type: 'image',
      url: `/api/event/map/${map.mapFilePath}`,
      coordinates: map.bounds
    }

    style.layers = [
      {
        id: 'map-image-layer',
        type: 'raster',
        paint: {
          "raster-fade-duration": 0
        },
        source: 'mapImage'
      },
      ...style.layers
    ]
  }

  const omapstoreHost = process.env.NODE_ENV === 'production' ? 'https://shop.omapstore.com' : 'http://127.0.0.1:8000'
  if (map?.omapstoreMapUuid) {
    style.sources['omapstore-map'] = {
      type: "raster",
      tiles: [`${omapstoreHost}/maptiles/${map.omapstoreMapUuid}/{z}/{x}/{y}.png`],
      tileSize: 512,
      attribution: "&copy; Omapstore",
      minzoom: 10,
      maxzoom: 16
    }

    style.layers = [
      {
        id: 'omapstore-layer',
        type: 'raster',
        source: 'omapstore-map'
      },
      ...style.layers
    ]
  }

/*   style.sources['osm'] = {
    type: "raster",
    tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
    tileSize: 256,
    attribution: "&copy; OpenStreetMap Contributors",
    maxzoom: 19
  }

  style.layers = [
    {
      id: 'osm-layer',
      type: 'raster',
      source: 'osm'
    },
    ...style.layers
  ] */

  return style
}
