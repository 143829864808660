import { isString } from '../utils/typeGuards'
import { axiosInstance } from '../utils/axiosInstance'

export interface EmailPassword {
  email: string,
  password: string
}

export enum UserRole {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
  ADMIN = 'ADMIN'
}

export interface TokenAndEmailAndRole {
  token: string,
  email: string,
  role: UserRole
}

export interface RegisterData extends EmailPassword {
  firstName: string,
  lastName: string,
}

export const isTokenExpired = (token: string) => {
  const { exp } = JSON.parse(window.atob(token.split('.')[1]))
  const expired = Math.floor((new Date()).getTime() / 1000) >= exp
  return expired
}

export const register = async (registerData: RegisterData) => {
  const registerResponse = await axiosInstance.post('/api/users', registerData)
  const user = registerResponse.data

  if (!user.email && !isString(user.email)) {
    return 'Registering failed'
  }
  return ''
}
