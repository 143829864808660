import React from "react";
import { MuiMarkdown } from "mui-markdown";
import Page from './Page';

// TODO: load markdown with web request instead.
const Instructions = () => {
  return (
    <Page>
      <MuiMarkdown>{`
### Otails
<br/>
Otails is a GPS data visualization tool.
<br/>
<br/>
#### Features
    
- Show GPS route on map
- Live GPS tracking using [Teltonika TMT250](https://teltonika-gps.com/products/trackers/assets-workforce/tmt250) GPS tracker
- Orienteering course on top of map as a layer
  - Show multiple courses in a same event
- GPX upload
- Hidden events
- Manage your TMT250 devices
- Join available events yourselves
  - No need to inform your GPS tracker information to event organizer
- Use background maps from [Omapstore](https://shop.omapstore.com/)
<br/>

#### Pricing
<br/>
Currently, we are developing the software, and planning the monetization of the business. However, our end goal is to provide the software as much as possible for free. On the other hand, running the software is not free and money is required to keep servers up. If you want to donate for the project, see contact information down under.
<br/>
<br/>
Should you be interested in using Otails (e.g., live gps tracking), please contact us, and we will tell more about the service and enable the premium features for you.
<br/>
<br/>
#### Configuring Teltonika TMT250 for Otails
<br/>
Teltonika TMT250 is currently the only supported GPS device for live tracking in Otails. You can configure your TMT250 device using the <a href="/otails-tmt250.cfg" title="Download TMT250 Otails configuration file" download>configuration file</a>. Use [Teltonika configuration](https://wiki.teltonika-gps.com/view/Teltonika_Configurator).
<br/>
<br/>
Wait, I participate to different orienteering trainings where other live GPS tracking services such as tulospalvelu.fi are used and I do not want to configure my device every time again. No worries, we have solved it. The configuration we provide, will work also with tulospalvelu.fi. When you want to use that service instead of Otails, navigate to [My devices](/my-devices) and edit your device and disable your device in Otails. When you want to use Otails again, enable your device. Thus, no Teltonika configuration needed after the initial configuration.
<br/>
<br/>
#### Contact
<br/>
Do you want to start using Otails (e.g., for live GPS tracking)? You have an idea, how to improve the service? Please contact shop(at)omapstore(dot)com
    `}</MuiMarkdown>
    </Page>
  );
};

export default Instructions;
