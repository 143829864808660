import center from '@turf/center'
import { points } from '@turf/helpers'
// @ts-ignore no-types-found
import MagVar from 'magvar'

import { Bounds } from './types'

const getDeclination = (bounds: Bounds) => {
  const centr = center(points(bounds))
  
  const lng = centr.geometry.coordinates[0]
  const lat = centr.geometry.coordinates[1]
  const magVar = MagVar.get(lat, lng)

  return magVar
}

export default getDeclination
