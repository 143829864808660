import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'

export interface ResponsiveState {
  isDesktopOrLaptop: boolean,
  isBigScreen: boolean,
  isTabletOrMobile: boolean,
  isPortrait: boolean,
  isRetina: boolean,
  isBetaAlertVisible: boolean,
}

const initialState: ResponsiveState = {
  isDesktopOrLaptop: true,
  isBigScreen: false,
  isTabletOrMobile: false,
  isPortrait: false,
  isRetina: false,
  isBetaAlertVisible: false,
}

export const responsiveSlice = createSlice({
  name: 'responsive',
  initialState,
  reducers: {
    setIsDesktopOrLaptop: (state, { payload }: PayloadAction<boolean>) => {
      state.isDesktopOrLaptop = payload
    },
    setIsBigScreen: (state, { payload }: PayloadAction<boolean>) => {
      state.isBigScreen = payload
    },
    setIsTabletOrMobile: (state, { payload }: PayloadAction<boolean>) => {
      state.isTabletOrMobile = payload
    },
    setIsPortrait: (state, { payload }: PayloadAction<boolean>) => {
      state.isPortrait = payload
    },
    setIsRetina: (state, { payload }: PayloadAction<boolean>) => {
      state.isRetina = payload
    },
    setIsBetaAlertVisible: (state, { payload}: PayloadAction<boolean>) => {
      state.isBetaAlertVisible = payload
    }
  }
})

export const { setIsDesktopOrLaptop, setIsBigScreen, setIsTabletOrMobile, setIsPortrait, setIsRetina, setIsBetaAlertVisible } = responsiveSlice.actions

export default responsiveSlice.reducer

export const useIsMobile = () => useSelector((state: RootState) => state.responsiveService.isPortrait)
