import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { axiosInstance } from '../../../utils/axiosInstance';
import { Users } from '../types';

const UsersList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<Users>([])

  useEffect(() => {
    const fetchUsers = async () => {
      const { data: users } = await axiosInstance.get<Users>('/api/admin/users')
      setUsers(users)
      return users
    }

    fetchUsers()
      .catch((e) => {
        console.log(e)
        window.alert('Failed to fetch users!')
      })
  }, [])

  const renderEditButton = (params: GridRenderCellParams) => {
    return (
      <Button
        variant='contained'
        size='small'
        onClick={() => navigate(`/admin/edit-user/${params.row.id}`)}
      >
        Edit
      </Button>
    )
  }
  /*
    const renderEventNameLink = (params: GridRenderCellParams) => {
      return (
        <Link to={`/event/${params.row.uuid}`}>{params.value}</Link>
      )
    }
  
    const renderEventDate = (params: GridRenderCellParams) => {
      // This deafault now to YYYY-MM-DD format. Refactor when localization is added
      const date = params.row.eventDate.slice(0, 10)
      return (
        <Typography>
          {date}
        </Typography>
      )
    } */

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.7,
      editable: false,
    },
    {
      field: 'allowedNumberOfDevices',
      headerName: 'device limit',
      flex: 0.7,
      editable: false
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 0.6,
      sortable: false,
      renderCell: renderEditButton
    }
  ];

  const getRows = () => {
    return users.map((user) => {
      return {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        role: user.role,
        allowedNumberOfDevices: user.allowedNumberOfDevices
      }
    })
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        padding: 2,
        height: '100%',
        marginBottom: 8
      }}
      component={Paper} elevation={6}
    >
      <DataGrid
        rows={getRows()}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        columnVisibilityModel={{
          id: false
        }}
      />
    </Box>
  )
}

export default UsersList