import { featureCollection, point } from '@turf/helpers';
import { Runner, RunnerType } from '../utils/types';
import nearestPoint from '@turf/nearest-point';

export const offsetRunnersForMassStart = (runners: Runner[], lng: number, lat: number) => {
  const targetPoint = point([lng, lat])

  let base = 0
  const offsetRunners = runners.map((runner, i) => {
    const records = runner.type === RunnerType.GPS ? runner.device.gpsRecords : runner.gpxRecords
    const runnersRoute = featureCollection(records.map((record) => point([record.longitude, record.latitude], { timestamp: record.timestamp })))
    const runnerIsInTargetAt = nearestPoint(targetPoint, runnersRoute).properties.timestamp as number // TODO: improve typing

    if (i === 0) {
      base = runnerIsInTargetAt
      return {
        ...runner,
        timeOffset: 0
      }
    }
    
    return {
      ...runner,
      timeOffset: base - runnerIsInTargetAt
    }
  })

  return {
    offsetRunners: offsetRunners,
    time: base
  }
} 