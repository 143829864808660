import { toast as reactToastify, ToastPromiseParams /* ToastOptions as ReactToastifyToastOptions */ } from 'react-toastify'

interface ToastOptions {
  // Define used toast options here
}

export const toast = {
  info: (msg: string, toastOptions?: ToastOptions) => reactToastify.info(msg, toastOptions),
  error: (msg: string, toastOptions?: ToastOptions) => reactToastify.error(msg, toastOptions),
  warn: (msg: string, toastOptions?: ToastOptions) => reactToastify.warn(msg, toastOptions),
  success: (msg: string, toastOptions?: ToastOptions) => reactToastify.success(msg, toastOptions),
}

interface ToastHandlePromiseMsgs<TData> extends ToastPromiseParams<TData> {}

export const toastHandlePromise = <TData>(promise: Promise<any>, params: ToastHandlePromiseMsgs<TData>) => {
  return reactToastify.promise(
    promise,
    params
  )
}
