import React, { ReactElement } from 'react'
import { useAuth } from './AuthProvider'
import { useLocation, Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
  children: ReactElement
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { token, authReady } = useAuth()
  const location = useLocation()

  if (!token) {
    return <Navigate to='/signin' replace state={{ from: location }}/>
  }

  if (!authReady) {
    return null
  }

  return children
}

export default ProtectedRoute
