import { ReactElement } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'

interface PageProps {
  children: (ReactElement | null | "" | undefined | boolean)[] | ReactElement | null | "" | undefined | boolean
  title?: string
  sx?: SxProps
}

const Page = ({ children, title, sx }: PageProps) => {
  return (
    <Box component={Paper} sx={{ mb: 2, p: 2, ...sx }}>
      {
        title && (
          <Typography variant='h5' mb={2}>
            {title}
          </Typography>
        )
      }
      {children}
    </Box>
  )
}

export default Page
