import React, { useEffect, useState } from 'react'
import GameField from '../GameField'
import { useAuth } from '../../../components/Auth/AuthProvider'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../../../utils/axiosInstance'
import { OtailsEvent } from '../../../utils/types'
import { initEvent, toggleCourseRunnerDrawerOpenOpen } from '../../../features/eventService/eventServiceSlice'
import { initTimeSlider } from '../../../features/timeSlider/timeSliderSlice'
import { AxiosError, AxiosResponse } from 'axios'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import CourseRunnerDrawer from '../../../components/CourseRunnerDrawer'
import Button from '@mui/material/Button'
import EventSettings from '../../../components/EventSettings'
import TimeSlider from '../../../features/timeSlider/timeSlider'
import { BoxType, GameMode, GetGameData } from '../../types'
import { Feature, Polygon } from 'geojson'
import { BoxStatus } from '../GameField/enums'
import { setGameEvents, setGameId, setGameMode, setGridPolygons, setPlayers } from '../../gameService/gameServiceSlice'
import LiveGameEvents from '../LiveGameEvents'
import ScoreBoard from '../ScoreBoard'
import { UserRole } from '../../../services/authService'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { toastHandlePromise } from '../../../utils/toast'
import axiosErrorToToastString from '../../../utils/axiosErrorToToastString'

interface PasswordRequiredEventResponse {
  passwordRequired: boolean
}
interface FetchEventResponse extends AxiosResponse {
  data: OtailsEvent | PasswordRequiredEventResponse
}

const ShowGame = () => {
  const { authReady, userRole } = useAuth()
  const dispatch = useDispatch()
  const eventName = useSelector((state: RootState) => state.eventService.eventDetails?.name)
  const runners = useSelector((state: RootState) => state.eventService.runners)
  const { eventUuid } = useParams()
  const [eventPasswordProvided, setEventPasswordProvided] = useState('')
  const [passwordRequired, setPasswordRequired] = useState(false)
  const gameMode = useSelector((state: RootState) => state.gameService.gameMode)
  const gameId = useSelector((state: RootState) => state.gameService.gameId)
  const gameEvents = useSelector((state: RootState) => state.gameService.gameEvents)
  const currentTimestamp = useSelector((state: RootState) => state.timeSlider.currentTime)

  useEffect(() => {
    const fetchEvent = async () => {
      if (passwordRequired) {
        const res: FetchEventResponse = await axiosInstance.post(`/api/event/show-event/${eventUuid}`, {
          password: eventPasswordProvided
        })
        return res.data
      }

      const res: FetchEventResponse = await axiosInstance.get(`/api/event/show-event/${eventUuid}`)
      return res.data
    }

    if (eventUuid && authReady) {
      fetchEvent()
        .then((otailsEvent) => {
          if ('passwordRequired' in otailsEvent && otailsEvent.passwordRequired) {
            setPasswordRequired(true)
          } else {
            const fetchedEvent = otailsEvent as OtailsEvent
            dispatch(initEvent(fetchedEvent))
            dispatch(initTimeSlider(fetchedEvent.runners))
          }
        })
        .catch((e) => {
          // TODO: handle
        })
    }
  }, [authReady, dispatch, eventUuid, eventPasswordProvided, passwordRequired])

  useEffect(() => {
    const fetchGame = async () => {
      const gameApiPrefix = userRole === null
        ? '/api/game/get-game-free'
        : '/api/game/get-game'

      const { data } = await axiosInstance.get<GetGameData>(`${gameApiPrefix}/${eventUuid}`)
      return data
    }

    if (eventName) {
      fetchGame()
        .then((game) => {
          const newGridPolygons = game.boxes.map((box) => {
            const playerIdsWhoHaveSalpalinjaInThisBox = game.players
              .map((player) => {
                const boxId = player.salpalinjas.find(({ spBoxId }) => spBoxId === box.id)?.spBoxId
                if (boxId) {
                  return player.id
                }
                return null
              }).filter(_ => _ !== null)

            const polygonFeature: Feature<Polygon> = {
              id: box.id,
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: box.bounds.coordinates
              },
              properties: {
                id: box.id,
                status: box.spBoxType === BoxType.DISABLED ? BoxStatus.DISABLED : BoxStatus.FREE,
                hasSalpalinja: playerIdsWhoHaveSalpalinjaInThisBox.length > 0,
                playerIdsWhoHaveSalpalinjaInThisBox: playerIdsWhoHaveSalpalinjaInThisBox,
                type: box.spBoxType
              }
            }
            return polygonFeature
          })
          dispatch(setGridPolygons(newGridPolygons))
          dispatch(setGameMode(game.gameMode))
          dispatch(setGameId(game.gameId))
          dispatch(setPlayers(game.players))
          dispatch(setGameEvents({ gameEvents: game.gameEvents, runners }))
        })
        .catch((e) => {
        })
    }
  }, [eventName, dispatch, eventUuid, runners])

  useEffect(() => {
    if (gameMode === GameMode.REPLAY) {
      dispatch(setGameEvents({ gameEvents, runners, currentTime: currentTimestamp }))
    }
  }, [gameMode, currentTimestamp])

  const handleCourseRunnerDrawerToggle= () => {
    dispatch(toggleCourseRunnerDrawerOpenOpen())
  }

  if (passwordRequired && eventPasswordProvided.length <= 0) {
    const handlePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const data = new FormData(event.currentTarget);

      setEventPasswordProvided(data.get('eventPasswordInput')?.toString() ?? '')
    }

   return (
      <Box component="form" onSubmit={handlePasswordSubmit} sx={{ m: 2 }}>
        <TextField
          name="eventPasswordInput"
          fullWidth
          required
          id="eventPasswordInput"
          label="Give event password to see the event"
          autoFocus={true}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Show event
        </Button>
      </Box>
    )
  }

  const handleAdminSetGameMode = async (gameMode: GameMode) => {
    try {
      const setGameModePromise = axiosInstance.post('/api/game/set-game-mode', {
        gameId,
        gameMode
      })
  
      await toastHandlePromise(setGameModePromise, {
        pending: 'Setting game mode',
        success: 'Game mode set!',
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to set game mode. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      // do nothing
    }
  }

  if (!eventName) {
    return (
      <Box>
        Loading event
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%', maxWidth: '100%', overflow: 'hidden' }}>
      <CourseRunnerDrawer />
      <GameField>
        <>
          <Box sx={{ m: 2 }}>
            <ScoreBoard />
          </Box>
          <Button sx={{ position: 'absolute', top: '50%', right: -20, transform: 'rotate(270deg)', fontWeight: 'bold' }} onClick={handleCourseRunnerDrawerToggle}>
            Players
          </Button>
          <Box sx={{ position: 'absolute', bottom: 0, mb: 1, mx: 2, width: '100%', maxWidth: '100%' }} >
            { userRole === UserRole.ADMIN &&
              <FormControl>
                <InputLabel id="demo-simple-select-label">Game mode</InputLabel>
                <Select
                  labelId="gamemode-select-label"
                  id="gamemode-select"
                  value={gameMode}
                  label="Age"
                  onChange={({ target }) => handleAdminSetGameMode(target.value as GameMode)}
                >
                  { Object.values(GameMode).map((gameMode) => {
                    return (
                      <MenuItem key={`game-mode-select-${gameMode}`} value={gameMode}>{gameMode}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            }
            <EventSettings />
            <TimeSlider />
          </Box>
        </>
      </GameField>
      <LiveGameEvents />
    </Box>
  )
}

export default ShowGame
