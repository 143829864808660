import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { BoxType, GameEvent, GameEventType, GameMode, Player } from '../types'
import { Feature, Polygon } from 'geojson'
import { BoxStatus } from '../components/GameField/enums'
import { Runner } from '../../utils/types'

export interface GameServiceState {
  gameEvents: GameEvent[],
  gridPolygons: Feature<Polygon>[],
  gameMode: GameMode
  gameId: number | null,
  players: Player[]
}

const initialState: GameServiceState = {
  gameEvents: [],
  gridPolygons: [],
  gameMode: GameMode.REPLAY,
  gameId: null,
  players: []
}

// if currentTime is given, only gameEvents before that time are drawn
const setStateOfGridPolygons = (gridPolygons: Feature<Polygon>[], gameEvents: GameEvent[], players: Player[], runners: Runner[], currentTime?: number) => {
  return gridPolygons.map((polygon) => {
    // this assumes that gameEvents are in order based on timestamp (last event is first)
    const gameEventsForThisPolygon = gameEvents.filter(({ spBoxId, type, timestamp }) => spBoxId === polygon.id && [GameEventType.SET_TARGET, GameEventType.MISS, GameEventType.SHOT, GameEventType.DISABLE, GameEventType.ENABLE].includes(type))
    if (gameEventsForThisPolygon.length > 0) {
      const gameEventForThisPolygon = currentTime === undefined ? gameEventsForThisPolygon[0] : gameEventsForThisPolygon.find((gameEvent) => gameEvent.timestamp <= currentTime)
      
      const newPolygon = {
        ...polygon,
        properties: {
          ...polygon.properties,
        }
      }

      if (!gameEventForThisPolygon) {
        // this is for replay mode to hide all if it's not yet visible
        newPolygon.properties['status'] = BoxStatus.FREE
        return newPolygon
      }

      switch (gameEventForThisPolygon.type) {
        case GameEventType.SET_TARGET: {
          const player = players.find(({ id }) => id === gameEventForThisPolygon.playerId)
          const runnerColor = runners.find((runner) => runner.id === player?.runnerId)?.color
          newPolygon.properties['status'] = BoxStatus.TARGET
          newPolygon.properties['target-color'] = runnerColor ?? 'blue'
          newPolygon.properties['playerIdWhoMadeTarget'] = player?.id
          return newPolygon
        }
        case GameEventType.MISS: {
          newPolygon.properties['status'] = BoxStatus.MISS
          return newPolygon
        }
        case GameEventType.SHOT: {
          newPolygon.properties['status'] = BoxStatus.SHOT
          return newPolygon
        }
        case GameEventType.DISABLE: {
          newPolygon.properties['status'] = BoxStatus.DISABLED
          return newPolygon
        }
        case GameEventType.ENABLE: {
          newPolygon.properties['status'] = BoxStatus.FREE
          return newPolygon
        }
      }
      return newPolygon
    } else {
      return { ...polygon }
    }
  })
}

export const gameServiceSlice = createSlice({
  name: 'gameservice',
  initialState,
  reducers: {
    setGameEvents: (state, { payload }: PayloadAction<{ gameEvents: GameEvent[], runners: Runner[], currentTime?: number }>) => {
      state.gameEvents = payload.gameEvents
      state.gridPolygons = setStateOfGridPolygons(state.gridPolygons, payload.gameEvents, state.players, payload.runners, payload.currentTime)
    },
    appendGameEvents: (state, { payload }: PayloadAction<{ gameEvents: GameEvent[] | GameEvent, runners: Runner[] }>) => {
      const newGameEvents = Array.isArray(payload.gameEvents) ? payload.gameEvents : [payload.gameEvents]
      state.gameEvents = state.gameEvents.concat(newGameEvents)

      state.gridPolygons = setStateOfGridPolygons(state.gridPolygons, newGameEvents, state.players, payload.runners)
    },
    setGridPolygons: (state, { payload}: PayloadAction<Feature<Polygon>[]>) => {
      state.gridPolygons = payload
    },
    setGameMode: (state, { payload }: PayloadAction<GameMode>) => {
      state.gameMode = payload
    },
    setGameId: (state, { payload }: PayloadAction<number>) => {
      state.gameId = payload
    },
    setPlayers: (state, { payload }: PayloadAction<Player[]>) => {
      state.players = payload
    },
    setBoxType: (state, { payload }: PayloadAction<{ boxId: number, newBoxType: BoxType }> ) => {
      state.gridPolygons = state.gridPolygons.map((box) => {
        if (box.id === payload.boxId) {
          const newBox = {
            ...box,
            properties: {
              ...box.properties,
            }
          }
          newBox.properties['type'] = payload.newBoxType
          return newBox
        } else {
          return { ...box }
        }
      })
    }
  }
})

export const { setGameEvents, appendGameEvents, setGridPolygons, setGameMode, setGameId, setPlayers, setBoxType } = gameServiceSlice.actions

export default gameServiceSlice.reducer