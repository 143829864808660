import React, { useEffect, useState } from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

import { DataGrid, GridColDef, GridRenderCellParams, GridRowModel, GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid/'

import ExpandMore from '@mui/icons-material/ExpandMore'

import { Course, CreateOrEditEventRunner, DeviceGroup, DeviceGroupDevice } from '../../utils/types'
import { MenuItem, Select } from '@mui/material'

interface DeviceListProps {
  deviceGroup: DeviceGroup,
  runners: CreateOrEditEventRunner[],
  addRunner: (runner: CreateOrEditEventRunner) => void,
  removeRunner: (deviceId: number) => void,
  updateRunner: (runner: CreateOrEditEventRunner) => void
  courses: Course[]
}

type DeviceRow = DeviceGroupDevice & {
  course?: {
    id?: number,
    name: string
  }
}

const DeviceList = ({ deviceGroup, runners, addRunner, removeRunner, updateRunner, courses }: DeviceListProps) => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const [rows, setRows] = useState<DeviceRow[]>([])

  useEffect(() => {
    const newRows = deviceGroup.devices.map((device) => {
      const runner = runners.find((runner) => runner.deviceId === device.id)
      const row: DeviceRow = {
        id: device.id,
        deviceName: device.deviceName,
        displayName: device.displayName,
        fullName: device.fullName
      }
  
      if (runner) {
        row.displayName = runner.displayName
        row.fullName = runner.name
        row.course = runner.course
      }
  
      return row
    })

    setRows(newRows)
    setRowSelectionModel(runners.map((runner) => runner.deviceId))
  }, [runners, deviceGroup.devices])

  const handleOnRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    const selectedDeviceIds = rowSelectionModel

    const runnerUnselected = runners.find((runner) => !selectedDeviceIds.includes(runner.deviceId))
    if (runnerUnselected) {
      removeRunner(runnerUnselected.deviceId)
    } else {
      const newDeviceId = selectedDeviceIds.find((deviceId) => !runners.map((runner) => runner.deviceId).includes(Number(deviceId)))
      const device = rows.find((device) => device.id === newDeviceId)
      if (device) {
        const newRunner: CreateOrEditEventRunner = {
          deviceId: device.id,
          displayName: device.displayName,
          name: device.fullName,
          course: device.course
        }
        addRunner(newRunner)
      }
    
    }
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    const oldRunner = runners.find((runner) => runner.deviceId === newRow.id)

    if (oldRunner) {
      updateRunner({
        deviceId: newRow.id,
        displayName: newRow.displayName,
        name: newRow.fullName,
        course: newRow.course
      })
    }

    // This is now handled by useEffect above
    /* const newRows = rows.map((row) => {
      if (row.id === newRow.id) {
        return {
          ...row,
          ...newRow
        }
      } else {
        return row
      }
    })

    setRows(newRows) */
    return newRow
  }

  const columns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.2,
      editable: false
    },
    {
      field: 'deviceName',
      headerName: 'Device name',
      flex: 1,
      hideable: false,
      editable: false
    },
    {
      field: 'displayName',
      headerName: 'Display name',
      flex: 1,
      hideable: false,
      editable: true
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 1,
      hideable: false,
      editable: true
    }
  ];

  if (courses.length > 0) {
    const processCourseSelectUpdate = (row: GridRowModel, selectedCourseName: string) => {
      const newRow = row as DeviceRow

      if (selectedCourseName === 'NO COURSE') {
        newRow.course = undefined
      } else {
        const course = courses.find((course) => course.name === selectedCourseName)
        newRow.course = {
          name: selectedCourseName,
          id: course?.id
        }
      }
      
      processRowUpdate(newRow)
    }

    const courseNames = courses.map((course) => course.name).concat(['NO COURSE'])

    const renderCourseSelect = (params: GridRenderCellParams) => {
      return (
        <Select
          sx={{ width: '100%' }}
          id={`select-course-cell-${params.row.id}`}
          value={params.row.course?.name ?? 'NO COURSE'}
          onChange={(e) => processCourseSelectUpdate(params.row, e.target.value)}
        >
          { courseNames.map((availableCourseName) => {
            return <MenuItem key={`menuitem-course-select-${params.row.id}-${availableCourseName}`} value={availableCourseName}>{availableCourseName}</MenuItem>
          })}
          
        </Select>
      )
    }

    columns.push({
      field: 'course',
      headerName: 'Course',
      flex: 1,
      renderCell: renderCourseSelect
    })
  }

  return (
    <Accordion key={`device-group-${deviceGroup.id}-device-list`}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
      >
        <Typography>
          { deviceGroup.name }
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          onRowSelectionModelChange={handleOnRowSelectionModelChange}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
          columnVisibilityModel={{
            id: false
          }}
          processRowUpdate={processRowUpdate}
          rowSelectionModel={rowSelectionModel}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default DeviceList