import Slider from '@mui/material/Slider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setCurrentTime, setIsLive, setTickSpeed, tick } from './timeSliderSlice';
import Stack from '@mui/material/Stack';
import PlayArrow from '@mui/icons-material/PlayArrow';
import StopCircle from '@mui/icons-material/StopCircle';
import Button from '@mui/material/Button';
import { FormControl } from '@mui/material';

const TimeSlider = () => {
  const dispatch = useDispatch()
  const currentTime = useSelector((state: RootState) => state.timeSlider.currentTime)
  //const currentSpeed = useSelector((state: RootState) => state.timeSlider.tickSpeed)
  const minTime = useSelector((state: RootState) => state.timeSlider.minTime)
  const maxTime = useSelector((state: RootState) => state.timeSlider.maxTime)
  //const tailLength = useSelector((state: RootState) => state.timeSlider.tailLength)
  const [playInterval, setPlayInteval] = useState<NodeJS.Timer | null>(null)
  const isLive = useSelector((state: RootState) => state.timeSlider.isLive )
  const [isPlay, setIsPlay] = useState(false)

  useEffect(() => {
    handleStop()
    if (isLive) {
      const delay = 1000 * 30
      dispatch(setCurrentTime(Date.now() - delay))
      dispatch(setTickSpeed(1))
      const interval = play(1000)

      return () => {
        if (interval) {
          clearInterval(interval)
          setPlayInteval(null)
        }
        dispatch(setIsLive(false))
      }
    }
    
  }, [isLive])

  useEffect(() => {
    if (isPlay) {
      return () => {
        if (playInterval) {
          clearInterval(playInterval)
        }
      }
    }
  }, [isPlay])

  useEffect(() => {
    if (currentTime > maxTime) {
      handleStop()
    }
  }, [maxTime, currentTime])

  const handleTimeChange = (_event: Event, newTime: number | number[]) => {
    dispatch(setCurrentTime(newTime))
  }

  const play = (tickSpeed: number) => {
    if (!playInterval) {
      const interval = setInterval(() => {
        dispatch(tick(tickSpeed))
      }, tickSpeed)
      setPlayInteval(interval)
      return interval
    }
  }

  const stop = () => {
    if (playInterval) {
      clearInterval(playInterval)
      setPlayInteval(null)
    }
  }

  const handlePlay = () => {
    setIsPlay(true)
    play(100)
  }

  const handleStop = () => {
    setIsPlay(false)
    stop()
  }

  /* const handleSpeedChange = (event: SelectChangeEvent) => {
    const newTickSpeed = Number(event.target.value)
    dispatch(setTickSpeed(newTickSpeed))
  }

  const handleTailChange = (event: SelectChangeEvent) => {
    const newTailLength = Number(event.target.value)
    dispatch(setTailLength(newTailLength))
  } */

  return (
    <Stack spacing={2} direction="row" sx={{ ml: 1, mr: 1 }} alignItems="center" style={{ backgroundColor: 'white', maxWidth: '100%' }}>
      {/* <FormControl size='small'>
        <InputLabel id="time-slider-speed-select-label">Speed</InputLabel>
        <Select
          labelId='time-slider-speed-select-label'
          id="time-slider-speed-select"
          value={currentSpeed.toString()}
          label="Speed"
          onChange={handleSpeedChange}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </ FormControl>
      <FormControl size='small' >
        <Select
          id="tail-length-select"
          value={tailLength.toString()}
          label="Tail"
          onChange={handleTailChange}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={1000 * 60 * 60 * 3}>3h</MenuItem>
        </Select>
      </FormControl> */}
      {(new Date(currentTime)).toTimeString().split(' ')[0]}
      <FormControl sx={{ ml: 2 }} fullWidth>
        <Slider aria-label='Time' value={currentTime} step={1000} min={minTime} max={isLive ? currentTime : maxTime} onChange={handleTimeChange}></Slider>
      </FormControl>
      <FormControl style={{ marginRight: 10 }}>
      { isPlay 
        ? <Button variant='outlined' startIcon={<StopCircle/>} onClick={handleStop}>Stop</Button>
        : <Button variant='outlined' endIcon={<PlayArrow />} onClick={handlePlay}>Play</Button>
      }
      </FormControl>
    </Stack>
  )
}

export default TimeSlider