import { Feature, FeatureCollection } from 'geojson'
import { Control, ControlType, Course } from './types'
import circle from '@turf/circle'
import along from '@turf/along'
import { lineString, point, points } from '@turf/helpers'
import length from '@turf/length'
import nearestPointToLine from '@turf/nearest-point-to-line'
import distance from '@turf/distance'
import angle from '@turf/angle'

export const orderCourseControls = (controls: Control[], controlIdOrder: string[]) => {
  const controlsInOrder = controlIdOrder.map((controlId) => {
    const control = controls.find((control) => control.controlId === controlId)
    if (!control) {
      // TODO: this error should be handled when calling this
      throw new Error('Controls cannot be ordered')
    }
    return control
  })

  return controlsInOrder
}

// We try to detect whether the course is sprint course to avoid asking map scale from user
// this is needed to draw course markings better for different type of courses
export const detectSprintCourse = (course: Course) => {
  const lengths = [] as number[]
  for (let i = 0; i < course.controls.length - 2; i++) {
    const control = course.controls[i]
    const nextControl = course.controls[i + 1]
    const controlToNextControlLength = length(lineString([
      [control.longitude, control.latitude],
      [nextControl.longitude, nextControl.latitude]
    ]), { units: 'meters' })
    lengths.push(controlToNextControlLength)
  }

  const avgControlToControlLength = lengths.reduce((sum, length) => sum + length, 0) / lengths.length

  // TODO: this may require some adjustment especially for the short forest courses
  const isSprint = avgControlToControlLength < 200
  return isSprint
}

export const getCourseGeoJson = (course: Course, isSprintCourse = false) => {
  const getStartTriangle = (start: Control, radius: number, nextControl?: Control): Feature => {
    const center = [start.longitude, start.latitude]

    // this geometry is Polygon
    const { geometry } = circle(center, radius, {
      steps: 64,
      units: 'meters'
    }) 

    let startPoint = geometry.coordinates[0][0]

    if (nextControl) {
      geometry.coordinates = [geometry.coordinates[0].slice(0, -1)]
      const lineToNextControl = getControlLine(start, nextControl, radius, radius, true)
      if (lineToNextControl.geometry.type !== 'LineString') {
        throw new Error('Expected line string')
      }

      startPoint = lineToNextControl.geometry.coordinates[0]

      const line = lineString(lineToNextControl.geometry.coordinates)
      const startPoint2 = nearestPointToLine(points(geometry.coordinates[0]), line)

      let splitIndex = 0
      geometry.coordinates[0].find((c, i) => {
        const aa = distance(point(c), point(startPoint2.geometry.coordinates), { units: 'meters' })
        const fooBar = aa === 0 // TODO: this may break in some cases. Reconsider this when time

        if (fooBar) {
          splitIndex = i
        }

        return fooBar
      })

      const newLineCoordinatesEnd = geometry.coordinates[0].slice(0, splitIndex)
      const newLineCoordinatesStart = geometry.coordinates[0].slice(splitIndex, geometry.coordinates[0].length - 1)
      const newLine = newLineCoordinatesStart.concat(newLineCoordinatesEnd)
      newLine.push(newLine[0])

      geometry.coordinates = [newLine]
    }

    const turfLine = lineString(geometry.coordinates[0])
    const circleLength = length(turfLine, { units: 'meters' })
    const thirdOfLength = circleLength / 3

    const secondPoint = along(turfLine, thirdOfLength, { units: 'meters' })
    const thirdPoint = along(turfLine, 2 * thirdOfLength, { units: 'meters' })

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[
          startPoint,
          secondPoint.geometry.coordinates,
          thirdPoint.geometry.coordinates,
          startPoint
        ]]
      },
      properties: {}
    }
  }

  const getControlCircle = (control: Control, previousControl: Control | null, nextControl: Control | null, radius: number, controlNumber?: number): Feature => {
    const center = [control.longitude, control.latitude]

    const getTextVariableAnchor = () => {
      if (previousControl && nextControl) {

        const controlAngle = angle(
          [previousControl.longitude, previousControl.latitude],
          [control.longitude, control.latitude],
          [nextControl.longitude, nextControl.latitude],
          {
            mercator: true
          }
        )

        const prevLatSmaller = previousControl.latitude < control.latitude
        const nextLatSmaller = nextControl.latitude < control.latitude
        const prevLngSmaller = previousControl.longitude < control.longitude
        const nextLngSmaller = nextControl.longitude < control.longitude

        if (prevLatSmaller && nextLatSmaller && prevLngSmaller && nextLngSmaller) {
          return 'bottom-left'
        }

        if (prevLatSmaller && !nextLatSmaller && prevLngSmaller && nextLngSmaller) {
          return controlAngle > 90 ? 'top-left' : 'left'
        }

        if (prevLatSmaller && !nextLatSmaller && prevLngSmaller && !nextLngSmaller) {
          return 'bottom'
        }

        if (prevLatSmaller && !nextLatSmaller && !prevLngSmaller && nextLngSmaller) {
          return controlAngle < 180 ? 'top-right' : 'bottom-left'
        }

        if (prevLatSmaller && !nextLatSmaller && !prevLngSmaller && !nextLngSmaller) {
          return 'right'
        }

        if (prevLatSmaller && nextLatSmaller && !prevLngSmaller && nextLngSmaller) {
          return 'bottom'
        }

        if (prevLatSmaller && nextLatSmaller && !prevLngSmaller && !nextLngSmaller) {
          return 'bottom-right'
        }

        if (prevLatSmaller && nextLatSmaller && prevLngSmaller && !nextLngSmaller) {
          return 'bottom'
        }


        if (!prevLatSmaller && nextLatSmaller && prevLngSmaller && nextLngSmaller) {
          return 'bottom-left'
        }

        if (!prevLatSmaller && !nextLatSmaller && prevLngSmaller && nextLngSmaller) {
          return 'top-left'
        }

        if (!prevLatSmaller && !nextLatSmaller && prevLngSmaller && !nextLngSmaller) {
          return 'top'
        }

        if (!prevLatSmaller && !nextLatSmaller && !prevLngSmaller && nextLngSmaller) {
          return 'top'
        }

        if (!prevLatSmaller && !nextLatSmaller && !prevLngSmaller && !nextLngSmaller) {
          return 'top-right'
        }

        if (!prevLatSmaller && nextLatSmaller && !prevLngSmaller && nextLngSmaller) {
          return controlAngle > 180 ? 'bottom-right' : 'top-left'
        }

        if (!prevLatSmaller && nextLatSmaller && !prevLngSmaller && !nextLngSmaller) {
          return 'bottom-right'
        }

        if (!prevLatSmaller && nextLatSmaller && prevLngSmaller && !nextLngSmaller) {
          return controlAngle > 180 ? 'bottom-left' : 'top-right'
        }
        

      }

      return 'bottom-right'
    }

    // this geometry is Polygon
    const { geometry } = circle(center, radius, {
      steps: 64,
      units: 'meters'
    })

    const properties = {} as { [key: string]: any }

    if (controlNumber) {
      properties.controlNumber = controlNumber
      properties.textVariableAnchor = getTextVariableAnchor()
    }

    return {
      type: 'Feature',
      geometry,
      properties
    }
  }

  const getControlLine = (startControl: Control, endControl: Control, controlRadius: number, startRadius?: number, ignoreDoNotDraw = false): Feature => {
    const lineCoordinates = [
      [startControl.longitude, startControl.latitude],
      [endControl.longitude, endControl.latitude]
    ]

    const startPointOnControlCorner = along(lineString(lineCoordinates), startRadius ?? controlRadius, { units: 'meters' })
    const endPointOnControlCorner = along(lineString(lineCoordinates.reverse()), controlRadius, { units: 'meters' })

    const doNotDrawControlLine = length(lineString(lineCoordinates), { units: 'meters' }) < 2 * controlRadius

    const coordinates = doNotDrawControlLine && !ignoreDoNotDraw ? [] :
    [
      startPointOnControlCorner.geometry.coordinates,
      endPointOnControlCorner.geometry.coordinates
    ]

    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: {}
    }
  }


  const features: Feature[] = [] 
  
  // includes also start and finish (there can be many start and finish but these cases are not yet handled)
  const controls = orderCourseControls(course.controls, course.controlIdOrder)

  const controlRadius = isSprintCourse ? 15 : 35
  const startRadius = isSprintCourse ? 20 : 45
  controls.forEach((control, i) => {
    const getNextControl = (): Control | null => {
      if (i < controls.length - 1) {
        return controls[i + 1]
      }
      return null
    }

    const getPreviousControl = (): Control | null => {
      if (i > 0) {
        return controls[i - 1]
      }
      return null
    }

    const nextControl = getNextControl()
    const previousControl = getPreviousControl()

    switch (control.type) {
      case ControlType.START: {
        const start = getStartTriangle(control, startRadius, controls[i + 1])
        features.push(start)

        if (nextControl) {
          features.push(getControlLine(control, nextControl, startRadius, startRadius * 1.3))
        }
        break
      }
      case ControlType.CONTROL: {
        const controlFeature = getControlCircle(control, previousControl, nextControl, controlRadius, i)
        features.push(controlFeature)

        if (nextControl) {
          features.push(getControlLine(control, nextControl, controlRadius * 1.3))
        }
        break
      }
      case ControlType.FINISH:
        const finishOuterCircle = getControlCircle(control, previousControl, nextControl, controlRadius)
        const finishInnerCircle = getControlCircle(control, previousControl, nextControl, controlRadius * 0.70)
        features.push(finishOuterCircle)
        features.push(finishInnerCircle)
        break
    }
  })

  const courseGeoJson: FeatureCollection = {
    type: 'FeatureCollection',
    features
  }

  return courseGeoJson
}