import React, { useEffect, useState } from 'react'
import { User } from '../types'
import { axiosInstance } from '../../../utils/axiosInstance'
import { useParams } from 'react-router-dom'
import { Box, FormControl, Typography, RadioGroup, FormControlLabel, Radio, FormLabel, TextField, Button } from '@mui/material'
import { UserRole } from '../../../services/authService'
import { omit } from '../../../utils/objectUtil'
import { isNumber } from '@mui/x-data-grid/internals'


const EditUser = () => {
  const { userId } = useParams()
  const [user, setUser] = useState<User | null>(null)
  const [saving, setSaving] = useState(false)

  const setUserRole = (userRole: UserRole) => {
    if (user) {
      const newUser = { ...user, role: userRole }
      if (user.role === UserRole.FREE && userRole === UserRole.PREMIUM && user.allowedNumberOfDevices <= 0) {
        newUser.allowedNumberOfDevices = 1
      }
      setUser(newUser)
    }
  }

  const setUsersAllowedNumberOfDevices = (newAllowedNumberOfDevices: string) => {
    const newAllowedNumberOfDevicesNumber = Number(newAllowedNumberOfDevices)
    if (!isNumber(newAllowedNumberOfDevicesNumber)) {
      return
    }

    if (user) {
      setUser({ ...user, allowedNumberOfDevices: newAllowedNumberOfDevicesNumber })
    }
  }

  const saveUser = async () => {
    setSaving(true)
    await axiosInstance.post<User>('/api/admin/users/update', user)
    setSaving(false)
  }

  useEffect(() => {
    const fetchUser = async () => {
      const { data: userToEdit } = await axiosInstance.get<User>(`/api/admin/users/${userId}`)
      setUser(userToEdit)
      return userToEdit
    }

    fetchUser()
  }, [userId])

  if (saving) {
    return (
      <Typography>Saving user</Typography>
    )
  }

  if (!user) {
    return (
      <Typography>Loading user</Typography>
    )
  }

  return (
    <Box margin={2}>
      <Typography variant='h5'>Edit user: {`${user.firstName} ${user.lastName}`}</Typography>
      <Typography>Email: {user.email}</Typography>
      <FormControl style={{ marginTop: '2em' }}>
        <FormLabel>User role</FormLabel>
        <RadioGroup
          name='admin-edit-user-role-radio-group'
          value={user.role}
          onChange={({ target }) => setUserRole(target.value as UserRole)}
        >
          {Object.values(omit(UserRole, [UserRole.ADMIN])).map((userRole) => {
            return <FormControlLabel key={`admin-radio-user-role-${userRole}`} value={userRole} control={<Radio />} label={userRole} />
          })}
        </RadioGroup>
      </FormControl>
      <FormControl style={{ marginTop: '2em' }}>
        <FormLabel>Users max allowed number of devices</FormLabel>
        <TextField
          type='number'
          onChange={({ target }) => setUsersAllowedNumberOfDevices(target.value)}
          value={user.allowedNumberOfDevices}
        />
      </FormControl>
      <Button style={{ marginTop: '2em' }} variant='contained' onClick={saveUser}>
        Save
      </Button>
    </Box>
  )
}

export default EditUser