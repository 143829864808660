import axios from 'axios';

export const axiosInstance = axios.create()

export const setAuthorizationToken = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = token
}

export const removeAuthorizationToken = () => {
  delete axiosInstance.defaults.headers.common.Authorization
}