import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Runner, RunnerType } from '../../utils/types'
import getMinAndMaxTimesFromRunners from '../../utils/minAndMaxTimeFromGpsRecords'

export interface TimeSliderState {
  currentTime: number,
  minTime: number,
  tickSpeed: number,
  maxTime: number,
  tailLength: number,
  isLive: boolean
}

const initialState: TimeSliderState = {
  currentTime: Date.now(),
  tickSpeed: 1,
  minTime: Date.now() - 1000*60*60,
  maxTime: Date.now() + 1000*60*60,
  tailLength: 60,
  isLive: false
}

export const timeSliderSlice = createSlice({
  name: 'timeSlider',
  initialState,
  reducers: {
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload
    },
    tick: (state, action) => {
      const newTime = state.currentTime + state.tickSpeed * action.payload
      state.currentTime = newTime
      if (state.isLive) {
        state.maxTime = newTime + 1000 // + 1s
      }
    },
    setTickSpeed: (state, action) => {
      state.tickSpeed = action.payload
    },
    setMinTime: (state, action) => {
      state.minTime = action.payload
    },
    setMaxTime: (state, action) => {
      state.maxTime = action.payload
    },
    setTailLength: (state, action) => {
      state.tailLength = action.payload
    },
    setIsLive: (state, action) => {
      state.isLive = action.payload
    },
    initTimeSlider: (state, { payload }: PayloadAction<Runner[]>) => {
      const { minTime, maxTime } = getMinAndMaxTimesFromRunners(payload)

      state.currentTime = minTime
      state.minTime = minTime - 60 * 1000 // mintime is 1 minute before real min time

      if (maxTime === 0) {
        // no records found
        state.maxTime = minTime - 60 * 1000
      } else {
        state.maxTime = maxTime + 60 * 1000
      }
    },
    // currently used only when gps runner is added
    updateTimeSlider: (state, { payload }: PayloadAction<Runner | Runner[]>) => {
      if (Array.isArray(payload)) {
        // TODO: 
      } else {
        if (payload.type === RunnerType.GPX) {
          const { minTime, maxTime } = getMinAndMaxTimesFromRunners([payload])

          if (minTime < state.minTime) {
            state.minTime = minTime - 60 * 1000
          }
          if (maxTime > state.maxTime) {
            state.maxTime = maxTime + 60 * 1000
          }
        } else if (payload.type === RunnerType.GPS) {
          // TODO: 
        }
      }
    }
  }
})

export const { setCurrentTime, tick, setTickSpeed, setMinTime, setMaxTime, setTailLength, setIsLive, initTimeSlider, updateTimeSlider } = timeSliderSlice.actions

export default timeSliderSlice.reducer