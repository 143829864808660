import { useEffect } from 'react'
import { useAuth } from './AuthProvider'
import { useLocation } from 'react-router-dom'
import { isTokenExpired } from '../../services/authService'
import { toast } from 'react-toastify'

export const AuthVerify = () => {
  const location = useLocation()
  const { handleLogOut, token } = useAuth()

  useEffect(() => {
    if (token && isTokenExpired(token)) {
      toast.warn('Authentication expired. Please login again.')
      handleLogOut('/signin', location.pathname)
    }
  }, [location, handleLogOut, token])

  return null
}
