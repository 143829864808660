import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import EventMap from './EventMap'
import TimeSlider from '../features/timeSlider/timeSlider'
import { OtailsEvent } from '../utils/types'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { initEvent, toggleCourseRunnerDrawerOpenOpen } from '../features/eventService/eventServiceSlice'
import { initTimeSlider } from '../features/timeSlider/timeSliderSlice'
import { Button, TextField } from '@mui/material'
import EventSettings from './EventSettings'
import { axiosInstance } from '../utils/axiosInstance'
import CourseRunnerDrawer from './CourseRunnerDrawer'
import { useAuth } from './Auth/AuthProvider'

interface PasswordRequiredEventResponse {
  passwordRequired: boolean
}
interface FetchEventResponse extends AxiosResponse {
  data: OtailsEvent | PasswordRequiredEventResponse
}

const Event = () => {
  const { authReady } = useAuth()
  const dispatch = useDispatch()
  const { eventUuid } = useParams();
  const [eventPasswordProvided, setEventPasswordProvided] = useState('')
  const [passwordRequired, setPasswordRequired] = useState(false)
  
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchEvent = async () => {
      if (passwordRequired) {
        const res: FetchEventResponse = await axiosInstance.post(`/api/event/show-event/${eventUuid}`, {
          password: eventPasswordProvided
        })
        return res.data
      }

      const res: FetchEventResponse = await axiosInstance.get(`/api/event/show-event/${eventUuid}`)
      return res.data
    }

    if (eventUuid && authReady) {
      setLoading(true)
      fetchEvent()
        .then((otailsEvent) => {
          if ('passwordRequired' in otailsEvent && otailsEvent.passwordRequired) {
            setPasswordRequired(true)
          } else {
            const fetchedEvent = otailsEvent as OtailsEvent
            dispatch(initEvent(fetchedEvent))
            dispatch(initTimeSlider(fetchedEvent.runners))
          }
          setLoading(false)
        })
        .catch((e) => {
          // TODO: handle
          console.log(e)
        })
    }
  }, [eventUuid, eventPasswordProvided, authReady, dispatch, passwordRequired])

  const handleCourseRunnerDrawerToggle= () => {
    dispatch(toggleCourseRunnerDrawerOpenOpen())
  }

  if (passwordRequired && eventPasswordProvided.length <= 0) {
    const handlePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const data = new FormData(event.currentTarget);

      setEventPasswordProvided(data.get('eventPasswordInput')?.toString() ?? '')
    }

    return (
      <Box component="form" onSubmit={handlePasswordSubmit} sx={{ m: 2 }}>
        <TextField
          name="eventPasswordInput"
          fullWidth
          required
          id="eventPasswordInput"
          label="Give event password to see the event"
          autoFocus={true}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Show event
        </Button>
      </Box>
    )
  }

  if (loading) {
    return (
      <Box>
        Loading event
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%', maxWidth: '100%', overflow: 'hidden' }}>
      {/* { !isNaN(Number(eventId)) && <GpxService eventId={Number(eventId)} /> } */}
      <CourseRunnerDrawer />
      <Box sx={{ flex: '1 1 auto', maxWidth: '100%' }}>
        <EventMap />
        <Button sx={{ position: 'absolute', top: '50%', right: -60, transform: 'rotate(270deg)', fontWeight: 'bold' }} onClick={handleCourseRunnerDrawerToggle}>
          Courses & Runners
        </Button>
        <Box sx={{ position: 'absolute', top: '88%', ml: 2 }} >
          <EventSettings />
        </Box>
      </Box>
      <Box sx={{ flex: '0 1 auto', maxWidth: '100%', marginBottom: 1 }}>
        <TimeSlider />
      </Box>
    </Box>
  )
}

export default Event