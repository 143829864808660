import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'

import { RootState } from '../../../app/store'
import { BoxStatus } from '../GameField/enums'
import { Typography } from '@mui/material'

const HealthBar = () => {
  const [progress, setProgress] = useState(0)
  const [numberOfSalpalinjas, setNumberOfSalpalinjas] = useState(1)
  const gridPolygons = useSelector((state: RootState) => state.gameService.gridPolygons)

  useEffect(() => {
    const getHealthBarProgress = () => {
      const salpaLinjas = gridPolygons.filter((box) => box.properties?.hasSalpalinja)
      const totalNumberOfSalpalinjas = salpaLinjas.length

      if (totalNumberOfSalpalinjas !== numberOfSalpalinjas) {
        setNumberOfSalpalinjas(totalNumberOfSalpalinjas)
      }

      const destroyedSalpalinjas = salpaLinjas.filter((sp) => sp.properties?.status === BoxStatus.SHOT).length
      const divider = totalNumberOfSalpalinjas > 0 ? totalNumberOfSalpalinjas : 1
      return (destroyedSalpalinjas / divider) * 100
    }

    setProgress(getHealthBarProgress())
  }, [gridPolygons])

  // note that the progress bar is reversed
  const getColor = () => {
    if (progress > 66) return 'red'
    else if (progress > 33) return 'orange'
    return 'green'
  }

  return (
    <Box>
      <LinearProgress sx={{
        height: 10,
        backgroundColor: getColor(),
        '& .MuiLinearProgress-bar': { backgroundColor: 'gray' },
        transform: 'rotate(180deg)'
      }} variant='determinate' value={progress} />
      <Typography sx={{ position: 'relative' }}>{ Math.floor(Math.abs(progress - 100) / 100 * numberOfSalpalinjas) } / {numberOfSalpalinjas}</Typography>
    </Box>
  )
}

export default HealthBar
