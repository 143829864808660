import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Control, ControlType, Course, isControlType } from '../../utils/types'
import { orderCourseControls } from '../../utils/courseUtils'
import { ExpandMore, Delete } from '@mui/icons-material'

interface CoursesProps {
  courses: Course[],
  setCourses: (courses: Course[]) => void
}

interface ControlMap {
  [id: string]: {
    longitude: number
    latitude: number
  }
}

const parseIof3CoursesXml = (coursesXml: string): Course[] => {
  const domParser = new window.DOMParser()
  const coursesDom = domParser.parseFromString(coursesXml, 'text/xml')

  const courseData = coursesDom.getElementsByTagName('CourseData')[0]
  //const eventName = courseData.getElementsByTagName('Event')[0]?.getElementsByTagName('Name')[0]?.textContent

  const raceCourseData = courseData.getElementsByTagName('RaceCourseData')[0]

  const controlsMap = Array.from(raceCourseData.getElementsByTagName('Control'))
    .filter((possibleControlElement) => possibleControlElement.parentElement?.nodeName === 'RaceCourseData')
    .reduce((controlMap, controlElement) => {
      const id = controlElement.getElementsByTagName('Id')[0].textContent
      const position = controlElement.getElementsByTagName('Position')[0]
      const longitude = parseFloat(position.getAttribute('lng') ?? '')
      const latitude = parseFloat(position.getAttribute('lat') ?? '')

      if (!id || isNaN(longitude) || isNaN(latitude)) {
        throw new Error('Failed to parse control data from courses xml')
      }

      controlMap[id] = {
        longitude,
        latitude
      }

      return controlMap
    }, {} as ControlMap)

  const courses: Course[] = Array.from(raceCourseData.getElementsByTagName('Course'))
    .map((courseElement) => {
      const courseName = courseElement.getElementsByTagName('Name')[0].textContent
      const courseLengthInMeters = parseFloat(courseElement.getElementsByTagName('Length')[0].textContent ?? '')
      const courseClimb = parseFloat(courseElement.getElementsByTagName('Climb')[0].textContent ?? '')
      const courseControls = Array.from(courseElement.getElementsByTagName('CourseControl'))
        .map((courseControlElement) => {
          const type = courseControlElement.getAttribute('type')
          const controlId = courseControlElement.getElementsByTagName('Control')[0].textContent
          const legLength = parseFloat(courseControlElement.getElementsByTagName('LegLength')?.[0]?.textContent ?? '0') // start controls has no legLength => 0

          if (!type || !isControlType(type) || !controlId || isNaN(legLength)) {
            throw new Error('Failed to parse course data from the courses xml')
          }

          return {
            type,
            controlId,
            legLength
          }
        })

      if (!courseName) {
        throw new Error('Course is missing a name. Cannot parse the courses xml.')
      }

      const controls = courseControls.map((courseControl) => {
        const control: Control = {
          type: courseControl.type,
          controlId: courseControl.controlId,
          latitude: controlsMap[courseControl.controlId].latitude,
          longitude: controlsMap[courseControl.controlId].longitude,
        }
        return control
      })

      const controlIdOrder = controls.map(({ controlId }) => controlId)

      const course: Course = {
        name: courseName,
        controls: controls,
        controlIdOrder: controlIdOrder,
        length: courseLengthInMeters,
        climb: courseClimb,
      }

      return course
    })

  return courses
}

const AddCoursesXml = ({ setCourses }: { setCourses: CoursesProps['setCourses'] }) => {
  const [inputDisabled, setInputDisabled] = useState(false)
  const [error, setError] = useState('')

  const handleCoursesXmlFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourses([])
    setError('')
    setInputDisabled(true)
    const files = event.target.files
    const xmlFile = files ? files[0] : null

    if (!xmlFile) {
      setInputDisabled(false)
      setError('Oops. Could not load the file. Please try again.')
      return
    }

    const coursesXmlString = await xmlFile.text()
    try {
      const courses = parseIof3CoursesXml(coursesXmlString)
      setCourses(courses)
    } catch (e) {
      setError('Courses XML file is invalid. Otails could not find any courses from it. Please check your XML file format and ensure its version is IOF 3. Potential reason is also that your XML file is missing geolocation, i.e. the controls do not include latitude and longitude position. Please check that your map is georeferenced in software where from you export your XML file.')
    } finally {
      setInputDisabled(false)
    }
  }

  return (
    <Box>
      {error.length > 0 && <Alert sx={{ marginBottom: 2 }} severity='error'><AlertTitle>Failed to load courses from file</AlertTitle><Typography>{error}</Typography></Alert>}
      <FormControl>
        <InputLabel htmlFor='courses-xml-file-upload' shrink>Courses IOF xml 3 -file</InputLabel>
        <Input id='courses-xml-file-upload' aria-describedby='courses-xml-file-upload-helper-text' type='file' onChange={handleCoursesXmlFileUpload} disableUnderline inputProps={{ accept: '.xml' }} disabled={inputDisabled} />
        <FormHelperText id='courses-xml-file-upload-helper-text'>Give courses xml file</FormHelperText>
      </FormControl>
    </Box>
  )
}

const CourseList = ({ courses, removeCourse }: { courses: CoursesProps['courses'], removeCourse: (courseName: string) => void }) => {
  if (courses.length <= 0) {
    return (
      <Typography>No courses</Typography>
    )
  }

  const courseAccordion = (course: Course) => {
    return (
      <Accordion key={`course-${course.name}-accordion`}>
        <Box display="flex">
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ flexGrow: 1 }}>
            {course.name}, {`${course.controls.filter((({ type }) => type === ControlType.CONTROL)).length} rastia`}, {`${course.length / 1000}km`}{course.climb > 0 && `, nousu: ${course.climb}m`}
          </AccordionSummary>
          <Box>
            <IconButton onClick={() => removeCourse(course.name)}>
              <Delete color='warning' />
            </IconButton>
          </Box>
        </Box>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Control</TableCell>
                  <TableCell>Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderCourseControls(course.controls, course.controlIdOrder).map(({ type, controlId }, index) => {
                  return (
                    <TableRow key={`${type}-control-${index}`}>
                      <TableCell>
                        {type === ControlType.CONTROL ? `${type} ${index}` : type}
                      </TableCell>
                      <TableCell>
                        {controlId}
                      </TableCell>
                    </TableRow>
                  )
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <Box>
      {courses.sort((a, b) => [a.name, b.name].sort()[0] === a.name ? -1 : 1).map(courseAccordion)}
    </Box>
  )
}

const Courses = ({ courses, setCourses }: CoursesProps) => {
  const removeCourse = (courseName: string) => {
    const newCourses = [...courses.filter(({ name }) => name !== courseName)]
    setCourses(newCourses)
  }

  // TODO: Here should be "update courses" function that should be passed to the AddCourseXml
  // It should be able to update courses without deleting old and creating totally new courses.
  // e.g., course name could be used for replace operation

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CourseList courses={courses} removeCourse={removeCourse} />
        </Grid>
        <Grid item xs={12}>
          <AddCoursesXml setCourses={setCourses} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Courses
