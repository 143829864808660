import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { AxiosResponse } from 'axios';
import { AdminDeviceGroup, DeviceGroupMembership, GetMyDeviceGroupsResponse, MemberDeviceGroup } from '../utils/types';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { axiosInstance } from '../utils/axiosInstance';
import Page from './Page';



interface AddNewMemberResponse extends AxiosResponse {
  data: DeviceGroupMembership
}

const MyDeviceGroups = () => {
  const [adminDeviceGroups, setAdminDeviceGroups] = useState<AdminDeviceGroup[]>([])
  const [memberDeviceGroups, setMemberDeviceGroups] = useState<MemberDeviceGroup[]>([])
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false)
  const [currentGroupId, setCurrentGroupId] = useState<number | null>(null)
  const [newMemberEmail, setNewMemberEmail] = useState('')

  const fetchMyDeviceGroups = async () => {
    return axiosInstance.get('/api/device-group/my-device-groups')
      .then((res: GetMyDeviceGroupsResponse) => {
        setAdminDeviceGroups(res.data.myAdminDeviceGroups)
        setMemberDeviceGroups(res.data.myMemberDeviceGroups)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    fetchMyDeviceGroups()
  }, [])

  const handleAddMemberDialogClose = () => {
    setAddMemberDialogOpen(false)
    setNewMemberEmail('')
    setCurrentGroupId(null)
  }

  const openAddMemberDialog = (groupId: number) => {
    setCurrentGroupId(groupId)
    setAddMemberDialogOpen(true)
  }

  const handleAddMember = () => {
    setAddMemberDialogOpen(false)

    if (!currentGroupId || !newMemberEmail) {
      // TODO: show error
      return
    }

    axiosInstance.post('api/device-group/add-member-to-device-group', {
      id: currentGroupId,
      email: newMemberEmail
    }).then((res: AddNewMemberResponse) => {
      const newAdminDeviceGroups = [...adminDeviceGroups].map((adminDeviceGroup) => {
        if (adminDeviceGroup.deviceGroup.id === currentGroupId) {
          adminDeviceGroup.deviceGroup.deviceGroupMemberships.push(res.data)
          return adminDeviceGroup
        } else {
          return adminDeviceGroup
        }
      })

      setAdminDeviceGroups(newAdminDeviceGroups)
      setNewMemberEmail('')
      setCurrentGroupId(null)
    }).catch((e) => {
      // TODO: handle errors
      console.log(e)
    })
  }

  const showAdminDeviceGroup = (adminDeviceGroup: AdminDeviceGroup) => {
    const groupName = adminDeviceGroup.deviceGroup.name
    const groupId = adminDeviceGroup.deviceGroup.id
    const groupMembers = adminDeviceGroup.deviceGroup.deviceGroupMemberships
    const idPrefix = `admin-device-group-${groupName}`
    return (
      <Grid item xs={12} key={idPrefix}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`${idPrefix}-content`}
            id={`${idPrefix}-header`}
          >
            <Typography>
              {groupName}
              <Button sx={{ marginLeft: 2 }} onClick={(e) => {
                e.stopPropagation()
                openAddMemberDialog(groupId)
              }}>
                Add member
              </Button>
            </Typography>

          </AccordionSummary>
          <AccordionDetails>
            <Typography component='h6'>Group members</Typography>
            <List dense>
              {groupMembers.map((member) =>
                <ListItem key={idPrefix + member.user.lastName + member.user.firstName}>
                  <ListItemText>
                    {member.role}: {member.user.lastName} {member.user.firstName}
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </AccordionDetails>
          <AccordionActions>
            <Button onClick={() => openAddMemberDialog(groupId)}>
              Add member
            </Button>
          </AccordionActions>
        </Accordion>
      </Grid>
    )
  }

  const showMemberDeviceGroup = (memberDeviceGroup: MemberDeviceGroup) => {
    const groupName = memberDeviceGroup.deviceGroup.name
    const groupMembers = memberDeviceGroup.deviceGroup.deviceGroupMemberships
    const idPrefix = `member-device-group-${groupName}`
    return (
      <Grid item xs={12} key={idPrefix}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`${idPrefix}-content`}
            id={`${idPrefix}-header`}
          >
            <Typography>
              {groupName}
            </Typography>

          </AccordionSummary>
          <AccordionDetails>
            <Typography component='h6'>Group members</Typography>
            <List dense>
              {groupMembers.map((member) =>
                <ListItem key={idPrefix + member.user.lastName + member.user.firstName}>
                  <ListItemText>
                    {member.role}: {member.user.lastName} {member.user.firstName}
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }

  return (
    <Page title="Device groups">

      {adminDeviceGroups.length > 0 &&
        <Fragment>
          <Typography component='h1' variant='h6'>
            My admin device groups
          </Typography>
          <Grid spacing={2} sx={{ marginTop: 1 }}>
            {adminDeviceGroups.map(showAdminDeviceGroup)}
          </Grid>
        </Fragment>
      }
      {memberDeviceGroups.length > 0 &&
        <Fragment>
          <Typography component='h1' variant='h6'>
            Device groups where I'm a member
          </Typography>
          <Grid spacing={2} sx={{ marginTop: 1 }}>
            {memberDeviceGroups.map(showMemberDeviceGroup)}
          </Grid>
        </Fragment>
      }

      <Dialog open={addMemberDialogOpen} onClose={handleAddMemberDialogClose}>
        <DialogTitle>Add new member to device group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a user to the device group by providing their email address. Note that there must be existing account with that email address.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="addMemberEmailAddress"
            label="Email Address of user"
            type="email"
            fullWidth
            variant="standard"
            onChange={({ target }) => setNewMemberEmail(target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddMemberDialogClose}>Cancel</Button>
          <Button onClick={handleAddMember}>Add member</Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default MyDeviceGroups;
