import React, { Fragment, useRef, useState } from 'react';
import Map, { NavigationControl, Source, Layer, Marker, MapRef, MarkerDragEvent } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Button } from '@mui/material';
import { Bounds } from '../../utils/types';

// avoid loading RTL plugin if not necessary
maplibregl.setRTLTextPlugin('https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.js', (e) => console.log(e), true)

enum Corner {
  TOPLEFT = 'topLeft',
  TOPRIGHT = 'topRight',
  BOTTOMRIGHT = 'bottomRight',
  BOTTOMLEFT = 'bottomLeft'
}

interface GeoreferenceMapImageProps {
  mapFile: File | null,
  georeferencingDone: (corners: Bounds) => void,
  cancel: () => void
}

const GeoreferenceMapImage = ({ mapFile, georeferencingDone, cancel }: GeoreferenceMapImageProps) =>  {
  const [mapImageUrl, setMapImageUrl] = useState('')
  const [opacity, setOpacity] = useState(0.6)
  const [topLeft, setTopLeft] = useState([0, 0])
  const [topRight, setTopRight] = useState([0, 0])
  const [bottomRight, setBottomRight] = useState([0, 0])
  const [bottomLeft, setBottomLeft] = useState([0, 0])
  const [initialCoordinates, setInitialCoordinate] = useState<number[][] | undefined>(undefined)

  const mapRef = useRef<MapRef | null>(null)

  const initializeImageCoordinatesAndLoadImage = async (mapImageFile: File) => {
    const mapImage = await new Promise<HTMLImageElement>((resolve, reject) => {
      const imageUrl = URL.createObjectURL(mapImageFile)
      const image = new Image()
      image.onload = () => {
        resolve(image)
      }
      image.onerror = () => reject(new Error('Couldnt load the map image'))
      image.src = imageUrl
    })

    const { width, height } = mapImage
    const mapImageAspectRatio = width / height

    const viewBounds = mapRef.current?.getBounds()
    if (!viewBounds) {
      // TODO: Handle this
      return
    }

    const viewWidth = Math.abs(viewBounds.getEast() - viewBounds.getWest())
    const viewHeight = Math.abs(viewBounds.getNorth() - viewBounds.getSouth())

    const topLeftCornerLon = viewBounds.getWest() + viewWidth * 0.3
    const topLeftCornerLat = viewBounds.getNorth() - viewHeight * 0.2

    let bottomRightCornerLon = 0
    let bottomRightCornerLat = 0

    if (width > height) {
      bottomRightCornerLon = viewBounds.getEast() - viewWidth * 0.3
      bottomRightCornerLat = topLeftCornerLat - Math.abs(bottomRightCornerLon - topLeftCornerLon) / 2 / mapImageAspectRatio

    } else {
      bottomRightCornerLat = viewBounds.getSouth() + viewHeight * 0.25
      bottomRightCornerLon = topLeftCornerLon + Math.abs(topLeftCornerLat - bottomRightCornerLat) * 2 * mapImageAspectRatio
    }

    const topLeft = [topLeftCornerLon, topLeftCornerLat]
    const topRight = [bottomRightCornerLon, topLeftCornerLat]
    const bottomRight = [bottomRightCornerLon, bottomRightCornerLat]
    const bottomLeft = [topLeftCornerLon, bottomRightCornerLat]

    setTopLeft(topLeft)
    setTopRight(topRight)
    setBottomRight(bottomRight)
    setBottomLeft(bottomLeft)

    setInitialCoordinate([topLeft, topRight, bottomRight, bottomLeft])
    setMapImageUrl(mapImage.src)
  }

  const handleGeoreferenceMarkerChange = (e: MarkerDragEvent, corner: Corner) => {
    const imageSource = mapRef.current?.getSource('map-image')
    if (imageSource?.type === 'image') {
      console.log({ imageSource })
      // @ts-ignore
      const [oldTopLeft, oldTopRight, oldBottomRight, oldBottomLeft] = imageSource.coordinates
      const newCoordinate = [e.lngLat.lng, e.lngLat.lat]
      if (corner === Corner.TOPLEFT) {
        imageSource.setCoordinates([newCoordinate, oldTopRight, oldBottomRight, oldBottomLeft])
        setTopLeft(newCoordinate)
      } else if (corner === Corner.TOPRIGHT) {
        imageSource.setCoordinates([oldTopLeft, newCoordinate, oldBottomRight, oldBottomLeft])
        setTopRight(newCoordinate)
      } else if (corner === Corner.BOTTOMRIGHT) {
        imageSource.setCoordinates([oldTopLeft, oldTopRight, newCoordinate, oldBottomLeft])
        setBottomRight(newCoordinate)
      } else if (corner === Corner.BOTTOMLEFT) {
        imageSource.setCoordinates([oldTopLeft, oldTopRight, oldBottomRight, newCoordinate])
        setBottomLeft(newCoordinate)
      }
    }
  }
  
  const initialViewState = {
    longitude: 15,
    latitude: 60,
    zoom: 4,
  }

  if (!mapFile) {
    return null
  }

  return (
    <Map ref={mapRef} id="ownmap" dragRotate={false} pitchWithRotate={false} initialViewState={initialViewState} /* mapStyle={mapStyle} */ mapLib={maplibregl}>
      <NavigationControl />
      <Source
        type="raster"
        tiles={["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"]}
        tileSize={256}
        attribution="&copy; OpenStreetMap Contributors"
        maxzoom={19}
      >
        <Layer id="osm-layer" type='raster' source='osm'/>
      </Source>
      { mapImageUrl && initialCoordinates &&
        <Fragment>
          <Source
            id="map-image"
            type='image'
            url={mapImageUrl}
            coordinates={
              initialCoordinates
            }
          >
            <Layer id='map-image-layer' type='raster' source='map-image' paint={{ "raster-fade-duration": 0, "raster-opacity": opacity }}/>
          </Source>
          <Marker draggable longitude={topLeft[0]} latitude={topLeft[1]} onDrag={(e) => handleGeoreferenceMarkerChange(e, Corner.TOPLEFT)} />
          <Marker draggable longitude={topRight[0]} latitude={topRight[1]} onDrag={(e) => handleGeoreferenceMarkerChange(e, Corner.TOPRIGHT)} />
          <Marker draggable longitude={bottomRight[0]} latitude={bottomRight[1]} onDrag={(e) => handleGeoreferenceMarkerChange(e, Corner.BOTTOMRIGHT)} />
          <Marker draggable longitude={bottomLeft[0]} latitude={bottomLeft[1]} onDrag={(e) => handleGeoreferenceMarkerChange(e, Corner.BOTTOMLEFT)} />
        </Fragment>
      }
      { initialCoordinates
        ? <Fragment>
            <Button onClick={() => opacity > 0 && setOpacity(opacity - 0.1)}>Opacity -</Button>
            <Button onClick={() => opacity < 1 && setOpacity(opacity + 0.1)}>Opacity +</Button>
            <Button onClick={() => georeferencingDone([topLeft, topRight, bottomRight, bottomLeft] as Bounds)}>Georeferencing done</Button>
          </Fragment>
        : <Button onClick={() => initializeImageCoordinatesAndLoadImage(mapFile)}>View ready</Button>
      }
    </Map>
  )
}

export default GeoreferenceMapImage

/* 

[22.52922534942627, 60.34907768522572],
            [22.633981704711918, 60.34117885619651],
            [22.61986255645752, 60.29621243416144],
            [22.515363693237305, 60.304186204287944],*/


/* 


const mapStyle: Style = {
    version: 8,
    sources: {
      osm: {
        type: "raster",
        tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution: "&copy; OpenStreetMap Contributors",
        maxzoom: 19
      },
      overlay: {
        type: 'image',
        url: mapString, //'/finnspringM21EB.jpg',
        coordinates: [
          [22.52922534942627, 60.34907768522572],
          [22.633981704711918, 60.34117885619651],
          [22.61986255645752, 60.29621243416144],
          [22.515363693237305, 60.304186204287944]
        ],
      }
    },
    layers: [
      {
        id: "osm",
        type: "raster",
        source: "osm"
      },
      {
        id: "overlay",
        type: "raster",
        source: "overlay",
        paint: {
          "raster-opacity": opacity
        }
      }
    ]
  }
*/