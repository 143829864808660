
import React, { useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { Bounds } from '../../../utils/types'
import { Feature, LineString, Properties, lineString } from '@turf/helpers'
import lineIntersect from '@turf/line-intersect'
import lineChunk from '@turf/line-chunk'
import { Button, FormControl, TextField } from '@mui/material'
import { Feature as GeoJsonFeature, Polygon } from 'geojson'
import MenuItem from '@mui/material/MenuItem'
import { BoxStatus } from './enums'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { axiosInstance } from '../../../utils/axiosInstance'

interface MapGridProps {
  mapBounds: Bounds
}

const MapGrid = ({ mapBounds }: MapGridProps) => {
  const eventId = useSelector((state: RootState) => state.eventService.eventDetails?.id)
  const [boxWidthInMeters, setBoxWidthInMeters] = useState(50)
  const [polygons, setPolygons] = useState<GeoJsonFeature<Polygon>[]>([])

  useEffect(() => {
    const calcGridPolygons = () => {
      const topLine = lineString([[mapBounds[0][0], mapBounds[0][1]], [mapBounds[1][0], mapBounds[1][1]]])
      const rigthLine = lineString([[mapBounds[1][0], mapBounds[1][1]], [mapBounds[2][0], mapBounds[2][1]]])
      const bottomLine = lineString([[mapBounds[3][0], mapBounds[3][1]], [mapBounds[2][0], mapBounds[2][1]]])
      const leftLine = lineString([ [mapBounds[0][0], mapBounds[0][1]], [mapBounds[3][0], mapBounds[3][1]]])
    
      const topPoints = lineChunk(topLine, boxWidthInMeters, { units: 'meters' }).features.map((line) => line.geometry.coordinates[0])
      const bottomPoints = lineChunk(bottomLine, boxWidthInMeters, { units: 'meters' }).features.map((line) => line.geometry.coordinates[0])
    
      const leftPoints = lineChunk(leftLine, boxWidthInMeters, { units: 'meters' }).features.map((line) => line.geometry.coordinates[0])
      const rightPoints = lineChunk(rigthLine, boxWidthInMeters, { units: 'meters' }).features.map((line) => line.geometry.coordinates[0])
    
    
      const verticalLines: Feature<LineString, Properties>[] = []
      for (let i = 0; i < topPoints.length; i++) {
        verticalLines.push(lineString([topPoints[i], bottomPoints[i]]))
      }
    
      const horizontalLines: Feature<LineString, Properties>[] = []
      for (let i = 0; i < leftPoints.length; i++) {
        horizontalLines.push(lineString([leftPoints[i], rightPoints[i]]))
      }
    
      const intersects = verticalLines.map((line) => lineIntersect(line, { type: 'FeatureCollection', features: horizontalLines }))
      intersects.forEach((horizontalPointLines) => horizontalPointLines.features.sort((a, b) => b.geometry.coordinates[1] - a.geometry.coordinates[1]))
    
      const geoJsonPolygons: GeoJsonFeature<Polygon>[] = []
      for (let y = 0; y < intersects.length - 1; y++) {
        for (let x = 0; x < intersects[y].features.length - 1; x++) {
          const topLeft = intersects[y].features[x].geometry.coordinates
          const topRight = intersects[y].features[x + 1].geometry.coordinates
          const bottomRight = intersects[y + 1].features[x + 1].geometry.coordinates
          const bottomLeft = intersects[y + 1].features[x].geometry.coordinates
    
          geoJsonPolygons.push({
            id: Number(`${y}${x}`),
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [[
                topLeft,
                topRight,
                bottomRight,
                bottomLeft,
                topLeft
              ]]
            },
            properties: {
              status: BoxStatus.FREE,
              id: Number(`${y}${x}`)
            }
          })
        }
      }
  
      setPolygons(geoJsonPolygons)
    }

    try {
      calcGridPolygons()
    } catch (e) {
      console.log(e)
    }
  }, [boxWidthInMeters, mapBounds])

  const handleCreateGrid = async () => {
    await axiosInstance.post('/api/game/create-game', {
      eventId,
      boxes: polygons.map((polygon) => polygon.geometry.coordinates[0])
    })
  }

  return (
    <>
      <Source
        id='create-map-grid'
        type='geojson'
        data={{
          type: 'FeatureCollection',
          features: polygons
        }}
      >
        <Layer
          id='create-grid-line'
          type='line'
          paint={{
            'line-color': 'purple',
            'line-width': 2
          }}
        />
        <Layer
          id='create-grid-polygon'
          type='fill'
          paint={{
            'fill-color': [
              'match',
              ['get', 'status'],
              BoxStatus.FREE, 'green',
              BoxStatus.SHOT, 'red',
              'black'
            ],
            'fill-opacity': 0.5
          }}
        />
      </Source>
      <FormControl sx={{ backgroundColor: 'white', mt: 2, width: 150 }}>
        <TextField
          
          label='Width of box in meters'
          type='number'
          value={boxWidthInMeters}
          select
          onChange={({ target }) => !isNaN(Number(target.value)) && setBoxWidthInMeters(Number(target.value)) }
        >
          {
            Array.from({ length: 10}, (_, i) => (i + 1) * 10).map((value) => (
              <MenuItem key={`grid-box-width-option-${value}`} value={value}>
                { value }
              </MenuItem>
            ))
          }
        </TextField>
        <Button onClick={handleCreateGrid}>
          Create grid
        </Button>
      </FormControl>
    </>
  )
}

export default MapGrid
