import React, { createContext, useContext, useEffect, useState } from 'react'
import { SidebarProps } from 'react-pro-sidebar'
import { useLocation } from 'react-router-dom'

interface SideNavigationContextData {
  broken: boolean
  setBroken: (broken: boolean) => void
  collapsed: SidebarProps['collapsed']
  setCollapsed: (collapsed?: boolean) => void
  toggled: SidebarProps['toggled']
  setToggled: (toggled?: boolean) => void
  toggleSideNav: () => void
}

type SideNavigationContextType = SideNavigationContextData | null

const SideNavigationContext = createContext<SideNavigationContextType>(null)

export const useSideNavigation = () => {
  const currentSideNavigationContext = useContext(SideNavigationContext)

  if (!currentSideNavigationContext) {
    throw new Error("useSideNavigation must be used within <SideNavigation.Provider>")
  }

  return currentSideNavigationContext
}

interface SideNavigationProviderProps {
  children: React.ReactNode
}

export const SideNavigationProvider = ({ children }: SideNavigationProviderProps) => {
  const location = useLocation()
  const [collapsed, setCollapsed] = useState<SideNavigationContextData['collapsed']>(false)
  const [toggled, setToggled] = useState<SideNavigationContextData['toggled']>(false)
  const [broken, setBroken] = useState(false)

  const toggleSideNav = () => {
    if (broken) {
      if (collapsed) {
        setCollapsed(false)
      }
      setToggled(!toggled)
    } else {
      setCollapsed(!collapsed)
    }
  }

  // Hide sidebar when navigating to new page and sidebar is visible
  useEffect(() => {
    if (broken && toggled) {
      toggleSideNav()
    }
  }, [location])

  const value = {
    broken,
    setBroken,
    collapsed,
    setCollapsed,
    toggled,
    setToggled,
    toggleSideNav
  }

  return (
    <SideNavigationContext.Provider value={value}>
      { children }
    </SideNavigationContext.Provider>
  )
}