import React, { ReactElement } from 'react'
import { useAuth } from './AuthProvider'
import { useLocation, Navigate } from 'react-router-dom'
import { UserRole } from '../../services/authService'

interface PremiumRouteProps {
  children: ReactElement
}

const PremiumRoute = ({ children }: PremiumRouteProps) => {
  const { token, userRole, authReady } = useAuth()
  const location = useLocation()

  if (!token) {
    return <Navigate to='/signin' replace state={{ from: location }}/>
  }

  if (userRole !== UserRole.PREMIUM && userRole !== UserRole.ADMIN) {
    return <Navigate to='/' />
  }

  if (!authReady) {
    return null
  }

  return children
}

export default PremiumRoute
