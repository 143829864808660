
export const clearAuthLocalStorage = () => {
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('userRole')
  window.localStorage.removeItem('email')
}

export const getAuthLocalStorage = () => {
  return {
    token: window.localStorage.getItem('token'),
    userRole: window.localStorage.getItem('userRole'),
    email: window.localStorage.getItem('email'),
    userId: Number(window.localStorage.getItem('userId'))
  }
}

export const getFromLocalStorage = (itemName: string) => {
  return window.localStorage.getItem(itemName)
}

export const setAuthLocalStorage = (token: string, email: string, userRole: string, userId: number) => {
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('email', email)
  window.localStorage.setItem('userRole', userRole)
  window.localStorage.setItem('userId', userId.toString())
}