import React, { Fragment } from 'react'
import { Course, CreateOrEditEventRunner, DeviceGroup } from '../../utils/types'
import DeviceList from './DeviceList'
import { Typography } from '@mui/material'

interface DeviceGroupsProps {
  deviceGroups: DeviceGroup[]
  runners: CreateOrEditEventRunner[],
  addRunner: (runner: CreateOrEditEventRunner) => void,
  removeRunner: (deviceId: number) => void,
  updateRunner: (runner: CreateOrEditEventRunner) => void,
  courses: Course[]
}

const DeviceGroups = ({ deviceGroups, runners, addRunner, removeRunner, updateRunner, courses }: DeviceGroupsProps) => {
  return (
    <Fragment>
      <Typography variant='h6'>
        Runners and devices
      </Typography>
      { deviceGroups.map((deviceGroup) => {
        const deviceIds = deviceGroup.devices.map(({ id }) => id)
        const runnersInDeviceGroup = runners.filter((runner) => deviceIds.includes(runner.deviceId))
        return (
          <DeviceList
            key={`device-list-for-${deviceGroup.id}`}
            deviceGroup={deviceGroup}
            runners={runnersInDeviceGroup}
            addRunner={addRunner}
            removeRunner={removeRunner}
            updateRunner={updateRunner}
            courses={courses}
          />
        )
      })}
    </Fragment>
  )
}

export default DeviceGroups


  /* 

  <Typography>
    Select devices for event. You see here all devices from device groups you have ADMIN access. You can also add an arbitrary device if you know the deviceName and runner name (fast if you need to do it once but if you think this device will participate more often you should probably add user to your group)
    Note that you can here change the display name by double clicking the display name cell. This change is made only for this event and does not affect the default display name of this device
  </Typography>
  
  */