import { Runner } from './types'

const stringToHexColor = (string: string) => {
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  
  const c = (hash & 0x00FFFFFF)
    .toString(16)
    .toUpperCase()

  const hexColor = '#' + ('00000'.substring(0, 6 - c.length) + c)
  return hexColor
}

export const getRunnerColor = (runner: Runner) => {
  return stringToHexColor(`${runner.name}${runner.displayName}${(runner.id ?? 1) * 1600 % 100}`)
}