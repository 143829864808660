import { AxiosError, AxiosResponse } from 'axios';

interface OtailsErrorResponse extends AxiosResponse {
  data: {
    error: string
  }
}

export interface OtailsAxiosError extends AxiosError {
  response?: OtailsErrorResponse
}

export enum DeviceGroupRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER'
}

export interface DeviceGroupMembership {
  role: DeviceGroupRole,
  user: {
    firstName: string,
    lastName: string
  }
}

export interface AdminDeviceGroup {
  deviceGroup: {
    name: string,
    id: number,
    deviceGroupMemberships: DeviceGroupMembership[]
  }
}

export interface MemberDeviceGroup {
  deviceGroup: {
    name: string,
    id: number,
    deviceGroupMemberships: DeviceGroupMembership[]
  }
}

export interface DeviceGroupData {
  myAdminDeviceGroups: AdminDeviceGroup[],
  myMemberDeviceGroups: MemberDeviceGroup[]
}

export interface GetMyDeviceGroupsResponse extends AxiosResponse {
  data: DeviceGroupData
}

export interface Device {
  id: number;
  imei: string;
  deviceName: string;
  displayName: string;
  deviceGroups: {
      deviceGroup: {
          name: string;
          id: number;
      };
  }[];
}

export interface GroupsAndDevices {
  groupsAndDevices: {
    deviceGroup: DeviceGroup
  }[]
}
export interface MyEvent {
  id: number,
  uuid: string,
  name: string,
  date: string
}

export interface CreateOrEditEventRunner {
  id?: number,
  deviceId: number,
  name: string,
  displayName: string,
  course?: {
    id?: number,
    name: string
  }
}

export interface EditEvent {
  name: string,
  date: Date,
  password?: string,
  eventVisibility: EventVisibility,
  eventVisibleForGroups: {
    deviceGroupId: number
  }[],
  eventType: EventType,
  runners: CreateOrEditEventRunner[],
  id: number,
  courses: Course[]
  eventActions: EventAction[]
}

export type Bounds = [[number, number], [number, number], [number, number], [number, number]]

export interface GpxRecord {
  timestamp: number,
  longitude: number,
  latitude: number,
}
export interface GpsRecord extends GpxRecord {
  deviceId: number,
}

interface RunnerBase {
  id?: number,
  name: string,
  displayName: string,
  eventId?: number,
  courseId?: number | null
  color?: string,
  timeOffset?: number
}

export enum RunnerType {
  GPS = 'GPS',
  GPX = 'GPX'
}
export interface GpsRunner extends RunnerBase {
  type: RunnerType.GPS,
  deviceId: number,
  device: {
    gpsRecords: GpsRecord[]
  },
}

export interface GpxRunner extends RunnerBase {
  type: RunnerType.GPX,
  gpxRecords: GpxRecord[]
}

export type Runner = GpsRunner | GpxRunner

export type Runners = {
  [runnerId: number]: Runner
}
export interface Map {
  bounds: Bounds,
  mapFilePath?: string,
  omapstoreMapUuid?: string
}

export interface EventDetails {
  id?: number,
  name: string,
  date: Date,
  eventType: EventType
}
export interface OtailsEvent extends EventDetails {
  map?: Map,
  runners: Runner[],
  courses?: Course[]
}

export enum EventType {
  LIVE = 'LIVE',
  GPX = 'GPX'
}

export enum EventActionValue {
  SET_LIVE_ON = 'SET_LIVE_ON',
  SET_LIVE_OFF = 'SET_LIVE_OFF'
}

export interface EventAction {
  action: EventActionValue,
  time: Date
}

export interface LiveData {
  id: number,
  runners: {
    id: number,
    deviceId: number,
    device: {
      gpsRecords: {
        latitude: number,
        longitude: number,
        timestamp: number
      }[],
    }
  }[]
}

export enum EventVisibility {
  PUBLIC = 'PUBLIC', // everyone with link can access and the link is listed in public list
  PUBLIC_HIDDEN = 'PUBLIC_HIDDEN', // everyone with link can access but the link is not listed in public view
  LINK_AND_PASSWORD = 'LINK_AND_PASSWORD', // everyone with a link and password can access
  PARTICIPANTS = 'PARTICIPANTS', // Everyone whose device is recording for the event can access
  GROUPS = 'GROUPS', // available for users who belongs to specific device groups
  PRIVATE = 'PRIVATE' // only creator of the event can see
}

export enum EventMode {
  LIVE = 'LIVE',
  REPLAY = 'REPLAY'
}

export enum ControlType {
  START = 'Start',
  CONTROL = 'Control',
  FINISH = 'Finish'
}

export const isControlType = (controlType: string): controlType is ControlType => {
 return Object.values(ControlType).includes(controlType as ControlType)
}

export interface Control {
  id?: number,
  controlId: string,
  latitude: number,
  longitude: number,
  type: ControlType,
}
export interface Course {
  id?: number,
  name: string,
  controls: Control[],
  controlIdOrder: Control['controlId'][],
  length: number,
  climb: number
}

export interface DeviceGroupDevice {
  id: number,
  deviceName: string,
  displayName: string,
  fullName: string
}

export interface DeviceGroup {
  id: number,
  name: string,
  devices: DeviceGroupDevice[]
}