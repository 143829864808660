import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { OtailsAxiosError } from '../utils/types'
import { FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, toastHandlePromise } from '../utils/toast'
import axiosErrorToToastString from '../utils/axiosErrorToToastString'
import { axiosInstance } from '../utils/axiosInstance'
import Page from './Page'

interface SelectedDeviceGroups {
  [id: number]: boolean
}

interface SelectDeviceGroup {
  id: number,
  name: string
}

interface GetSelectDeviceGroupsResponse extends AxiosResponse {
  data: {
    deviceGroup: SelectDeviceGroup
  }[]
}

interface GetDeviceResponse extends AxiosResponse {
  data: {
    id: number;
    imei: string;
    deviceName: string;
    displayName: string;
    enabled: boolean;
    deviceGroups: {
      deviceGroup: {
        name: string;
        id: number;
      };
    }[];
  } | null
}

/* interface GpsDeviceFormProps {
  deviceId?: number
} */

const GpsDeviceForm = (/* { deviceId }: GpsDeviceFormProps */) => {
  const navigate = useNavigate()
  const { deviceId } = useParams()
  const [myDeviceGroups, setMyDeviceGroups] = useState<SelectDeviceGroup[]>([])
  const [selectedDeviceGroups, setSelectedDeviceGroups] = useState<SelectedDeviceGroups>({})
  const [imei, setImei] = useState('')
  const [deviceName, setDeviceName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [deviceIsEnabled, setDeviceIsEnabled] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const setSelectDeviceGroups = (deviceGroups: SelectDeviceGroup[], selectedGroups: number[] = []) => {
    const selectedDeviceGroups: SelectedDeviceGroups = {}
    deviceGroups.forEach((deviceGroup) => {
      selectedDeviceGroups[deviceGroup.id] = selectedGroups.includes(deviceGroup.id)
    })

    setSelectedDeviceGroups(selectedDeviceGroups)
  }

  const handleResponseForNewDevice = (response: GetSelectDeviceGroupsResponse) => {
    const deviceGroups = response.data.map((group) => group.deviceGroup)
    setSelectDeviceGroups(deviceGroups)
    setMyDeviceGroups(deviceGroups)
  }

  const handleResponseForExistingGpsDevice = (responseForGroups: GetSelectDeviceGroupsResponse, responseForDevice: GetDeviceResponse) => {
    const deviceGroups = responseForGroups.data.map((group) => group.deviceGroup)
    const device = responseForDevice.data

    if (device) {
      setDeviceName(device.deviceName)
      setDisplayName(device.displayName)
      setImei(device.imei)
      setDeviceIsEnabled(device.enabled)

      const selectedGroups = device.deviceGroups.map((group) => group.deviceGroup.id)
      setSelectDeviceGroups(deviceGroups, selectedGroups)
    }

    setMyDeviceGroups(deviceGroups)
  }

  const fetchData = async () => {
    const endpoints = ['/api/device-group/select-groups']
    if (deviceId) {
      endpoints.push(`/api/device/${deviceId}`)
    }

    return axios.all(endpoints.map((endpoint) => axiosInstance.get(endpoint)))
      .then((res) => {
        return res
      })
      .catch((e) => {
        console.log(e)
        return null
      })
  }

  useEffect(() => {
    fetchData()
      .then((res) => {
        if (!res) {
          // TODO: show error
          return
        } else if (res.length === 1) {
          handleResponseForNewDevice(res[0])
        } else if (res.length === 2) {
          handleResponseForExistingGpsDevice(res[0], res[1])
        }
      })
  }, [])


  const handleDeviceGroupCheckBoxChange = (groupId: number, value: boolean) => {
    const newSelectedDeviceGroups = { ...selectedDeviceGroups }
    newSelectedDeviceGroups[groupId] = value
    setSelectedDeviceGroups(newSelectedDeviceGroups)
  }

  const handleSubmitCreateDevice = async () => {
    if (!imei || !deviceName || !displayName) {
      toast.warn('Please fill up all the required fields')
      return
    }

    /* axios.post('/api/device/create', {
      imei,
      deviceName,
      displayName,
      deviceGroupIds: Object.entries(selectedDeviceGroups).filter(([_, value]) => value).map(([key, _]) => Number(key))
    }, {
      headers: {
        'Authorization': token
      }
    }).then(({ data }) => console.log({ data }))
    .catch((e: OtailsAxiosError) => {
      console.log(e)
      if (e.response?.status === 422) {
        setError(e?.response?.data?.error ?? null)
      } else if (e.response?.status === 403) {
        setError('Authentication timed out')
      } else {
        setError('Error occurred! Try again')
      }
    }) */

    try {
      const createDevicePromise = axiosInstance.post('/api/device/create', {
        imei,
        deviceName,
        displayName,
        enabled: deviceIsEnabled,
        deviceGroupIds: Object.entries(selectedDeviceGroups).filter(([_, value]) => value).map(([key, _]) => Number(key))
      })

      await toastHandlePromise(createDevicePromise, {
        pending: 'Saving...',
        success: {
          render: () => {
            navigate('/my-devices')
            return 'New device added.'
          }
        },
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosToastMsg = axiosErrorToToastString(data)
              return `Failed to add a new device. ${axiosToastMsg}`
            }
            return 'Misspunched. Unexpected error occurred. Please refresh the page and try again. If problem persists please contact Otails.'
          }
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmitEditDevice = () => {
    if (!imei || !deviceName || !displayName || !deviceId) {
      // TODO: Show error
      return
    }

    axiosInstance.put('/api/device/edit', {
      id: deviceId,
      imei,
      deviceName,
      displayName,
      enabled: deviceIsEnabled,
      deviceGroupIds: Object.entries(selectedDeviceGroups).filter(([_, value]) => value).map(([key, _]) => Number(key))
    }).then((res) => {
      toast.success('Device updated!')
    }).catch((e: OtailsAxiosError) => {
      // TODO: proper error handling
      if (e.response?.status === 422) {
        setError(e?.response?.data?.error ?? null)
      } else if (e.response?.status === 403) {
        setError('Forbidden')
      } else {
        setError('Unkown error')
      }
    })
  }

  const title = deviceId ? 'Edit GPS device' : 'Add a new GPS device'

  return (
    <Page title={title}>
      {!deviceId && (
        <Typography>
          Currently only Teltonika TMT250 is supported
        </Typography>
      )}
      {error && <Typography color="red">
        {error}
      </Typography>}
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="imei"
              required
              fullWidth
              id="imei"
              label="Imei of gps device"
              autoFocus
              value={imei}
              onChange={({ target }) => setImei(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="deviceName"
              required
              fullWidth
              id="deviceName"
              label="Unique device name"
              value={deviceName}
              onChange={({ target }) => setDeviceName(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="displayName"
              required
              fullWidth
              id="displayeName"
              label="Default display name in map"
              value={displayName}
              onChange={({ target }) => setDisplayName(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={
              <Checkbox
                value={deviceIsEnabled}
                onChange={({ target }) => setDeviceIsEnabled(target.checked)}
              />
            }
              checked={deviceIsEnabled}
              label="Enable device for OTails. Uncheck if you want to use some other service."
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{ m: 3 }}
            >
              <FormLabel component="legend" sx={{ marginBottom: 2 }}>Add device to groups</FormLabel>
              <FormGroup>
                {myDeviceGroups.map((deviceGroup) =>
                  <FormControlLabel key={`device-group-${deviceGroup.id}`} control={
                    <Checkbox
                      checked={selectedDeviceGroups[deviceGroup.id]}
                      onChange={({ target }) => handleDeviceGroupCheckBoxChange(deviceGroup.id, target.checked)}
                    />
                  }
                    label={deviceGroup.name}
                  />
                )}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={deviceId ? handleSubmitEditDevice : handleSubmitCreateDevice}
        >
          {deviceId ? "Edit device" : "Add a new device"}
        </Button>
      </Box>
    </Page>
  )
}

export default GpsDeviceForm;
