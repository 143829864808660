import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'

import { RootState } from '../../../app/store'
import { Typography } from '@mui/material'
import { GameEventType, GameMode } from '../../types'

const TOTAL_NUMBER_OF_SALPALINJAS = 20

interface HealthBarPlayerProps {
  playerId: number
}

const HealthBarPlayer = ({ playerId }: HealthBarPlayerProps) => {
  const [progress, setProgress] = useState(0)
  const gameEvents = useSelector((state: RootState) => state.gameService.gameEvents)
  const gameMode = useSelector((state: RootState) => state.gameService.gameMode)
  const currentTime = useSelector((state: RootState) => state.timeSlider.currentTime)

  useEffect(() => {
    const getHealthBarProgress = () => {
      const playersSalpalinjasShot = gameEvents.filter((gameEvent) => gameEvent.type === GameEventType.SHOT && gameEvent.playerIdsWhoseSalpalinjaWasShot?.includes(playerId) && ((gameMode === GameMode.REPLAY && currentTime) ? gameEvent.timestamp <= currentTime : true)).length
      return (playersSalpalinjasShot / TOTAL_NUMBER_OF_SALPALINJAS) * 100
    }

    setProgress(getHealthBarProgress())
  }, [gameEvents, playerId, gameMode, currentTime])

  // note that the progress bar is reversed
  const getColor = () => {
    if (progress > 66) return 'red'
    else if (progress > 33) return 'orange'
    return 'green'
  }

  return (
    <Box>
      <LinearProgress sx={{
        height: 10,
        backgroundColor: getColor(),
        '& .MuiLinearProgress-bar': { backgroundColor: 'gray' },
        transform: 'rotate(180deg)'
      }} variant='determinate' value={progress} />
      <Typography sx={{ position: 'relative' }}>{ Math.floor(Math.abs(progress - 100) / 100 * TOTAL_NUMBER_OF_SALPALINJAS) } / {TOTAL_NUMBER_OF_SALPALINJAS}</Typography>
    </Box>
  )
}

export default HealthBarPlayer
