import React from 'react'
import Box from '@mui/material/Box'
import Copyright from './Copyright'
import { useLocation } from 'react-router-dom'

const Footer = () => {
  const location = useLocation()

  const hideFooter = location.pathname.startsWith('/event/')

  if (hideFooter) {
    return null
  }

  return (
    <Box sx={{ p: 1 }} >
      <Copyright/>
    </Box>
  )
}

export default Footer