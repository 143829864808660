import { Bounds } from './types'

const getMapAngle = (bounds: Bounds) => {
  const topLeft = bounds[0]
  const topRight = bounds[1]

  const third = [topRight[0], topLeft[1]]

  const hypLen = Math.sqrt(Math.pow(topLeft[1] - topRight[1] ,2) + Math.pow(topLeft[0] - topRight[0] ,2))
  const oppositeLegLen = Math.sqrt(Math.pow(third[1] - topRight[1] ,2) + Math.pow(third[0] - topRight[0] ,2))

  const angleEstimation = Math.asin(oppositeLegLen / hypLen) * 180 / Math.PI * 2
  return Number(angleEstimation.toFixed(2))
}

export default getMapAngle