import { AxiosError } from 'axios';
import { isObject, isString } from './typeGuards';

interface DataWithError {
  error: string
}

const resposeDataHasError = (data: unknown): data is DataWithError => {
  return isObject(data) && 'error' in data && isString(data.error)
}

const axiosErrorToToastString = (error: AxiosError) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    const defaultErrorMsg = 'Otails couldn´t handle the request. Please refresh the page and try again. If problem persists please contact Otails.'

    switch (error.response.status) {
      case 401:
        if (resposeDataHasError(error.response.data)) {
          if (error.response.data.error === 'token expired') {
            return 'Authentication timed out. Please login again.'
          } else if (error.response.data.error === 'Invalid email or password') {
            return 'Login failed'
          }
          return error.response.data.error
        }
        return defaultErrorMsg
      case 422: 
        if (resposeDataHasError(error.response.data)) {
          return error.response.data.error
        }
        return defaultErrorMsg
      case 402:
        if (resposeDataHasError(error.response.data)) {
          return error.response.data.error
        }
        return defaultErrorMsg
      case 409:
        if (resposeDataHasError(error.response.data)) {
          return error.response.data.error
        }
        return defaultErrorMsg
      default:
        if (resposeDataHasError(error.response.data)) {
          return error.response.data.error
        }
        return defaultErrorMsg
    }
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error)
    switch (error.code) {
      case 'ERR_NETWORK':
        return 'No Internet connection.'
      default:
        return 'Unknown error happened!'
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log(error.message)
    return `Couldn't perform request to Otails server.`
  }
}

export default axiosErrorToToastString;
