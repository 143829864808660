import React, { useEffect, useState } from 'react'
import { Bounds } from '../../utils/types'
import { axiosInstance } from '../../utils/axiosInstance'
import { AxiosError } from 'axios'
import { toast } from '../../utils/toast'
import axiosErrorToToastString from '../../utils/axiosErrorToToastString'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

interface SelectMapFromOmapstoreProps {
  setMapBounds: (bounds: Bounds | null) => void
  setOmapstoreMapUuid: (uuid: string | null) => void
  selectedOmapstoreMapUuid: null | string
}

interface MapInfo {
  name: string,
  uuid: string,
  bounds: number[]
}

const SelectMapFromOmapstore = ({ selectedOmapstoreMapUuid, setMapBounds, setOmapstoreMapUuid }: SelectMapFromOmapstoreProps) => {
  const [mapInfos, setMapInfos] = useState<MapInfo[]>([])
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSelectMap = (mapName: string) => {
    const mapInfo = mapInfos.find(({ name }) => name === mapName)
    if (!mapInfo) {
      handleClose()
      return
    }
    const bounds: Bounds = [
      [mapInfo.bounds[0], mapInfo.bounds[3]],
      [mapInfo.bounds[2], mapInfo.bounds[3]],
      [mapInfo.bounds[2], mapInfo.bounds[1]],
      [mapInfo.bounds[0], mapInfo.bounds[1]]
    ]

    setMapBounds(bounds)
    setOmapstoreMapUuid(mapInfo.uuid)
    handleClose()
  }

  const getMapByUuid = (mapUuid: string) => {
    const mapName = mapInfos.find(({ uuid }) => uuid === mapUuid)?.name
    return mapName
  }

  useEffect(() => {
    if (open && !loaded) {
      const fetchMapInfos = async () => {
        const res = await axiosInstance.get<MapInfo[][]>('/api/omapstore/map-infos')
        setMapInfos(res.data.flat())
      }

      fetchMapInfos()
        .catch((e) => {
          if (e instanceof AxiosError) {
            toast.error(axiosErrorToToastString(e))
          } else {
            toast.error('Failed to laod map infos from omapstore.')
          }
        })
        .finally(() => {
          setLoaded(true)
        })
    }
  }, [open, loaded])

  return (
    <Box>
      <Button variant='contained' onClick={handleClickOpen}>
        Select map from Omapstore
      </Button>
      {
        selectedOmapstoreMapUuid && <Typography>Map: { getMapByUuid(selectedOmapstoreMapUuid) ?? 'No map selected' }</Typography>
      }
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Available maps from Omapstore</DialogTitle>
        <DialogContent>
          {
            !loaded && <Typography>Loading available maps</Typography>
          }
          {
            loaded && mapInfos.length <= 0 && <Typography>No maps available from Omapstore</Typography>
          }
          {
            mapInfos.length >= 0 && mapInfos.map((mapInfo) => {
              return (
                <Box sx={{ marginBottom: 2 }} key={`omapstore-map-selection-${mapInfo.name}`}>
                  <Button variant='contained' onClick={() => handleSelectMap(mapInfo.name)}>{mapInfo.name}</Button>
                </Box>
              )
            })
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
    
  )
}

export default SelectMapFromOmapstore
