import React, { useEffect } from 'react';
import {
  Routes, Route,
  useLocation
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { Box, Container, CssBaseline } from '@mui/material'
import Header from './components/Header';
import SignInSide from './components/SignInSide';
import SignUp from './components/SignUp';
import Event from './components/Event';
import Home from './components/Home';
import { useDispatch } from 'react-redux';
import CreateEvent from './components/CreateEvent';
import GpsDeviceForm from './components/GpsDeviceForm';
import CreateDeviceGroupForm from './components/CreateDeviceGroupForm';
import MyDeviceGroups from './components/MyDeviceGroups';
import MyDevices from './components/MyDevices';
import SideNavigation from './components/SideNavigation';
import MyEvents from './components/MyEvents';
import { useMediaQuery } from 'react-responsive'
import { setIsBigScreen, setIsDesktopOrLaptop, setIsPortrait, setIsRetina, setIsTabletOrMobile } from './features/responsiveService/responsiveSlice';
import 'react-toastify/dist/ReactToastify.css';
import getAdminRoutes from './components/admin/getAdminRoutes';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import PremiumRoute from './components/Auth/PremiumRoute';
import JoinEvent from './components/JoinEvent';
import { AuthVerify } from './components/Auth/AuthVerify';
import IntegrationTokens from './components/IntegrationTokens';
import AddNewIntegrationToken from './components/IntegrationTokens/AddNewIntegrationToken';
import ShowGame from './game/components/ShowGame';
import Instructions from './components/Instructions';
import Footer from './components/Footer';

const App = () => {
  const dispatch = useDispatch()


  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  useEffect(() => {
    dispatch(setIsDesktopOrLaptop(isDesktopOrLaptop))
    dispatch(setIsBigScreen(isBigScreen))
    dispatch(setIsTabletOrMobile(isTabletOrMobile))
    dispatch(setIsPortrait(isPortrait))
    dispatch(setIsRetina(isRetina))
  }, [])

  return (
    <Box sx={{ minHeight: window.document.getElementsByTagName('html')[0]?.clientHeight ?? '100vh', display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      <Header />
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        <SideNavigation />
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flex: 1, display: 'flex' }}>
            <MainContent />
          </Box>
          <Footer/>
        </Box>
      </Box>

      <AuthVerify />
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  )
}

export default App;

const MainContent = () => {
  const location = useLocation()

  const disableMarginsAndPaddings = location.pathname.startsWith('/event/')

  return (
    <Container disableGutters={disableMarginsAndPaddings} sx={{ mt: disableMarginsAndPaddings ? 0 : 2, mb: disableMarginsAndPaddings ? 0 : 2 }}>
      <AppRoutes />
    </Container>
  )
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/signup' element={<SignUp />} />
      <Route path='/signin' element={<SignInSide />} />
      <Route path='/event/:eventUuid' element={<Event />} />

      {/* Protected routes */}
      <Route path='/create-event' element={<ProtectedRoute><CreateEvent /></ProtectedRoute>} />
      <Route path='/my-events' element={<ProtectedRoute><MyEvents /></ProtectedRoute>} />
      <Route path='/edit-event/:eventId' element={<ProtectedRoute><CreateEvent /></ProtectedRoute>} />
      <Route path='/instructions' element={<ProtectedRoute><Instructions /></ProtectedRoute>} />

      {/* Premium routes */}
      <Route path='/add-gps-device' element={<PremiumRoute><GpsDeviceForm /></PremiumRoute>} />
      <Route path='/edit-gps-device/:deviceId' element={<PremiumRoute><GpsDeviceForm /></PremiumRoute>} />
      <Route path='/create-device-group' element={<PremiumRoute><CreateDeviceGroupForm /></PremiumRoute>} />
      <Route path='/my-device-groups' element={<PremiumRoute><MyDeviceGroups /></PremiumRoute>} />
      <Route path='/my-devices' element={<PremiumRoute><MyDevices /></PremiumRoute>} />
      <Route path='/join-event/:eventUuid' element={<PremiumRoute><JoinEvent /></PremiumRoute>} />
      <Route path='/integration-tokens' element={<PremiumRoute><IntegrationTokens /></PremiumRoute>} />
      <Route path='/integration-tokens/add-new' element={<PremiumRoute><AddNewIntegrationToken /></PremiumRoute>} />

      {/* Admin routes */}
      {getAdminRoutes()}

      {/* Salpalinja pitää - game */}
      <Route path='/pitaakosalpalinjasi-game/:eventUuid' element={<PremiumRoute><ShowGame /></PremiumRoute>} />
      <Route path='/pitaakosalpalinjasi/:eventUuid' element={<ShowGame />} />

      <Route path='/' element={<Home />} />
    </Routes>
  )
}
