import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../app/store'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { toggleCourseRunnerDrawerOpenOpen } from '../features/eventService/eventServiceSlice'
import { Box, Divider, Typography } from '@mui/material'
import RunnerList from './RunnerList'
import CourseList from './CourseList'
import UploadGpxToEventDialog from './UploadGpxToEventDialog'
import { useAuth } from './Auth/AuthProvider'

const CourseRunnerDrawer = () => {
  const { userRole } = useAuth()
  const isPortrait = useSelector((state: RootState) => state.responsiveService.isPortrait)
  const dispatch = useDispatch()
  const courseRunnerDrawerOpen = useSelector((state: RootState) => state.eventService.courseRunnerDrawerOpen)

  const handleOpen = () => {
    dispatch(toggleCourseRunnerDrawerOpenOpen())
  }

  const handleClose = () => {
    dispatch(toggleCourseRunnerDrawerOpenOpen())
  }

  const uploadGpxButtonWidth = isPortrait ? '68%' : '13.3%'

  return (
    <SwipeableDrawer anchor='right' open={courseRunnerDrawerOpen} onOpen={handleOpen} onClose={handleClose}>
      <Box sx={{ margin: 2 }}>
        <Typography variant='h5'>Courses</Typography>
        <CourseList />
        <Divider />
        <Typography variant='h5'>Runners without course</Typography>
        <RunnerList />
        {
          userRole &&
          <Box sx={{ display: 'flex', position: 'fixed', m: 'auto', bottom: 10, justifyContent: 'center', width: uploadGpxButtonWidth }}>
            <UploadGpxToEventDialog />
          </Box>
        }
      </Box>
    </SwipeableDrawer>
  )

}

export default CourseRunnerDrawer
