import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Device } from '../utils/types';
import { AxiosResponse } from 'axios';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../utils/axiosInstance';
import Page from './Page';

interface FetchMyDevicesResponse extends AxiosResponse {
  data: Device[]
}

const MyDevices = () => {
  const navigate = useNavigate()
  const [devices, setDevices] = useState<Device[]>([])

  const fetchDevices = () => {
    axiosInstance.get('/api/device/my-devices').then((res: FetchMyDevicesResponse) => {
      setDevices(res.data)
    }).catch((e) => {
      console.log(e)
      // TODO: handle error
    })
  }

  useEffect(() => {
    fetchDevices()
  }, [])

  const renderEditButton = (params: GridRenderCellParams) => {
    return (
      <Button
        variant='contained'
        size='small'
        onClick={() => navigate(`/edit-gps-device/${params.row.id}`)}
      >
        Edit
      </Button>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.2,
    },
    {
      field: 'deviceName',
      headerName: 'Device name',
      flex: 1
    },
    {
      field: 'displayName',
      headerName: 'Display name',
      flex: 1
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      flex: 1
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      renderCell: renderEditButton
    }
  ];

  const getRows = () => {
    return devices.map((device) => {
      return {
        id: device.id,
        deviceName: device.deviceName,
        displayName: device.displayName,
        imei: device.imei
      }
    })
  }

  return (
    <Page title="My devices">
      <DataGrid
        rows={getRows()}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              imei: false,
            }
          }
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
    </Page>
  )
}

export default MyDevices;
