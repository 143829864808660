import React, { useState } from 'react'

import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

import { IntegrationTokenType } from '.'
import { axiosInstance } from '../../utils/axiosInstance'
import { toast } from '../../utils/toast'
import axiosErrorToToastString from '../../utils/axiosErrorToToastString'


const AddNewIntegrationToken = () => {
  const navigate = useNavigate()
  const [token, setToken] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [type, setType] = useState<IntegrationTokenType>(IntegrationTokenType.OMAPSTORE)

  const handleSubmit = async () => {
    if (token.length <= 0 || name.length <= 0) {
      toast.warn('Token not created. Please fill all fields.')
      return
    }

    try {
      await axiosInstance.post('/api/integration-tokens/create-token', {
        name,
        token,
        type
      })
      toast.success('Token created')
      navigate('/integration-tokens')
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(axiosErrorToToastString(e))
      } else {
        toast.error('Failed to create a token')
      }
    }
  }

  return (
    <Box
      sx={{
        padding: 5,
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      component={Paper}
    >
      <Typography component="h1" variant="h5">
        Add integration token
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Token name"
              autoFocus
              onChange={({ target }) => setName(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Token"
              onChange={({ target }) => setToken(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
            >
              <InputLabel id="token-type-select-label">Token type</InputLabel>
              <Select
                id="token-type-select"
                fullWidth
                value={type}
                label="Token type"
                onChange={({ target }) => setType(target.value as IntegrationTokenType)}
              >
                {
                  Object.values(IntegrationTokenType).map((tokenType, i) => (
                    <MenuItem value={tokenType} key={`token-type-select-${tokenType}-${i}`}>{tokenType}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          Save token
        </Button>
      </Box>
    </Box>
  )
}

export default AddNewIntegrationToken
