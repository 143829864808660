import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

import { toast, toastHandlePromise } from '../../utils/toast'
import { axiosInstance } from '../../utils/axiosInstance'
import { AxiosError } from 'axios'
import axiosErrorToToastString from '../../utils/axiosErrorToToastString'

interface CourseInfo {
  id: number,
  name: string,
  length: number
}

interface EventInfo {
  id: number,
  name: string,
  date: Date,
  courses: CourseInfo[]
}

interface UserDevice {
  id: number,
  deviceName: string,
  displayName: string,
  runnerName: string,
  courseId?: number
}


interface CourseInfoProps {
  courses: CourseInfo[],
  setSelectedCourseId: (courseId: number) => void,
  selectedCourseId: number | null
}

const JoinEventCourseInfo = ({ courses, setSelectedCourseId, selectedCourseId }: CourseInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const courseId = Number(event.target.value)
    if (isNaN(courseId)) {
      return
    }
    setSelectedCourseId(courseId)
  }

  return (
    <Fragment>
      <Typography>Courses</Typography>
      <RadioGroup
        name="radio-group-for-join-course-selection"
        value={selectedCourseId}
        onChange={handleChange}
      >
        {
          courses.map((course) => {
            const label = course.length && course.length > 0 ? `${course.name}: ${course.length / 1000}km` : course.name
            return (
              <FormControlLabel key={`join-course-radio-button-${course.id}`} value={course.id} control={<Radio />} label={label} />
            )
          })
        }
      </RadioGroup>
    </Fragment>
  )
}

interface JoinEventDeviceInfoProps {
  devices: UserDevice[],
  updateDevice: (device: UserDevice) => void,
  selectDevice: (deviceId: number) => void,
  selectedDeviceId: number | null
}

const JoinEventDeviceInfo = ({ devices, updateDevice, selectDevice, selectedDeviceId }: JoinEventDeviceInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const deviceId = Number(event.target.value)
    if (isNaN(deviceId)) {
      return
    }
    selectDevice(deviceId)
  }

  return (
    <Fragment>
      <Typography>Select your device</Typography>
      <RadioGroup
        name="radio-group-for-join-device-selection"
        value={selectedDeviceId}
        onChange={handleChange}
      >
        {
          devices.map((device) => {
            const label = `${device.deviceName} ${device.runnerName} (${device.displayName})`
            return (
              <FormControl key={`join-event-device-list-item-${device.id}`}>
                <FormControlLabel key={`join-event-select-device-radio-button-${device.id}`} value={device.id} control={<Radio />} label={label} />
              </FormControl>
            )
          })
        }
      </RadioGroup>
      <Typography fontSize={12}>Please note that currently you can modify the runner names associated to devices above in {<Link to={'/my-devices'}>"My devices"</Link>}</Typography>
    </Fragment>
  )
}

interface JoinEventAlreadySelectedDevicesProps {
  alreadySelectedDevices: UserDevice[],
  courses: CourseInfo[]
}

const JoinEventAlreadySelectedDevices = ({ alreadySelectedDevices, courses }: JoinEventAlreadySelectedDevicesProps) => {
  // TODO: possibility to remove joining
  return (
    <Fragment>
      <Typography>You have joined to the event with these devices</Typography>
      {
        alreadySelectedDevices.map((device) => {
          const course = courses.find(({ id }) => device.courseId === id)
          return (
            <Typography>{device.deviceName} {device.runnerName} ({device.displayName}) {course && `${course.name}`}</Typography>
          )
        })
      }
    </Fragment>
  )
}

const JoinEvent = () => {
  const navigate = useNavigate()
  const { eventUuid } = useParams()
  const [event, setEvent] = useState<EventInfo | null>(null)
  const [userDevices, setUserDevices] = useState<UserDevice[]>([])
  const [selectedCourseId, setSelectedCourseId] = useState<null | number>(null)
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null)
  // these devices are already "joined" to the event
  const [devicesAlreadySelected, setDevicesAlreadySelected] = useState<UserDevice[]>([])

  const joinButtonDisabled = () => !event || userDevices.length <= 0

  useEffect(() => {
    const fetctData = async () => {
      const { data: eventInfo } = await axiosInstance.get<EventInfo>(`/api/event/join-event/${eventUuid}`)
      const { data: { devicesAvailable, devicesSelected } } = await axiosInstance.get<{
        devicesSelected: UserDevice[],
        devicesAvailable: UserDevice[]
      }>(`/api/device/users-devices-for-join-event/${eventUuid}`)

      if (devicesAvailable.length === 1) {
        setSelectedDeviceId(devicesAvailable[0].id)
      }

      setEvent(eventInfo)
      setUserDevices(devicesAvailable)
      setDevicesAlreadySelected(devicesSelected)
    }

    if (eventUuid) {
      fetctData()
        .catch((e) => {
          if (e instanceof AxiosError) {
            toast.error(axiosErrorToToastString(e))
          } else {
            toast.error('Failed to load data. Please try again by refreshing the page')
          }
        })
    }
  }, [eventUuid])

  const handleJoinEvent = async () => {
    if (!event) {
      return
    }

    if (event?.courses.length > 0 && !selectedCourseId) {
      toast.error('You must select a course!')
      return
    }

    if (userDevices.length <= 0) {
      toast.error('You must have at least one device to join event.')
      return
    }

    const selectedDevice = userDevices.find((device) => device.id === selectedDeviceId)
    if (!selectedDevice) {
      toast.error('Device not selected')
      return
    }

    const joinEventResponsePromise = axiosInstance.post('/api/event/join-event', {
      eventId: event.id,
      runnerName: selectedDevice.runnerName,
      runnerDisplayName: selectedDevice.displayName,
      deviceId: selectedDevice.id,
      courseId: selectedCourseId
    })

    toastHandlePromise(joinEventResponsePromise, {
      pending: 'Joining... Please wait.',
      success: {
        render: () => {
          navigate('/') // TODO: should probably navigate to list where is users join events etc
          return `Joined to event ${event.name}`
        }
      },
      error: {
        render: ({ data }) => {
          if (data instanceof AxiosError) {
            const axiosErrorString = axiosErrorToToastString(data)
            return `Failed to join event! ${axiosErrorString}`
          }
          return 'Failed to join event!'
        }
      }
    })
  }

  const updateDevice = (device: UserDevice) => {
    const oldDevices = { ...userDevices }
    const newDevices = oldDevices.map((oldDevice) => {
      if (oldDevice.id === device.id) {
        return device
      } else {
        return oldDevice
      }
    })
    setUserDevices(newDevices)
  }

  if (!eventUuid) {
    // TODO: navigate to 404 page
    return (
      <div>No event identifier found</div>
    )
  }

  if (!event) {
    return (
      <div>Loading event</div>
    )
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        padding: 2,
        height: '100%',
        marginBottom: 8
      }}
      component={Paper}
      elevation={6}
    >
      <Card>
        <CardHeader
          title={event.name}
          subheader={dayjs(event.date).format('DD.MM.YYYY')}
        />
        {/* TODO: CardMedia where is shown thumbnail of map if available and if event is public etc. */}
        <CardContent>
          {event.courses.length > 0 &&
            <Box sx={{ marginBottom: 5 }}>
              <JoinEventCourseInfo
                courses={event.courses}
                selectedCourseId={selectedCourseId}
                setSelectedCourseId={setSelectedCourseId}
              />
            </Box>
          }
          {
            userDevices.length > 0 &&
            <Box sx={devicesAlreadySelected.length > 0 ? { marginBottom: 5 } : {}}>
              <JoinEventDeviceInfo
                devices={userDevices}
                selectDevice={setSelectedDeviceId}
                updateDevice={updateDevice}
                selectedDeviceId={selectedDeviceId}
              />
            </Box>
          }
          {
            devicesAlreadySelected.length > 0 &&
            <Box>
              <JoinEventAlreadySelectedDevices
                alreadySelectedDevices={devicesAlreadySelected}
                courses={event.courses}
              />
            </Box>
          }
        </CardContent>
        <CardActions>
          <Button onClick={handleJoinEvent} disabled={joinButtonDisabled()}>
            Join event
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

export default JoinEvent
