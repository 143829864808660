import React, { useEffect, useState } from 'react'
import { EventAction, EventActionValue, EventType } from '../../utils/types'
import { useAuth } from '../Auth/AuthProvider'
import { UserRole } from '../../services/authService'
import { Alert, AlertTitle, Box, FormControl, FormControlLabel, FormLabel, Typography, Checkbox, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'

interface EventLiveSelectionProps {
  eventDate: Dayjs | null
  eventType: EventType
  setEventType: (eventType: EventType) => void
  eventActions: EventAction[]
  setEventActions: (eventActions: EventAction[]) => void
}

const EventLiveSelection = ({ eventDate, eventType, setEventType, eventActions, setEventActions }: EventLiveSelectionProps) => {
  const { userRole } = useAuth()

  const availableEventTypes = getAvailableEventTypesForUserRole(userRole)

  if (!availableEventTypes.includes(EventType.LIVE)) {
    return (
      <Box>
        <Alert severity="info">
          <AlertTitle>GPX mode</AlertTitle>
          <Typography>In freemium events supports only GPX files, i.e. you may add routes recorded by your GPS watch for example. LIVE mode is a premium feature.</Typography>
        </Alert>
      </Box>
    )
  }

  const handleLiveModeChange = () => {
    if (eventType === EventType.LIVE) {
      setEventType(EventType.GPX)
    } else {
      setEventType(EventType.LIVE)
    }
  }

  const isLive = eventType === EventType.LIVE

  return (
    <Box>
      <FormControl>
        <FormLabel>LIVE Settings</FormLabel>
        <Alert severity="info">
          <Typography>Selecting LIVE will set the event to LIVE mode. Please unset the selection after your event has been run. Alternatively consider using scheduled actions to set the event live and off. If you want to just upload your GPX file to event, leave the LIVE option and scheduler unset.</Typography>
        </Alert>
        <Box mt={2}>
          <FormControlLabel control={<Checkbox checked={isLive} onChange={handleLiveModeChange} />} label="LIVE" />
        </Box>
      </FormControl>
      <EventLiveStartAndEndActions
        eventDate={eventDate}
        eventType={eventType}
        eventActions={eventActions}
        setEventActions={setEventActions}
      />
    </Box>
  )
}

const getAvailableEventTypesForUserRole = (userRole: UserRole | null) => {
  if (userRole === UserRole.PREMIUM) {
    return Object.values(EventType)
  }
  return [EventType.GPX]
}

interface EventActionsProps {
  eventDate: EventLiveSelectionProps['eventDate']
  eventType: EventLiveSelectionProps['eventType']
  eventActions: EventLiveSelectionProps['eventActions']
  setEventActions: EventLiveSelectionProps['setEventActions']
}

const EventLiveStartAndEndActions = ({ eventDate, eventType, eventActions, setEventActions }: EventActionsProps) => {
  const [useSchedulers, setUseSchedulers] = useState(false)

  useEffect(() => {
    setUseSchedulers(!!eventActions.find(({ action }) => [EventActionValue.SET_LIVE_OFF, EventActionValue.SET_LIVE_ON].includes(action)))
  }, [eventActions])

  const handleToggleSchedulers = () => {
    const newUseSchedulers = !useSchedulers
    const keepActions = [...eventActions.filter((action) => ![EventActionValue.SET_LIVE_OFF, EventActionValue.SET_LIVE_ON].includes(action.action))]
    if (newUseSchedulers) {
      const setLiveOnAction = {
        action: EventActionValue.SET_LIVE_ON,
        time: eventDate?.toDate() ?? new Date(Date.now())
      }

      const ceilTime = (time: Date) => {
        const newMinutes = Math.ceil(time.getMinutes() / 5) * 5
        if (newMinutes === 60) {
          time.setHours(time.getHours() + 1)
          time.setMinutes(0)
        } else {
          time.setMinutes(newMinutes)
        }
      }

      ceilTime(setLiveOnAction.time)

      if (eventType !== EventType.LIVE) {
        keepActions.push(setLiveOnAction)
      }

      const liveOffDate = eventDate?.toDate() ?? new Date(Date.now())
      liveOffDate.setHours(liveOffDate.getHours() + 3)
      const setLiveOffAction = {
        action: EventActionValue.SET_LIVE_OFF,
        time: liveOffDate
      }

      ceilTime(setLiveOffAction.time)

      keepActions.push(setLiveOffAction)

      setEventActions(keepActions)
    } else {
      setEventActions([...keepActions])
    }
    setUseSchedulers(newUseSchedulers)
  }

  const getDayjsFromAction = (eventActionValue: EventActionValue.SET_LIVE_OFF | EventActionValue.SET_LIVE_ON) => {
    const action = eventActions.find((action) => action.action === eventActionValue)
    return dayjs(action?.time)
  }

  const handleSetLiveOnDate = (value: Dayjs | null) => {
    if (!value) {
      return
    }
    const keepActions = [...eventActions.filter((action) => ![EventActionValue.SET_LIVE_OFF, EventActionValue.SET_LIVE_ON].includes(action.action))]
    const setLiveOffAction = eventActions.find((action) => EventActionValue.SET_LIVE_OFF === action.action)

    const setLiveOnDate = value.toDate()
    const setLiveOnAction = {
      action: EventActionValue.SET_LIVE_ON,
      time: setLiveOnDate
    }
    keepActions.push(setLiveOnAction)

    if (setLiveOffAction && (setLiveOffAction.time < setLiveOnDate)) {
      setLiveOffAction.time = new Date(setLiveOnDate)
      setLiveOffAction.time.setHours(setLiveOffAction.time.getHours() + 3)
    }

    if (setLiveOffAction) {
      keepActions.push(setLiveOffAction)
    }
    setEventActions(keepActions)
  }

  const handleSetLiveOffDate = (value: Dayjs | null) => {
    if (!value) {
      return
    }
    const keepActions = [...eventActions.filter((action) => ![EventActionValue.SET_LIVE_OFF, EventActionValue.SET_LIVE_ON].includes(action.action))]
    const setLiveOnAction = eventActions.find((action) => EventActionValue.SET_LIVE_ON === action.action)

    const setLiveOffDate = value.toDate()
    const setLiveOffAction = {
      action: EventActionValue.SET_LIVE_OFF,
      time: setLiveOffDate
    }
    keepActions.push(setLiveOffAction)

    if (setLiveOnAction && (setLiveOnAction.time > setLiveOffDate) && eventType !== EventType.LIVE) {
      setLiveOnAction.time = new Date(setLiveOffAction.time)
      setLiveOnAction.time.setHours(setLiveOnAction.time.getHours() - 3)
    }

    if (setLiveOnAction && eventType !== EventType.LIVE) {
      keepActions.push(setLiveOnAction)
    }
    setEventActions(keepActions)
  }

  return (
    <Box>
      <FormControl>
        <Box mb={2}>
          <FormControlLabel control={<Switch checked={useSchedulers} onChange={handleToggleSchedulers} />} label="Schedule LIVE" />
        </Box>
        {useSchedulers && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fi'}>
              {eventType !== EventType.LIVE &&
                <DateTimePicker
                  minDate={eventDate ?? undefined}
                  label="Set live ON at"
                  value={getDayjsFromAction(EventActionValue.SET_LIVE_ON)}
                  onChange={handleSetLiveOnDate}
                  disablePast
                />
              }
              <DateTimePicker
                minDate={eventDate ?? undefined}
                label="Set live OFF at"
                value={getDayjsFromAction(EventActionValue.SET_LIVE_OFF)}
                onChange={handleSetLiveOffDate}
                disablePast
              />
            </LocalizationProvider>
          </Box>
        )}

      </FormControl>
    </Box>
  )
}

export default EventLiveSelection
