import React, { useState } from 'react';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { AxiosError } from 'axios';
import { toast, toastHandlePromise } from '../utils/toast';
import { useNavigate } from 'react-router-dom';
import axiosErrorToToastString from '../utils/axiosErrorToToastString';
import { axiosInstance } from '../utils/axiosInstance';
import Page from './Page';

const CreateDeviceGroupForm = () => {
  const navigate = useNavigate()
  const [groupName, setGroupName] = useState('')
  //const [error, setError] = useState<string | null>(null)

  const handleSubmitCreateDeviceGroup = async () => {
    if (!groupName) {
      toast.warn('Group name cannot be empty!')
      return
    }

    /* axios.post('/api/device-group/create', {
      groupName
    }, {
      headers: {
        'Authorization': token
      }
    }).then(({ data }) => console.log({ data }))
    .catch((e: OtailsAxiosError) => {
      console.log(e)
      if (e.response?.status === 422) {
        setError(e?.response?.data?.error ?? null)
      } else if (e.response?.status === 403) {
        setError('Permission denied')
      } else {
        setError('Error occurred! Try again')
      }
    }) */

    try {
      const createDeviceGroupPromise = axiosInstance.post('/api/device-group/create', {
        groupName
      })

      await toastHandlePromise(createDeviceGroupPromise, {
        pending: 'Saving... Please wait.',
        success: {
          render: () => {
            navigate('/my-device-groups')
            return 'Device group created.'
          }
        },
        error: {
          render: ({ data }) => {
            if (data instanceof AxiosError) {
              const axiosErrorMsg = axiosErrorToToastString(data)
              return `Failed to create device group. ${axiosErrorMsg}`
            }
          }
        }
      })
    } catch (e) {
      console.log(e)
    }

  }

  return (
    <Page>
      <Typography component="h1" variant="h5">
        Create a new gps device group
      </Typography>
      {/* { error && <Typography color="red">
          { error }  
        </Typography>} */}
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="groupName"
              required
              fullWidth
              id="groupName"
              label="Unique gsp device group name"
              autoFocus
              value={groupName}
              onChange={({ target }) => setGroupName(target.value)}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmitCreateDeviceGroup}
        >
          Create a new device group
        </Button>
      </Box>
    </Page>
  )
}

export default CreateDeviceGroupForm;
