import { Puff, Radio } from 'react-loader-spinner'

interface LoaderProps {
  visible: boolean
  size?: number
}

export const MapLoader = ({ visible, size }: LoaderProps ) => {
  const width = size || 100
  
  return (
    <Puff
      width={width}
      height={width}
      radius={1}
      color='green'
      ariaLabel='loading-map'
      visible={visible}
      wrapperStyle={{ top: `calc(50% - ${width / 2}px)`, left: `calc(50% - ${width / 2}px)`, position: 'absolute' }}
    />
  )
}

interface LiveLoaderProps extends LoaderProps {
  color?: string
}

export const LiveLoader = ({ visible, size, color }: LiveLoaderProps) => {
  return (
    <Radio
      colors={color ? [color, color, color] : undefined}
      visible={visible}
      height={size}
      width={size}
      ariaLabel="radio-loading"
      wrapperStyle={{}}
    />
  )
}
