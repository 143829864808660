import React from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddIcon from '@mui/icons-material/Add';
import SensorsIcon from '@mui/icons-material/Sensors';
import DevicesIcon from '@mui/icons-material/Devices';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useLocation } from 'react-router-dom';
import { blue } from '@mui/material/colors'
import { Typography } from '@mui/material';
import AdminSubMenu from '../admin/AdminSubMenu';
import { useAuth } from '../Auth/AuthProvider';
import { UserRole } from '../../services/authService';
import Token from '@mui/icons-material/Token';
import { MenuBook } from '@mui/icons-material';
import { useSideNavigation } from './SideNavigationProvider';

const SideNavigation = () => {
  const location = useLocation()
  const { handleLogOut, userRole } = useAuth()
  const { toggled, collapsed, toggleSideNav, setBroken, broken } = useSideNavigation()

  const userIsPremiumOrAdmin = userRole === UserRole.PREMIUM || userRole === UserRole.ADMIN

  const transitionDuration = broken ? 200 : 300

  const getLinkText = (text: string) => {
    return (
      <Typography color="white">{text}</Typography>
    )
  }

  const getMenuItem = (path: string, linkText: string, icon: React.ReactNode) => {
    return (
      <MenuItem
        active={location.pathname === path}
        component={<Link to={path} />}
        icon={icon}
      >
        {getLinkText(linkText)}
      </MenuItem>
    )
  }

  return (
    <Sidebar
      transitionDuration={transitionDuration}
      backgroundColor={blue[700]}
      color={blue[700]}
      toggled={toggled}
      collapsed={collapsed}
      breakPoint='xl'
      onBreakPoint={setBroken}
      onBackdropClick={toggleSideNav}
    >
      <Menu
        style={ broken ? { marginTop: 30 } : {}}
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {
              return {
                color: disabled ? '#fff' : "#000", // NOTE: if disabled is used design these colors
                backgroundColor: active ? blue[600] : undefined,
                "&:hover": {
                  backgroundColor: "#335B8C !important",
                  color: "white !important",
                  borderRadius: "0px !important",
                }
              }
            }
          }
        }}
      >
        {getMenuItem('/', 'Home', <HomeOutlinedIcon />)}
        {userRole && getMenuItem('/create-event', 'Create event', <AddIcon />)}
        {userRole && getMenuItem('/my-events', 'My events', <EventIcon />)}

        {userIsPremiumOrAdmin && getMenuItem('/add-gps-device', 'Add gps device', <SensorsIcon />)}
        {userIsPremiumOrAdmin && getMenuItem('/my-devices', 'My devices', <DevicesIcon />)}
        {userIsPremiumOrAdmin && getMenuItem('/my-device-groups', 'My groups', <GroupsIcon />)}
        {userIsPremiumOrAdmin && getMenuItem('/create-device-group', 'Create device group', <GroupAddIcon />)}
        {userIsPremiumOrAdmin && getMenuItem('/integration-tokens', 'My integration tokens', <Token />)}

        { userRole && getMenuItem('/instructions', 'Instructions', <MenuBook />) }
        { userRole && <MenuItem onClick={() => handleLogOut()} icon={<LogoutIcon />}>{getLinkText('Logout')}</MenuItem> }

        {userRole === UserRole.ADMIN && <AdminSubMenu />}
      </Menu>
    </Sidebar>
  )
}

export default SideNavigation;
