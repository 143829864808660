import React, { Fragment, useState } from 'react'
import { AxiosError } from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import Input from '@mui/material/Input'
import FormControl from '@mui/material/FormControl'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import { GpxRecord, GpxRunner, RunnerType } from '../utils/types'
import { addGpxRunner } from '../features/eventService/eventServiceSlice'
import { updateTimeSlider } from '../features/timeSlider/timeSliderSlice'
import { axiosInstance } from '../utils/axiosInstance'
import { RootState } from '../app/store'
import { toast } from '../utils/toast'
import axiosErrorToToastString from '../utils/axiosErrorToToastString'

const simpleGpxParser = (gpxXml: string) => {

  const domParser = new window.DOMParser()
  const gpxDom = domParser.parseFromString(gpxXml, 'text/xml')
  
  const trkpts = Array.from(gpxDom.getElementsByTagName('trkpt'))

  const gpsRecords: GpxRecord[] = trkpts.map((trkpt) => {
    const latitude = parseFloat(trkpt.getAttribute('lat') ?? '')
    const longitude = parseFloat(trkpt.getAttribute('lon') ?? '')
    return {
      latitude,
      longitude,
      timestamp: (new Date(trkpt.getElementsByTagName('time').item(0)?.textContent ?? '')).getTime(),
      // elevation: parseFloat(trkpt.getElementsByTagName('ele').item(0)?.textContent ?? '')
    }
  })

  return gpsRecords
}

type AddedGpxRunner = Omit<GpxRunner, 'gpxRecords'>

const UploadGpxToEventDialog = () => {
  const dispatch = useDispatch()
  const courses = useSelector((state: RootState) => state.eventService.courses)
  const eventId = useSelector((state: RootState) => state.eventService.eventDetails?.id)
  const [gpxFile, setGpxFile] = useState<File | null>(null)
  const [runnerFullName, setRunnerFullName] = useState('')
  const [runnerDisplayName, setRunnerDisplayName] = useState('')
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null)

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogCLose = () => {
    setDialogOpen(false)
  }

  const parseGpxFile = async (gpxFile: File) => {
    const gpxXml = await gpxFile.text()
    const gpxRecords = simpleGpxParser(gpxXml)
    return gpxRecords
  }

  const handleFileUpload = async (event: any) => {
    const files = event.target.files
    const firstFile = files ? files[0] : null

    if (firstFile) {
      setGpxFile(firstFile)
    }
  }

  const uploadGpxRunner = async () => {
    if (runnerDisplayName.trim().length > 0 && runnerFullName.trim().length > 0 && gpxFile && eventId) {
      const gpxRecords = await parseGpxFile(gpxFile)

      const gpxRunner: GpxRunner = {
        type: RunnerType.GPX,
        name: runnerFullName,
        displayName: runnerDisplayName,
        gpxRecords,
        eventId,
        courseId: selectedCourseId
      }

      try {
        const { data } = await axiosInstance.post<AddedGpxRunner>('/api/event/add-gpx-runner', gpxRunner)

        gpxRunner.id = data.id

        dispatch(addGpxRunner(gpxRunner))
        dispatch(updateTimeSlider(gpxRunner))
        setGpxFile(null)
        setRunnerDisplayName('')
        setRunnerFullName('')
        setSelectedCourseId(null)

        handleDialogCLose()
        toast.success('Gps route saved')
      } catch (e) {
        // TODO: handle
        if (e instanceof AxiosError) {
          toast.error(axiosErrorToToastString(e))
        } else {
          toast.error('Failed to save gps route')
        }
      }
    }
  }

  return (
    <Box sx={{ width: 1 }}>
      <Button variant="outlined" fullWidth onClick={handleDialogOpen}>
        Add GPX
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogCLose}
        aria-labelledby="add-gpx-dialog-dialog-title"
        aria-describedby="add-gpx--dialog-description"
      >
        <DialogTitle id="add-gpx-dialog-title">
          Upload GPX file and add a runner to the event
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-gpx-dialog-description" sx={{ mb: 1 }}>
            You can add your gps route recorded by a gps device (e.g., a sport watch). Export your gpx file from your provider and upload it here.
          </DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Alert severity='info'>Please note that currently you cannot modify or delete your route after adding it.</Alert>
            <FormControl sx={{ my: 1 }}>
              <TextField
                helperText="Runner full name is shown in a runner list."
                name="runnerFullName"
                required
                id="runnerFullName"
                label="Runner full name"
                value={runnerFullName}
                onChange={({ target }) => setRunnerFullName(target.value)}
              />
            </FormControl>
            <FormControl sx={{ my: 1 }}>
              <TextField
                helperText="Runner display name is shown on a map with runner's current location."
                name="runnerDisplayName"
                required
                id="runnerDisplayName"
                label="Runner display name"
                value={runnerDisplayName}
                onChange={({ target }) => setRunnerDisplayName(target.value)}
              />
            </FormControl>
            {
              courses.length > 0 &&
              <FormControl sx={{ my: 1 }}>
                <TextField
                  helperText="Select course you run."
                  id="runnerCourseSelection"
                  select
                  label="Select course"
                  defaultValue={courses[0].id}
                  onChange={({ target }) => setSelectedCourseId(Number(target.value))}
                >
                  {
                    courses.map((course) => (
                      <MenuItem key={`course-${course.name}-${course.id}`} value={course.id}>
                        {course.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </FormControl>
            }
            <Fragment>
              <Button component='label' variant='contained' startIcon={<AttachFileIcon/>}>
                Select gpx-file
                <Input
                  id='gpx-file-upload'
                  aria-describedby='gpx-file-upload-helper-text'
                  type='file'
                  disableUnderline
                  inputProps={{ accept: '.gpx' }}
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
              </Button>
              {
                gpxFile &&
                <Typography>{gpxFile.name}</Typography>
              }

            </Fragment>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCLose}>Cancel</Button>
          <Button onClick={uploadGpxRunner} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default UploadGpxToEventDialog;

